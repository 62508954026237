import { Link } from 'react-router-dom';

import formatMoney from '../../../utils/formatMoney';
import PermissionGate from "../../../utils/PermissionGate";

export default function index({
    tBody,
    showButton1,
    showButton2,
    tButton2,
    tFooter,
    socio,
    nivel,
    tButton3,
    permissionApagar
}) {

    return (
        <div className='borderTable'>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Login</th>
                        {nivel === 'operador' && (
                            <>
                                <th>Cotação / Comissão</th>
                                <th>Saldo</th>
                            </>
                        )}
                        {nivel === 'gerente' && (
                            <>
                                <th>Bruto</th>
                                <th>Lucro</th>
                                <th>Sócio</th>
                                <th>C / S</th>
                            </>
                        )}

                        {showButton2 && <th>Status</th>}
                        {showButton1 && <th>Editar</th>}
                        {tButton3 && <PermissionGate permissions={[(permissionApagar ?? '')]}>
                            <th>Apagar</th>
                        </PermissionGate>}
                    </tr>
                </thead>
                <tbody>
                    {tBody.map((linha) => {
                        return (
                            <tr key={linha.id}>
                                <td>
                                    <span style={{ fontWeight: 700, fontSize: 17 }}>
                                        {linha.login}
                                    </span>
                                    <br />
                                    {linha.gerente && linha.gerente.login}
                                </td>
                                {linha.nivel === 'operador' ? (
                                    <>
                                        <td>
                                            {linha.cotas}
                                            <br />
                                            {`${linha.comissao} / ${linha.comissao_dezena} / ${linha.comissao_grupo}`}
                                        </td>

                                        <td>
                                            {formatMoney(parseInt(linha.saldo), 2, false)}
                                        </td>
                                    </>
                                ) : null}

                                {linha.nivel === 'gerente' && (
                                    <>
                                        <td>{linha.tipo_usuario === 'socio' ? '-' : `${linha.comissao_gerente}%`}</td>
                                        <td>{linha.tipo_usuario === 'socio' ? '-' : `${linha.comissao_lucro}%`}</td>
                                        <td>{linha.tipo_usuario === 'socio' ? `${linha.porcentagem_socio}%` : '-'}</td>
                                        <td>
                                            <input
                                                type='checkbox'
                                                onClick={() => false}
                                                checked={linha.permissoes && linha.permissoes.criar_operador}
                                            />
                                            &nbsp;&nbsp;
                                            <input
                                                type='checkbox'
                                                onClick={() => false}
                                                checked={linha.permissoes && linha.permissoes.adicionar_saldo}
                                            />
                                        </td>
                                    </>
                                )}

                                {showButton2 && (
                                    <td>
                                        {linha.ativo ? (
                                            <button
                                                className='btn btn-success'
                                                onClick={() => {
                                                    tButton2(linha.id, linha.ativo);
                                                }}
                                            >
                                                Ativo
                                            </button>
                                        ) : (
                                            <button
                                                className='btn btn-danger'
                                                onClick={() => {
                                                    tButton2(linha.id, linha.ativo);
                                                }}
                                            >
                                                Inativo
                                            </button>
                                        )}
                                    </td>
                                )}

                                {showButton1 && (
                                    <td>
                                        <Link
                                            className={'btn btn-light'}
                                            to={`/usuarios/${linha.id}/login`}
                                        >
                                            Editar
                                        </Link>
                                    </td>
                                )}

                                {tButton3 && (
                                    <PermissionGate permissions={[(permissionApagar ?? '')]}>
                                        <td>
                                            <button
                                                className='btn btn-link'
                                                onClick={() => {
                                                    tButton3(linha.id, linha.ativo);
                                                }}
                                            >
                                                Apagar
                                            </button>
                                        </td>
                                    </PermissionGate>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
                {tFooter && tFooter()}
            </table>
        </div>
    );
}
