import {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';

import styles from './styles.module.css';

import http from 'services/http';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import PermissionGate from "../../../utils/PermissionGate";
import {alerta} from "../../../components/Alert";

const MySwal = withReactContent(Swal);

export default function Descarga() {
    const [data, setData] = useState(dayjs().format('YYYY-MM-DD'));
    const [dataBRL, setDataBRL] = useState(dayjs(data).format('DD/MM/YYYY'));
    const [loterias, setLoterias] = useState([]);
    const [loteria, setLoteria] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [descarga, setDescarga] = useState([]);
    const [hasNoResultDescarga, setHasNoResultDescarga] = useState(false);
    const [isActiveFildsEditDescarga, setIsActiveFildsEditDescarga] = useState(true);
    const [modalidadesBanca, setModalidadesBanca] = useState([]);
    const [visiblymodalidade, setVisiblyModalidade] = useState(true);

    const [carregaPost, setCarregaPost] = useState(false);

    const naoMostrar = [
        'Milhar Brinde', 'Milhar Moto', 'Unidade',
        'Duque de Grupo', 'Terno de Grupo', 'Duque de Dezena',
        'Terno de Dezena', 'Passe', 'Passe vai e vem',
        'Terno de Grupo Combinado', 'Passe', 'Passe vai e vem',
        'Duque de Dezena Combinado', 'Terno de Dezena Combinado'
    ];

    const containerDescargaLista = useRef();

    async function requestLoterias() {
        try {
            const response = await http.get('/loterias');

            const loteriasLista = response.data.data.map((loteriaItem) => {
                return {
                    value: loteriaItem.id,
                    label: loteriaItem.nome,
                    ativo: loteriaItem.ativo,
                };
            });

            setLoteria(loteriasLista[0]);
            setLoterias(loteriasLista.filter(el => el.ativo === true));

            // loterias.unshift({ label: "Todas as loterias", value: 0 });
        } catch (e) {
            alerta(e.response.data)
        }
    }

    async function requestModalidadesBanca() {
        try {
            setLoading(true);
            const response = await http.get('/bancas/modalidades');
            const {data: modalidades} = response.data;

            setModalidadesBanca(modalidades);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alerta(error.response.data)
        }
    }

    useEffect(() => {
        requestLoterias();
        requestModalidadesBanca();
    }, []);

    useEffect(() => {
        setDataBRL(dayjs(data).format('DD/MM/YYYY'));
    }, [data]);

    async function buscarDescarga() {
        try {
            setLoading(true);
            setVisiblyModalidade(false);

            const response = await http.get('/descargas', {
                params: {
                    loteria: loteria.value,
                    data: data,
                },
            });

            const {descarga, total} = response.data;
            setDescarga(descarga);
            setTotal(total);
            setLoading(false);

            if (descarga.length === 0) {
                setHasNoResultDescarga(true);
            } else {
                setHasNoResultDescarga(false);
            }
        } catch (e) {
            alerta(e.response.data)
        }
    }

    async function downloadPdf() {
        try {
            setLoading(true);

            const response = await http({
                url: '/descargas/baixar', // your url
                method: 'GET',
                responseType: 'blob', // important
                params: {
                    loteria: loteria.value,
                    data: data,
                },
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            const nome = `descarga-${data}-.pdf`;

            link.setAttribute('download', nome);
            document.body.appendChild(link);
            link.click();

            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    function handleChangeValueDescarga(event) {
        const targetElement = event.target;
        const value = targetElement.value;
        const index = targetElement.dataset.index;

        const arrayModalidades = modalidadesBanca;
        arrayModalidades[index].descarga = value;

        setModalidadesBanca([...arrayModalidades]);
    }

    function handleClickEditDescarga(event) {
        event.preventDefault();
        setIsActiveFildsEditDescarga(
            (isActiveFildsEditDescarga) => !isActiveFildsEditDescarga,
        );
    }

    async function salvarDescarga() {
        try {
            setCarregaPost(true);

            const res = await http.patch('/bancas/modalidades', {
                modalidades: modalidadesBanca,
            });

            alerta(res.data)
            setCarregaPost(false);

        } catch (e) {
            alerta(e.response.data)
            setCarregaPost(false);
        }
    }

    return (
        <>
            <div className='titulo-principal'>Descarga</div>

            <div className='d-flex tres-colunas conteudo-principal'>
                <input
                    required
                    type='date'
                    onChange={(event) => {
                        setData(event.target.value);
                        setHasNoResultDescarga(false);
                    }}
                    className='form-control'
                    value={data}
                />

                <Select
                    label='Single select'
                    placeholder='Extração'
                    options={loterias}
                    value={loterias.filter(({value}) => value === loteria.value)}
                    onChange={(event) => {
                        setLoteria(event);
                        setHasNoResultDescarga(false);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })
                    }
                />


                <button
                    style={{height: '38px'}}
                    type='submit'
                    className='btn btn-primary' onClick={buscarDescarga}>
                    Buscar
                </button>

                <button
                    className='btn btn-success' className={styles.buttonSalvarPDF} onClick={downloadPdf}>
                    Salvar PDF
                </button>
            </div>

            {loading && <div className='loader'/>}

            {!loading && descarga.length > 0 && (
                <>
                    <div className={styles.containerTitle}>
                        <div
                            style={{marginTop: '13px'}}
                            className='d-flex duas-colunas '
                        >
                            <div className={styles.title}>
                                <h4>{loteria.label}</h4>
                            </div>
                            <div>
                                <h4>{dataBRL}</h4>
                            </div>
                        </div>


                        <table className='table m-t '>
                            <thead>
                            <tr>
                                <th>Modalidade</th>
                                <th>Palpite</th>
                                <th>Sorteio</th>
                                <th>Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {descarga.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.modalidade.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}</td>
                                        <td>{item.numero}</td>
                                        <td>{item.sorteio}</td>
                                        <td>R$ {item.valor}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan='3'>
                                    <b>Total:</b>
                                </td>
                                <td>
                                    <b>R$ {total}</b>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}

            {hasNoResultDescarga && (
                <div className={styles.noResult}>
                    <p>
                        Nenhum descarga na loteria <b>{loteria.label}</b> para o dia{' '}
                        <b>{dataBRL}</b>
                    </p>
                </div>
            )}

            {!loading && modalidadesBanca.length > 0 && visiblymodalidade && (
                <div className={styles.descarga}>
                    <div className={styles.titlesecondTable}>
                        <span>Valores da descarga</span>
                    </div>
                    <div className={styles.descargaLista} ref={containerDescargaLista}>
                        {modalidadesBanca.map((modalidade, index) => {
                            if (!naoMostrar.includes(modalidade.nome)) {
                                return (
                                    <div key={index}>
                                        <label htmlFor={modalidade.tipo}>{modalidade.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}</label>
                                        <input
                                            onChange={handleChangeValueDescarga}
                                            disabled={isActiveFildsEditDescarga}
                                            id={modalidade.tipo}
                                            data-index={index}
                                            type='number'
                                            className='form-control'
                                            value={modalidade.descarga}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <PermissionGate permissions={['Alterar modalidade']}>
                        <div className={styles.descargaButton}>
                            {isActiveFildsEditDescarga ? (
                                <button
                                    className='btn btn-info'
                                    onClick={handleClickEditDescarga}
                                    disabled={carregaPost}
                                >
                                    Editar
                                </button>
                            ) : (
                                <button
                                    className='btn btn-secondary'
                                    onClick={handleClickEditDescarga}
                                    disabled={carregaPost}
                                >
                                    Desabilitar
                                </button>
                            )}

                            <button className='btn btn-success' onClick={salvarDescarga} disabled={carregaPost}>
                                Salvar
                            </button>
                        </div>
                    </PermissionGate>
                </div>
            )}

        </>
    );
}
