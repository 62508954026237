import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import './styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';
import {ToastContainer} from "react-toastify";

const App = () => (
  <>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    <ToastContainer/>
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));
