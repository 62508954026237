import dayjs from 'dayjs';

import formatMoney from 'utils/formatMoney';
import Logo from 'utils/Logo';

import './styles.css';

const Bilhete = ({aposta}) => {

    function calcularSituacaoNumero(numero) {
        if (aposta.resultados) {
            const resultados = aposta.resultados;

            if (resultados.includes(numero)) {
                return 'vencedor';
            }

            return 'perdedor';
        }

        return 'pendente';
    }

    return (<>
        <div className='bilheteContainer'>
            <div className='logoDiv'>
                <Logo/>
            </div>

            <h3 style={{fontWeight: 'bold', fontFamily: 'arial'}}>
                Bilhete: {aposta.codigo}
            </h3>
            <span style={{fontFamily: 'arial', color: '#000'}}>
          Emitido: {dayjs(aposta.created_at).format('DD/MM/YYYY HH:mm')}
        </span>

            <br/>
            <span>
          Ponto: {aposta.cambista}
        </span>
            <br/>

            <span>
                Situação:&nbsp;

                {aposta.situacao === 'pendente' && (<span className="badge bg-secondary">
                  {aposta.situacao}
                </span>)}

                {aposta.situacao === 'pago' && (
                    <>
                        <span className="badge badge-primary mr-2">{aposta.situacao}</span>
                        <span>{formatMoney(aposta.premio)}</span>
                    </>
                )}

                {aposta.situacao === 'cancelado' && (<span className="badge badge-warning">
                  {aposta.situacao}
                </span>)}

                {aposta.situacao === 'perdedor' && (<span className="badge badge-danger">
                  {aposta.situacao}
                </span>)}

            </span>
            <hr/>
            <h4>
                {aposta.concurso_nome}: <br/>
                {dayjs(aposta.data_resultado).format('DD/MM/YY')}
            </h4>
            <hr/>


            {aposta.palpites.map((palpite) => (
                <>
                    <div className="">
                        <h6 className="flex-wrap mb-0">
                            {palpite.numeros.map((numero) => (
                                <p className={`numero text-white mr-2 mb-2 text-center ${calcularSituacaoNumero(numero)}`}>
                                    <span>{numero}</span>
                                </p>
                            ))}
                        </h6>

                        <span>
                            Valor: {formatMoney(palpite.valor)}
                        </span>
                        <br/>

                        <span>

                        Situação:&nbsp;

                            {palpite.situacao === 'pendente' && (
                                <span className="badge bg-secondary">{palpite.situacao}</span>)}

                            {palpite.situacao === 'pago' && (
                                <>
                                    <span className="badge badge-primary mr-2">{palpite.situacao}</span>
                                    <span>{formatMoney(palpite.premio)}</span>
                                </>
                            )}

                            {palpite.situacao === 'cancelado' && (
                                <span className="badge badge-warning">{palpite.situacao}</span>)}

                            {palpite.situacao === 'perdedor' && (
                                <span className="badge badge-danger">{palpite.situacao}</span>)}

                    </span>
                    </div>
                    <hr/>
                </>

            ))}

            <h4 style={{fontWeight: 'bold'}}>
                TOTAL: {formatMoney(aposta.valor)}
            </h4>
            <br/>
            <span>{aposta.texto_bilhete}</span>
        </div>
    </>);
};

export default Bilhete;
