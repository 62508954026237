import React, { Component } from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import http from "services/http";
import ListaResponsiva from "components/ListaResponsiva/Acompanhamento";
import Bilhete from "components/Bilhete";
import BilheteBolao from "components/BilheteBolao/index";
import Tabela from "components/Auditoria";
import Select from "react-select";
import PermissionGate from "../../../utils/PermissionGate";
import { alerta } from "../../../components/Alert";
import formatMoney from "../../../utils/formatMoney";
import { Id } from "../../../../node_modules/react-toastify/dist/index";

// import styles from './styles.module.css';

const tHead = ["ID", "Usuário", "Tipo", "Data", "Descrição"];

const ordernarPor = [
    {
        label: "Data",
        value: "created_at",
    },
    {
        label: "Tipo",
        value: "auditable_type",
    },
    {
        label: "ID",
        value: "id",
    },
];

export default class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data_inicio: dayjs().format("YYYY-MM-DD"),
            data_fim: dayjs().format("YYYY-MM-DD"),
            arrayDatas: [],
            tipos: [],
            listaRotas: [],
            relatorio: [],
            usuarios: [],
            rotaId: "",
            ordernarPor: "created_at",
            loadingAtivo: false,
            nivel: "",
        };
    }

    componentDidMount() {
        const buscaNivel = async () => {
            const nivel = await localStorage.getItem("nivel");
            this.setState({ nivel });
        };
        buscaNivel();

        this.requestRotas();
        this.buscarUsuarios();
        this.requestTipos();
    }

    requestRotas = async () => {
        try {
            const response = await http.get("/usuarios", {
                params: {
                    nivel: "gerente",
                    resumida: true,
                },
            });

            const { data: usuarios } = response.data;

            this.setState({ listaRotas: usuarios });
        } catch (e) {}
    };

    requestTipos = async() => {
        try {
            const response = await http.get("/auditoria/tipos");
            const tipos = response.data;

            console.log(tipos);

            this.setState({ tipos: response.data });
        } catch (e) {
            console.log(e);
        }
    }

    buscarUsuarios = async () => {
        try {
            const response = await http.get("/usuarios", {
                params: {
                    resumida: true,
                    nivel: "gerente",
                },
            });

            const { data: usuarios } = response.data;

            this.setState({ usuarios: usuarios });
        } catch (e) {
            console.log(e);
        }
    };

    requestAudits = async () => {
        this.setState({ loadingAtivo: true });

        const params = {
            tipo: this.state.tipo,
            usuario: this.state.usuario,
            ordenacao: this.state.ordernarPor,
            data_inicio: `${this.state.data_inicio} 00:00:00`,
            data_fim: `${this.state.data_fim} 23:59:59`,
        };

        const response = await http.get("/auditoria", { params });
        const { data: relatorio } = response.data;

        this.setState({
            relatorio,
        });

        this.setState({
            loadingAtivo: false,
        });
    };

    render() {
        return (
            <>
                <div className="titulo-principal">Auditoria</div>
                <div className="conteudo-principal">
                    <div className="d-flex tres-colunas">
                        <div className="form-group">
                            <label>Tipo</label>
                            <select
                                className="form-control"
                                onChange={(e) =>
                                    this.setState({ tipo: e.target.value })
                                }
                            >
                                <option value="0">Todos</option>
                                {this.state.tipos.map((tipo) => (
                                    <option
                                        key={tipo.tipo}
                                        value={tipo.tipo}
                                    >
                                        {tipo.tipo}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Usuário</label>
                            <select
                                onChange={(e) =>
                                    this.setState({ usuario: e.target.value })
                                }
                                className="form-control"
                            >
                                <option value="">Todos</option>
                                {this.state.usuarios.map((u) => (
                                    <option key={u.id} value={u.id}>
                                        {u.login}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Ordernar por</label>
                            <select
                                onChange={(e) =>
                                    this.setState({
                                        ordernarPor: e.target.value,
                                    })
                                }
                                className="form-control"
                            >
                                {ordernarPor.map((ordernar) => (
                                    <option
                                        key={ordernar.value}
                                        value={ordernar.value}
                                    >
                                        {ordernar.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="d-flex tres-colunas">
                        <div className="form-group">
                            <input
                                type="date"
                                className="form-control"
                                value={this.state.data_inicio}
                                onChange={(e) =>
                                    this.setState({
                                        data_inicio: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="date"
                                className="form-control"
                                value={this.state.data_fim}
                                onChange={(e) =>
                                    this.setState({ data_fim: e.target.value })
                                }
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "32%", height: "38px" }}
                            onClick={() => this.requestAudits()}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
                {this.state.loadingAtivo ? (
                    <div className="loader" />
                ) : (
                    <>
                        <Tabela relatorio={this.state.relatorio} />
                    </>
                )}
            </>
        );
    }
}
