import {useGetUserPermission} from "../hook/permissions";

const PermissionGate = ({children, permissions, module = []}) => {
    const userPermissions = useGetUserPermission();
    const {modulos} = JSON.parse(localStorage.getItem('usuario'));

    const hasPermissions = permissions
        .some(permission => {
            return userPermissions.includes(permission)
        })

    const hasModules = modulos.some(modulo => {
        return module.includes(modulo) || module?.length === 0
    })

    if (hasPermissions && hasModules) {
        return children;
    }

    return null;
}

export default PermissionGate; 