import {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import http from 'services/http';
import calcularGrupo from 'utils/calcularGrupo';
import {useLoading} from '../../../../hook/loading';
import {alerta} from "../../../../components/Alert";

export default function Adicionar({location, history}) {
    const [loading, startLoading, stopLoading] = useLoading();

    const [data, setData] = useState('');
    const [loteria, setLoteria] = useState(null);
    const [resultados, setResultados] = useState([]);
    const [resultadosGrupo, setResultadoGrupos] = useState([]);

    const aoIniciar = useCallback(() => {
        if (location && location.loteria && location.data) {
            const lot = location.loteria;
            const dat = location.data;

            setLoteria(lot);
            setData(dat);
            setResultados(lot.resultado ? lot.resultado.resultados : []);
        }
    }, [location]);

    useEffect(() => {
        aoIniciar();
    }, [aoIniciar]);

    useEffect(() => {
        const rg = resultados.map((r) => calcularGrupo(r, location.tipo));
        setResultadoGrupos(rg);
    }, [resultados]);

    const mudarResultado = (jogo, numero) => {
        const arr = resultados.map((r) => r);
        if (numero.length <= 4) {
            arr[jogo] = numero;
            setResultados(arr);
        }
    };

    // const inputsResultados = useCallback(() => {
    //   //
    //   const array = [];
    //   for (let i = 0; i < loteria.quantidade_sorteios; i++) {
    //     array.push(
    //       <div key={i} className="form-group d-flex tres-colunas">
    //         <div style={{ width: "50px" }}>{i === 9 ? 10 : "0" + (i + 1)}</div>
    //         <input
    //           type="number"
    //           className="form-control"
    //           onChange={(event) => mudarResultado(i, event.target.value)}
    //           value={resultados[i] || ''}
    //         />
    //         <div>{resultadosGrupo[i]}</div>
    //       </div>
    //     );
    //   }
    //   return array;
    // }, [loteria.quantidade_sorteios, resultados, resultadosGrupo]);

    // update history
    if (location.loteria === undefined) {
        history.push('/resultado/');
        return <span>Redirecionando para resultados disponíveis</span>;
    }

    async function enviarResultado() {
        startLoading();

        const arr = [];
        let parar = false;
        for (let i = 0; i < loteria.quantidade_sorteios; i++) {
            if (!resultados[i]) {
                parar = true;
            } else {
                arr.push(resultados[i]);
            }
        }

        for (let i = 0; i < loteria.quantidade_sorteios; i++) {
            if (arr[i] >= 0 && arr[i] <= 9999) {
                parar = false;
            } else {
                parar = true;
            }
        }
        if (!parar) {
            try {
                const response = await http.post(
                    location.tipo + '/extracoes/' + loteria.id + '/resultado',
                    {
                        data_resultado: data,
                        resultados: Object.values(arr)
                    },
                    {
                        cache: {
                            maxAge: 60 * 1000,
                            exclude: {query: false}
                        }
                    }
                );

                stopLoading();

                alerta(response.data)
                history.push('/resultado/');
            } catch (e) {
                stopLoading();
                alerta(e.response.data)
            }
        } else {
            stopLoading();

            Swal.fire({
                type: 'error',
                title: 'Resultado não adicionado!'
            });
        }
    }

    // valida o negativo (doidera!!!??? by ari)
    function validar(numero) {
        return `${numero}`.length !== 4;
    }

    async function gerarResultado() {
        if (
            validar(resultados[0]) ||
            validar(resultados[1]) ||
            validar(resultados[2]) ||
            validar(resultados[3]) ||
            validar(resultados[4])
        ) {
            Swal.fire({
                type: 'error',
                title: 'Adicione os 5 primeiros resultados !'
            });

            return false;
        }

        const result = [...resultados];
        if (loteria.quantidade_sorteios >= 6)
            result[5] =
                resultados[0].slice(0, -3) +
                '' +
                resultados[1].slice(0, -3) +
                '' +
                resultados[2].slice(0, -3) +
                '' +
                resultados[3].slice(0, -3);
        if (loteria.quantidade_sorteios >= 7)
            result[6] =
                resultados[0].slice(1, -2) +
                '' +
                resultados[1].slice(1, -2) +
                '' +
                resultados[2].slice(1, -2) +
                '' +
                resultados[3].slice(1, -2);
        if (loteria.quantidade_sorteios >= 8)
            result[7] =
                resultados[0].slice(2, -1) +
                '' +
                resultados[1].slice(2, -1) +
                '' +
                resultados[2].slice(2, -1) +
                '' +
                resultados[3].slice(2, -1);
        if (loteria.quantidade_sorteios >= 9)
            result[8] =
                resultados[0].slice(3) +
                '' +
                resultados[1].slice(3) +
                '' +
                resultados[2].slice(3) +
                '' +
                resultados[3].slice(3);
        if (loteria.quantidade_sorteios >= 10) {
            const numero =
                parseInt(result[0]) +
                parseInt(result[1]) +
                parseInt(result[2]) +
                parseInt(result[3]) +
                parseInt(result[4]) +
                parseInt(result[5]) +
                parseInt(result[6]) +
                parseInt(result[7]) +
                parseInt(result[8]);
            result[9] = numero.toString().slice(numero.toString().length - 4);
        }
        //
        setResultados(result);
    }

    async function simularResultado() {
        startLoading();
        const arr = [];
        let parar = false;
        for (let i = 0; i < loteria.quantidade_sorteios; i++) {
            if (!resultados[i]) {
                parar = true;
            } else {
                arr.push(resultados[i]);
            }
        }
        for (let i = 0; i < loteria.quantidade_sorteios; i++) {
            if (arr[i] >= 0 && arr[i] <= 9999) {
                parar = false;
            } else {
                parar = true;
            }
        }
        if (!parar) {
            try {
                const response = await http.post(
                    location.tipo + '/extracoes/' + loteria.id + '/simular-resultado',
                    {
                        data_resultado: data,
                        resultados: Object.values(arr)
                    },
                    {
                        cache: {
                            maxAge: 60 * 1000,
                            exclude: {query: false}
                        }
                    }
                );
                alerta(response.data)

                Swal.fire({
                    type: 'info',
                    title: 'Premio: ' + response.data.data.premio.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                });

                stopLoading();
            } catch (e) {
                alerta(e.response.data)

                stopLoading();
            }
        } else {
            stopLoading();
        }
    }

    return (
        <>
            <div className="titulo-principal">
                {loteria && loteria.nome} -&nbsp;
                {dayjs(data).format('DD/MM/YYYY')}
            </div>
            <div className="conteudo-principal">

                {loteria &&
                    loteria.quantidade_sorteios &&
                    [...Array(loteria.quantidade_sorteios).keys()].map((_, index) => (
                        <div key={index} className="form-group d-flex tres-colunas">
                            <div style={{width: '50px'}}>
                                {index === 9 ? 10 : '0' + (index + 1)}
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                onChange={(event) => mudarResultado(index, event.target.value)}
                                value={resultados[index] || ''}
                            />
                            <div>{resultadosGrupo[index]}</div>
                        </div>
                    ))}
                {/*  */}
                <div className="d-flex tres-colunas m-t">
                    <button
                        className="btn btn-warning"
                        onClick={() => simularResultado()}
                        disabled={loading}
                    >
                        Simular resultado
                    </button>

                    <button
                        className="btn btn-info"
                        onClick={() => gerarResultado()}
                        disabled={loading}
                    >
                        Gerar resultados
                    </button>

                    <button
                        className="btn btn-primary"
                        onClick={() => enviarResultado()}
                        disabled={loading}
                    >
                        {!loading ? 'Salvar' : 'Aguarde...'}
                    </button>
                </div>
            </div>
        </>
    );
}
