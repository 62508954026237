import Swal from 'sweetalert2';
import React from 'react';

import http from 'services/http';
import axios from 'axios';
import styles from '../Editar/editar.module.css';

export default class Criar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            sorteios: '5',
            milharMoto: '0',
            modalidade: 'ambas',
            loteria_central: '',
            listaLoterias: [],
            hora: '',
            salvo: false,
            error: false,
            carregaPost: false,
            semana: [
                {
                    dia: 'Segunda',
                    ativo: false
                },
                {
                    dia: 'Terça',
                    ativo: false
                },
                {
                    dia: 'Quarta',
                    ativo: false
                },
                {
                    dia: 'Quinta',
                    ativo: false
                },
                {
                    dia: 'Sexta',
                    ativo: false
                },
                {
                    dia: 'Sábado',
                    ativo: false
                },
                {
                    dia: 'Domingo',
                    ativo: false
                },
            ]
        };
    }

    componentDidMount() {
        this.requestLoteriasCentral();
    }

    changerCheckBox = (dia, index) => {
        const {semana} = this.state;
        semana[index].ativo = !dia.ativo;

        this.setState({semana});
    };

    renderCheckBox = () => {
        return this.state.semana.map((item, index) => {
            return (
                <label key={index} className="control-label">
                    <input
                        type="checkbox"
                        checked={item.ativo}
                        onChange={() => {
                            this.changerCheckBox(item, index);
                        }}
                    />
                    &nbsp;
                    {item.dia}
                </label>
            );
        });
    };

    salvarExtracao = async () => {
        try {
            this.setState({carregaPost: true});
            const {nome, hora, sorteios, milharMoto, semana, loteria_central, modalidade} = this.state;

            await http.post('/loterias', {
                nome: nome,
                hora: hora + ':00',
                segunda: semana[0].ativo,
                terca: semana[1].ativo,
                quarta: semana[2].ativo,
                quinta: semana[3].ativo,
                sexta: semana[4].ativo,
                sabado: semana[5].ativo,
                domingo: semana[6].ativo,
                quantidade_sorteios: sorteios,
                milhar_moto: milharMoto,
                central_id: loteria_central,
                modalidade: modalidade
            });

            Swal.fire({
                type: 'success',
                title: 'Criado com sucesso'
            });

            this.props.history.push('/extracao');
        } catch (e) {
            Swal.fire({
                type: 'error',
                title: 'Nao foi possivel criar'
            });
            this.setState({carregaPost: false});
        }
    };

    requestLoteriasCentral = async () => {
        try {
            const response = await axios.get('https://centralsete.com/api/loterias');

            const loterias_central = response.data.loterias;

            this.setState({listaLoterias: loterias_central});
        } catch (e) {
        }
    }

    render() {
        return (
            <>
                <div className="titulo-principal">Criar Loteria</div>
                <div className="conteudo-principal">
                    <div className="d-flex tres-colunas">
                        <div className="form-group">
                            <label className="control-label">Nome da loteria</label>
                            <input
                                required
                                onChange={(c) => this.setState({nome: c.target.value})}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label className="control-label">Encerra</label>
                            <input
                                required
                                onChange={(c) => this.setState({hora: c.target.value})}
                                type="time"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label className="control-label">Sorteios</label>
                            <select
                                className="form-control"
                                onChange={(e) => this.setState({sorteios: e.target.value})}
                            >
                                <option value="5">1a5</option>
                                <option value="7">1a7</option>
                                <option value="10">1a10</option>
                            </select>
                        </div>
                        <div className={styles.sorteioEhora2}>
                            <div className='form-group'>
                                <label className='control-label'>Milhar Moto</label>
                                <select
                                    className='form-control'
                                    value={this.state.milharMoto}
                                    onChange={(e) =>
                                        this.setState({milharMoto: e.target.value})
                                    }
                                >
                                    <option value='1'>Sim</option>
                                    <option value='0'>Não</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.sorteioEhora2}>
                            <div className='form-group'>
                                <label className='control-label'>Modalidade</label>
                                <select
                                    className='form-control'
                                    value={this.state.modalidade}
                                    onChange={(e) =>
                                        this.setState({modalidade: e.target.value})
                                    }
                                >
                                    <option value='ambas'>Ambas</option>
                                    <option value='tradicional'>Tradicional</option>
                                    <option value='lototimes'>Lototimes</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className="form-group">
                                <label>Central</label>
                                <select
                                    className="form-control"
                                    onChange={(e) =>
                                        this.setState({loteria_central: e.target.value})
                                    }
                                >
                                    <option value={null}></option>
                                    {this.state.listaLoterias.map((u) => (
                                        <option key={u.loteria_id} value={u.loteria_id}>
                                            {u.nome}
                                        </option>

                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">{this.renderCheckBox()}</div>
                    <div className="d-flex duas-colunas m-t">
                        <button
                            className="btn btn-danger"
                            onClick={() => this.props.history.push('/extracao')}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-success"
                            onClick={() => this.salvarExtracao()}
                            disabled={this.state.carregaPost}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
