import { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import http from "services/http";
import Swal from "sweetalert2";
import {alerta} from "../../../../components/Alert";

class CriarRegulamento extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    id: "",
  };

  componentDidMount() {
    this.requestBanca();
  }

  requestBanca = async () => {
    const response = await http.get(`bancas/minha-banca`);
    const { id, regulamento } = response.data.data;

    const contentBlock = htmlToDraft(regulamento);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateValue = EditorState.createWithContent(contentState);
      this.setState({
        id,
        editorState: editorStateValue,
      });
    }
  };

  onEditorStateChange = async (editorState) => {
    this.setState({
      editorState,
    });
  };

  enviarRegulamento = async () => {
    const rules = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    const res = await http.patch(`/bancas/regulamento`, {
      regulamento: rules,
    });
    
    alerta(res.data)

    return this.props.history.push("/regulamento");
  };

  render() {
    const { editorState } = this.state;
    return (
      <>
        <div className="titulo-principal">Regulamento</div>
        <div className="bordered">
          <div className="bordered" style={{ margin: "10px", padding: "10px" }}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                blockType: {
                  className: "displayNone",
                },
                fontFamily: {
                  className: "displayNone",
                },
                colorPicker: {
                  colors: [
                    // "rgb(44,130,201)",
                    // "rgb(65,168,95)",
                    "rgb(0,0,0)",
                    "rgb(226,80,65)",
                    // "rgb(255,255,255)",
                    // "rgb(250,197,28)",
                    "rgb(243,121,52)",
                    // "rgb(209,72,65)",
                  ],
                },
                fontSize: {
                  options: [16, 18, 24],
                },
                list: { inDropdown: true, className: "displayNone" },
                textAlign: { inDropdown: true },
                link: { inDropdown: true, className: "displayNone" },
                emoji: { className: "displayNone" },
                embedded: { className: "displayNone" },
                image: { className: "displayNone" },
                remove: { className: "displayNone" },
                history: { inDropdown: true, className: "displayNone" },
              }}
            />
          </div>
          <div
            style={{
              padding: "0 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="m-b m-t"
          >
            <button
              style={{ display: "block", width: "270px" }}
              className="btn btn-success"
              onClick={() => this.enviarRegulamento()}
            >
              Salvar
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CriarRegulamento;
