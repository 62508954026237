import {Component} from 'react';
import Swal from 'sweetalert2';

import styles from './editar.module.css';
import http from 'services/http';

import axios from 'axios';
import {alerta} from "../../../../components/Alert";

export default class Editar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            hora: '',
            quantidadeSorteio: '',
            milharMoto: '0',
            listaLoterias: [],
            loteria_central: '',
            loteria_instantanea: 0,
            carregaPost: false,
            semana: [
                {
                    dia: 'Segunda',
                    ativo: false,
                },
                {
                    dia: 'Terça',
                    ativo: false,
                },
                {
                    dia: 'Quarta',
                    ativo: false,
                },
                {
                    dia: 'Quinta',
                    ativo: false,
                },
                {
                    dia: 'Sexta',
                    ativo: false,
                },
                {
                    dia: 'Sábado',
                    ativo: false,
                },
                {
                    dia: 'Domingo',
                    ativo: false,
                },
            ],
        };
    }


    componentDidMount = async () => {
        try {
            const {id} = this.props.match.params;

            const response = await http.get(`/loterias/${id}`);

            const {data: loteria} = response.data;

            this.requestLoteriasCentral();

            this.setState((state, props) => ({
                nome: loteria.nome,
                hora: loteria.hora,
                modalidade: loteria.modalidade,
                quantidadeSorteio: loteria.quantidade_sorteios,
                milharMoto: loteria.milhar_moto,
                loteria_central: loteria.central_id,
                loteria_instantanea: loteria.loteria_instantanea,
                semana: [
                    {
                        dia: 'Segunda',
                        ativo: loteria.segunda,
                    },
                    {
                        dia: 'Terça',
                        ativo: loteria.terca,
                    },
                    {
                        dia: 'Quarta',
                        ativo: loteria.quarta,
                    },
                    {
                        dia: 'Quinta',
                        ativo: loteria.quinta,
                    },
                    {
                        dia: 'Sexta',
                        ativo: loteria.sexta,
                    },
                    {
                        dia: 'Sábado',
                        ativo: loteria.sabado,
                    },
                    {
                        dia: 'Domingo',
                        ativo: loteria.domingo,
                    },
                ],
            }));
        } catch (e) {
            alerta(e.response.data);

            return this.props.history.push('/extracao');
        }
    };

    requestLoteriasCentral = async () => {
        try {
            const response = await axios.get('https://centralsete.com/api/loterias');

            const loterias_central = response.data.loterias;

            this.setState({listaLoterias: loterias_central});
        } catch (e) {
            alerta(e.response.data);
        }
    };

    salvarEdicoes = async () => {
        try {
            this.setState({carregaPost: true});
            const {nome, hora, semana, quantidadeSorteio, milharMoto, loteria_central, modalidade} = this.state;
            const {id} = this.props.match.params;

            const res = await http.put(`/loterias/${id}`, {
                nome: nome,
                hora: hora,
                modalidade,
                segunda: semana[0].ativo,
                terca: semana[1].ativo,
                quarta: semana[2].ativo,
                quinta: semana[3].ativo,
                sexta: semana[4].ativo,
                sabado: semana[5].ativo,
                domingo: semana[6].ativo,
                quantidade_sorteios: quantidadeSorteio,
                milhar_moto: milharMoto,
                central_id: loteria_central,
            });

            alerta(res.data);

            this.props.history.push('/extracao');
        } catch (e) {
            alerta(e.response.data);

            this.setState({carregaPost: false});
        }
    };

    perguntarParaApagar = () => {
        Swal.fire({
            title: 'Deseja apagar a extração?',
            text: 'Todas as apostas serão apagadas junto a extração!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Sim, apagar!',
            cancelButtonText: 'Não, cancelar',
        }).then((result) => {
            if (result.value) {
                this.deleteExtracao();
            }
        });
    };

    deleteExtracao = async () => {
        try {
            const {id} = this.props.match.params;
            const {data} = await http.delete(`/loterias/${id}`);

            alerta(data);

            this.props.history.push('/extracao');
        } catch (e) {
            alerta(e.response.data);
        }
    };

    changerCheckBox = (dia, index) => {
        const {semana} = this.state;
        semana[index].ativo = !dia.ativo;

        this.setState({semana});
    };

    renderCheckBox = () => {
        return this.state.semana.map((item, index) => {
            return (
                <label key={index} className='control-label'>
                    <input
                        type='checkbox'
                        checked={item.ativo}
                        onChange={() => {
                            this.changerCheckBox(item, index);
                        }}
                    />
                    &nbsp;
                    {item.dia}
                </label>
            );
        });
    };

    render() {
        return (
            <>
                <div className='titulo-principal'>Editar Loteria</div>
                <div className='conteudo-principal'>
                    <div className='d-flex tres-colunas'>
                        <div className='form-group'>
                            <label className='control-label'>Nome da loteria</label>
                            <input
                                onChange={(c) => this.setState({nome: c.target.value})}
                                disabled={this.state.loteria_instantanea}
                                type='text'
                                className='form-control'
                                value={this.state.nome}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Encerra</label>
                            <input
                                onChange={(c) =>
                                    this.setState({hora: c.target.value + ':00'})
                                }
                                type='time'
                                className='form-control'
                                value={this.state.hora.slice(0, 5)}
                            />
                        </div>
                        <div className={styles.sorteioEhora2}>
                            <div className='form-group'>
                                <label className='control-label'>Sorteios</label>
                                <select
                                    className='form-control'
                                    value={this.state.quantidadeSorteio}
                                    onChange={(e) =>
                                        this.setState({quantidadeSorteio: e.target.value})
                                    }
                                >
                                    <option value='5'>1a5</option>
                                    <option value='7'>1a7</option>
                                    <option value='10'>1a10</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.sorteioEhora2}>
                            <div className='form-group'>
                                <label className='control-label'>Milhar Moto</label>
                                <select
                                    className='form-control'
                                    value={this.state.milharMoto}
                                    onChange={(e) =>
                                        this.setState({milharMoto: e.target.value})
                                    }
                                >
                                    <option value='1'>Sim</option>
                                    <option value='0'>Não</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.sorteioEhora2}>
                            <div className='form-group'>
                                <label className='control-label'>Modalidade</label>
                                <select
                                    className='form-control'
                                    value={this.state.modalidade}
                                    onChange={(e) =>
                                        this.setState({modalidade: e.target.value})
                                    }
                                >
                                    <option value='ambas'>Ambas</option>
                                    <option value='tradicional'>Tradicional</option>
                                    <option value='lototimes'>Lototimes</option>
                                </select>
                            </div>
                        </div>
                        {!this.state.loteria_instantanea && (<div>
                            <div className='form-group'>
                                <label>Central</label>
                                <select
                                    className='form-control'
                                    onChange={(e) =>
                                        this.setState({loteria_central: e.target.value})
                                    }
                                >
                                    <option value={null}></option>
                                    {this.state.listaLoterias.map(u => {
                                        const selectLoteria = (this.state.loteria_central === u.loteria_id) ? 'selected' : '';
                                        return (
                                            <option selected={selectLoteria} key={u.loteria_id} value={u.loteria_id}>
                                                {u.nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>)}
                    </div>
                    <div className='d-flex'>{this.renderCheckBox()}</div>
                    {this.state.loteria_instantanea ? (
                        <div className='d-flex duas-colunas m-t'>
                            <button
                                className='btn btn-danger'
                                onClick={() => this.props.history.push('/extracao')}
                            >
                                Cancelar
                            </button>
                            <button
                                className='btn btn-success'
                                onClick={() => this.salvarEdicoes()}
                            >
                                Salvar
                            </button>
                        </div>
                    ) : (
                        <div className='d-flex tres-colunas m-t'>
                            <button
                                className='btn btn-danger'
                                onClick={() => this.props.history.push('/extracao')}
                            >
                                Cancelar
                            </button>
                            <button
                                className='btn btn-warning'
                                onClick={() => this.perguntarParaApagar()}
                            >
                                Excluir
                            </button>
                            <button
                                className='btn btn-success'
                                onClick={() => this.salvarEdicoes()}
                                disabled={this.state.carregaPost}
                            >
                                Salvar
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
