import {Component} from 'react';
import dayjs from 'dayjs';

import http from 'services/http';
import formatMoney from 'utils/formatMoney';

import Tabela from 'components/Financeiro';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import styles from './styles.module.css';
import Swal from "sweetalert2";


export default class index extends Component {
    constructor(props) {
        super(props);

        const data = dayjs().format('YYYY-MM-DD');

        this.state = {
            data_inicial: data,
            data_final: data,
            usuarioId: null,
            usuarios: [],
            relatorio: [],
            resumo: null,
            loadingAtivo: false
        };

        this.formatarMoeda = this.formatarMoeda.bind(this);
    }

    componentDidMount() {
        const buscaNivel = async () => {
            const nivel = await localStorage.getItem('nivel');
            this.setState({nivel});
        }
        buscaNivel();

        this.buscarUsuarios(localStorage.getItem('nivel'));
    }

    formatarMoeda = (valor) => formatMoney(valor);

    buscarUsuarios = async (nivel) => {
        try {
            const response = await http.get('/usuarios', {
                params: {
                    nivel: nivel === 'gerente' ? 'operador' : 'gerente',
                    resumida: true
                }
            });

            const {data: usuarios} = response.data;

            this.setState({usuarios: usuarios});
        } catch (e) {
            console.log(e);
        }
    };

    buscarFinanceiro = async (e) => {
        try {
            e.preventDefault();

            this.setState({loadingAtivo: true});

            const data_inicial = dayjs(this.state.data_inicial);
            const data_final = dayjs(this.state.data_final);

            const diferencaDeDias = data_final.diff(data_inicial, 'days');

            if (diferencaDeDias > 91) {
                Swal.fire({
                    type: 'error',
                    title: 'Não foi possível buscar o relatório. Intervalo máximo é de 90 dias!'
                });

                this.setState({loadingAtivo: false});

                return;
            }

            const nivel = localStorage.getItem('nivel');

            if (nivel === "admin") {
                const response = await http.get('/vendas', {
                    params: {
                        data_inicial: this.state.data_inicial,
                        data_final: this.state.data_final,
                        gerente_id: this.state.usuarioId
                    }
                });

                const {data: {relatorio, resumo}} = response.data;

                this.setState({
                    relatorio,
                    resumo
                });
            }

            if (nivel === "gerente") {
                const response = await http.get('/vendas', {
                    params: {
                        data_inicial: this.state.data_inicial,
                        data_final: this.state.data_final,
                        cambista_id: this.state.usuarioId
                    }
                });

                const {data: {relatorio, resumo}} = response.data;

                this.setState({
                    relatorio,
                    resumo
                });
            }

        } catch (e) {
            console.log(e);
        }

        this.setState({
            loadingAtivo: false
        });
    };

    downloadPdf = async () => {
        const doc = new jsPDF();
        doc.setFontSize(24);
        doc.text(`Relatorio Financeiro`, 15, 10);
        doc.autoTable({
            html: `.table`,
            useCss: true
        });

        doc.autoTable({
            html: `.head_table_banca`,
            useCss: true
        });

        doc.autoTable({
            html: `.table_banca`,
            useCss: true
        });

        doc.save(`Financeiro_.pdf`);
    }

    render() {
        return (
            <>
                <div className="titulo-principal">Venda</div>
                <div className="conteudo-principal">
                    <form
                        onSubmit={this.buscarFinanceiro}
                        className="d-flex quatro-colunas"
                    >
                        <select
                            onChange={(e) => this.setState({usuarioId: e.target.value})}
                            className="form-control"
                        >
                            <option value="0">Todos</option>
                            {this.state.usuarios.map((u) => (
                                <option key={u.id} value={u.id}>
                                    {u.login}
                                </option>
                            ))}
                        </select>

                        <input
                            type="date"
                            value={this.state.data_inicial}
                            onChange={(e) => this.setState({data_inicial: e.target.value})}
                            className="form-control"
                        />

                        <input
                            type="date"
                            value={this.state.data_final}
                            onChange={(e) => this.setState({data_final: e.target.value})}
                            className="form-control"
                        />

                        <button
                            style={{height: '38px'}}
                            type="submit"
                            className="btn btn-primary">
                            Buscar
                        </button>
                    </form>
                </div>
                {this.state.loadingAtivo ? (
                    <div className="loader"/>
                ) : (
                    <>
                        <Tabela
                            relatorio={this.state.relatorio}
                            resumo={this.state.resumo}
                            formatMoney={this.formatarMoeda}
                        />
                        {this.state.relatorio.length > 0 ? (
                            <button
                                className={`btn btn-primary ${styles.buttonSalvarPDF}`}
                                onClick={this.downloadPdf}>
                                Salvar PDF
                            </button>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </>
        );
    }
}
