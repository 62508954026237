import React, {useEffect, useState} from "react";
import styles from "../../Cotacoes/styles.module.css";
import http from "../../../../services/http";
import {alerta} from "../../../../components/Alert";

export function CotacaoSenaBrasil() {
    const [cotacoesSenaBrasil, setCotacoesSenaBrasil] = useState([]);
    const [cotacaoSenaBrasil, setCotacaoSenaBrasil] = useState([]);
    const [modalidade, setModalidade] = useState('Sena Brasil');
    const [isLoading, setIsLoading] = useState(true);

    function editarValoresSenaBrasil(index, coluna, valor) {
        const cotacoesSenaBrasilArray = cotacaoSenaBrasil;
        cotacoesSenaBrasilArray.cotacoes[index][coluna] = valor;

        setCotacaoSenaBrasil({...cotacoesSenaBrasilArray});
    }

    async function salvarCotacaoSenaBrasil(cotacao) {

        const response = await http.put(`/sena-brasil/cotacoes/${cotacao.id}`, {
            'premio': cotacao.premio
        })

        alerta(response.data)
    }

    async function buscarCotacoes() {
        try {
            const responseCotacoesSenaBrasil = await http.get('/sena-brasil/concursos/cotacoes');
            const {data: cotacoesSenaBrasil} = responseCotacoesSenaBrasil.data;
            setCotacoesSenaBrasil(cotacoesSenaBrasil);

            const cotacao = cotacoesSenaBrasil.filter((cotacao) => {
                return cotacao.nome === modalidade
            })
            setCotacaoSenaBrasil(cotacao['0'])

            setIsLoading(false);
        } catch (e) {
            alerta(e.response.data)
        }
    }

    useEffect(() => {
        buscarCotacoes()
    }, [])

    useEffect(() => {
        const cotacao = cotacoesSenaBrasil.filter((cotacao) => {
            return cotacao.nome === modalidade
        })
        setCotacaoSenaBrasil(cotacao['0'])
    }, [modalidade])

    return (
        <>
            <div className="conteudo-principal d-flex duas-colunas mb-2">
                <select
                    required
                    className="form-control text-capitalize"
                    onChange={(e) => setModalidade(e.target.value)}
                    value={modalidade}
                >
                    <option value="Sena Brasil">Sena Brasil</option>
                    <option value="Quina Brasil">Quina Brasil</option>
                    <option value="Loto Brasil">Loto Brasil</option>
                </select>
            </div>

            {cotacaoSenaBrasil?.cotacoes?.length > 0 && (
                <div className="bordered">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Quantidade de acerto</th>
                            <th>Pagamento</th>
                            <th>Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cotacaoSenaBrasil.cotacoes.map((cotacao, index) => {
                            return (
                                <tr key={cotacao.id}>
                                    <td>{cotacao.quantidade_acertos}</td>
                                    <td>
                                        <input type="number" value={cotacao?.premio}
                                               onChange={(e) => editarValoresSenaBrasil(index, 'premio', e.target.value)}/>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-success mr-2"
                                            onClick={() => salvarCotacaoSenaBrasil(cotacao)}
                                        >
                                            Salvar
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}
