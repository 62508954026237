export default () => {
  return {
    '0': {numero: '361', cor: 'success'},
    '26': {numero: '10', cor: 'dark'},
    '3': {numero: '20', cor: 'danger'},
    '35': {numero: '30', cor: 'dark'},
    '12': {numero: '40', cor: 'danger'},
    '28': {numero: '50', cor: 'dark'},
    '7': {numero: '59', cor: 'danger'},
    '29': {numero: '69', cor: 'dark'},
    '18': {numero: '79', cor: 'danger'},
    '22': {numero: '89', cor: 'dark'},
    '9': {numero: '98', cor: 'danger'},
    '31': {numero: '108', cor: 'dark'},
    '14': {numero: '118', cor: 'danger'},
    '20': {numero: '128', cor: 'dark'},
    '1': {numero: '137', cor: 'danger'},
    '33': {numero: '147', cor: 'dark'},
    '16': {numero: '157', cor: 'danger'},
    '24': {numero: '167', cor: 'dark'},
    '5': {numero: '176', cor: 'danger'},
    '10': {numero: '186', cor: 'dark'},
    '23': {numero: '196', cor: 'danger'},
    '8': {numero: '206', cor: 'dark'},
    '30': {numero: '215', cor: 'danger'},
    '11': {numero: '225', cor: 'dark'},
    '36': {numero: '235', cor: 'danger'},
    '13': {numero: '245', cor: 'dark'},
    '27': {numero: '254', cor: 'danger'},
    '6': {numero: '264', cor: 'dark'},
    '34': {numero: '274', cor: 'danger'},
    '17': {numero: '284', cor: 'dark'},
    '25': {numero: '293', cor: 'danger'},
    '2': {numero: '303', cor: 'dark'},
    '21': {numero: '313', cor: 'danger'},
    '4': {numero: '322', cor: 'dark'},
    '19': {numero: '332', cor: 'danger'},
    '15': {numero: '342', cor: 'dark'},
    '32': {numero: '352', cor: 'danger'},
  }
}
