import dayjs from 'dayjs';

import formatMoney from 'utils/formatMoney';
import Logo from 'utils/Logo';

import './styles.css';

const Bilhete = ({ aposta }) => {

  return (
    <>
      <div className='bilheteContainer'>
        <div className='logoDiv'>
          <Logo />
        </div>

        <h3 style={{ fontWeight: 'bold', fontFamily: 'arial' }}>
          Bilhete: {aposta.codigo}
        </h3>
        <span style={{ fontFamily: 'arial', color: '#000' }}>
          Emitido: {dayjs(aposta.created_at).format('DD/MM/YYYY HH:mm')}
        </span>

        <br />
        <span>
          Ponto: {aposta.operador}
        </span>
        <br />

        <span>
          Situação:&nbsp;

          {
            <span className={`badge badge-${aposta.situacao === 'perdedor' ? 'danger' : aposta.situacao === 'pago' ? 'primary' : 'secondary'}`}>
              { aposta.situacao }
            </span>
          }

        </span>
        <hr />
        <h4>
          {aposta.titulo}
        </h4>
        <hr />

        { 
          aposta.palpites.map((palpite, index) => {
            return (
              <div key={index}>
                <h6 className=" mb-0">
                  {palpite.time_casa } { palpite.placar_casa && `(${palpite.placar_casa})` } x { palpite.placar_fora && `(${palpite.placar_fora})` } {palpite.time_fora }
                  <h6 className='mb-0 font-weight-bold'>{palpite.palpite}</h6>
                </h6>
                <h6>
                  <span className={
                    `badge badge-${palpite.situacao === 'perdedor' ? 'danger' : palpite.situacao === 'vencedor' ? 'success' : 'secondary'}`
                  }>
                    { palpite.situacao }  
                  </span>  
                </h6>
                <hr />
              </div>
            );
          })
        }

        <div>

          {
            aposta.tipo_bolao === 'placar' ?
            <>
              <h6 className=" mb-0">{aposta.palpites.length} Acertos {aposta.primeiro_premio}</h6>
              <h6 className=" mb-0">{aposta.palpites.length -1} Acertos {aposta.segundo_premio}</h6>
              <h6>{aposta.palpites.length -2} Acertos {aposta.terceiro_premio}</h6>
            </> : <h6>Premio {aposta.premio_acumulado}</h6>
          }

        </div>

        <hr />
        
        <h4 style={{ fontWeight: 'bold' }}>
          TOTAL: {formatMoney(aposta.valor)}
        </h4>
        <br />
        <span>{aposta.texto_bilhete}</span>
      </div>
    </>
  );
};

export default Bilhete;
