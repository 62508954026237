import {Component} from 'react';
import {NavLink} from 'react-router-dom';
import dayjs from 'dayjs';

import http from 'services/http';
import formatMoney from 'utils/formatMoney';

import styles from './styles.module.css';
import {alerta} from "../../../components/Alert";
import Swal from "sweetalert2";
import Select from "react-select";
import PermissionGate from "../../../utils/PermissionGate";

export default class caixa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            caixa: {},
            nivel: '',
            lancamentos: [],
            loading: true,
            data: dayjs().format('YYYY-MM-DD')
        };
    }

    componentDidMount() {

        const buscaNivel = async () => {
            const nivel = await localStorage.getItem('nivel');
            this.setState({nivel});
        }
        buscaNivel();

        this.buscarLancamentos();
        this.buscarCaixa(localStorage.getItem('nivel'));
        this.buscarOperadores(localStorage.getItem('nivel'));

    }

    buscarCaixa = async (nivel) => {
        try {
            const response = await http.get(`caixa/sintetico`);

            const {caixa} = response.data.data;

            this.setState({caixa});
        } catch (e) {
        }
        this.setState({loading: false});
    };

    buscarLancamentos = async () => {
        try {
            const response = await http.get('/lancamentos');

            const lancamentos = response.data.data.map((lancamento) => {
                lancamento.data = dayjs(lancamento.created_at).format('DD/MM/YY HH:mm');
                lancamento.valor = formatMoney(lancamento.valor);
                return lancamento;
            });

            this.setState({lancamentos});
        } catch (e) {
            alerta(e.response.data);
        }
    };

    criarDatas = () => {
        const datas = [];
        for (let i = 0; i < 15; i++) {
            const data = dayjs().subtract(i, 'day');

            datas.push({
                nome: data.format('DD/MM/YYYY'),
                valor: data.format('YYYY-MM-DD')
            });
        }

        return datas;
    };

    buscarOperadores = async (nivel) => {
        try {

            if (nivel !== 'gerente') return

            const response = await http.get('usuarios?nivel=operador');
            let {data: operadores} = response.data;

            operadores = operadores.map((operador) => {
                return {
                    label: operador.login,
                    value: operador.id
                };
            });
            this.setState({operadores: operadores});

        } catch (e) {
            console.log(e)
        }
    };

    criarLancamento = async (event) => {
        try {
            event.preventDefault();

            const {valor, operadorSubtraido, operadorRecebedor} = this.state;

            if (!operadorSubtraido || !operadorRecebedor) {
                Swal.fire({
                    type: 'error',
                    title: 'Informe os dois operadores para o lançamento'
                });

                return;
            }

            if (operadorSubtraido === operadorRecebedor) {
                Swal.fire({
                    type: 'error',
                    title: 'Informe operadores diferentes para o lançamento'
                });

                return;
            }

            if (!valor) {
                Swal.fire({
                    type: 'error',
                    title: 'Informe o valor do lançamento'
                });

                return;
            }

            const response = await http.post('/lancamentos', {
                valor,
                operadorSubtraido,
                operadorRecebedor
            });

            this.buscarLancamentos();

            alerta(response.data);
        } catch (e) {
            alerta(e.response.data);
        }
    };

    caixaItem = (nome, valor, className = '') => {
        return (
            <div className="caixaAdmin bordered2">
                {nome}: <br/>
                <span className={className}>{formatMoney(valor)}</span>
            </div>
        );
    };

    render() {
        return (
            <>
                <div className="titulo-principal">Caixa</div>
                <div className="conteudo-principal">
                    <div className={`d-flex duas-colunas ${styles.container}`}>
                        {this.state.loading ? (
                            <div className="loader"/>
                        ) : (

                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '33%'
                                    }}
                                >
                                    <div
                                        className="caixaAdmin bordered"
                                        style={{backgroundColor: '#5cb85c'}}
                                    >
                                        Caixa - Admin
                                    </div>

                                    {this.caixaItem('Entrada', this.state.caixa.entrada, 'text-success')}

                                    {this.caixaItem('Pago', this.state.caixa.saida, 'text-danger')}

                                    {
                                        this.state.nivel === 'gerente' ? (
                                            this.caixaItem('Comissão', this.state.caixa.comissao_cambista)
                                        ) : (
                                            <>
                                                {this.caixaItem('Comissão vendedores', this.state.caixa.comissao_cambista)}
                                                {this.caixaItem('Comissão gerentes', this.state.caixa.comissao_gerente)}
                                            </>
                                        )
                                    }

                                    {
                                        this.state.nivel === 'gerente' &&
                                        this.caixaItem(this.state.caixa.gerente_nome, this.state.caixa.comissao_gerente)
                                    }

                                    <div className="caixaAdmin bordered2">
                                        <b>Total:&nbsp;</b><br/>
                                        <b
                                            className={`${
                                                this.state.caixa.total > 1 ? 'text-success' : ''
                                            } ${this.state.caixa.total < 0 ? 'text-danger' : ''}`}
                                        >
                                            {formatMoney(this.state.caixa.total)}
                                        </b>
                                    </div>

                                    <PermissionGate permissions={["Listar resumo"]}>
                                        <NavLink
                                            className="caixaAdmin"
                                            style={{
                                                marginTop: 10,

                                                backgroundColor: '#007bff',
                                                color: '#ffffff'
                                            }}
                                            to={'/caixa/resumo/'}
                                        >
                                            <b>Resumo para fechamento</b>
                                        </NavLink>
                                    </PermissionGate>

                                    <div
                                        style={{
                                            textAlign: "left",
                                            fontSize: "17px",
                                            color: "#000",
                                            marginTop: "20px",
                                            marginBottom: "10px",
                                            minWidth: "270px"
                                        }}
                                    >
                                        Entrada aberta: {formatMoney(this.state.caixa.entrada_aberta)}
                                    </div>

                                    <div
                                        style={{
                                            textAlign: "left",
                                            fontSize: "17px",
                                            color: "#000",
                                            minWidth: "270px"
                                        }}
                                    >
                                        Prêmios não pago: {formatMoney(this.state.caixa.nao_pago)}
                                    </div>
                                </div>

                                <div className={`${styles.tableCaixa} `}>

                                    {

                                        this.state.nivel === 'gerente'
                                            ? <form className="m-0 mb-3 row" action="">

                                                <div className="col-12 bg-secondary m-0 text-white p-1 mb-3">
                                                    <h4>Lançamentos</h4>
                                                </div>

                                                <div className="col-2">
                                                    <label>Retirar</label>
                                                    <input
                                                        type="number"
                                                        className="w-100"
                                                        onChange={(event) =>
                                                            this.setState({valor: event.target.value})
                                                        }
                                                        value={this.state.valor}
                                                    />
                                                </div>

                                                <div className="col-4">
                                                    <label>De</label>
                                                    <Select
                                                        badge="Single select"
                                                        placeholder={''}
                                                        onChange={(e) =>
                                                            this.setState({operadorSubtraido: e.value})
                                                        }
                                                        options={this.state.operadores}
                                                    />
                                                </div>

                                                <div className="col-4">
                                                    <label>Para</label>
                                                    <Select
                                                        badge="Single select"
                                                        placeholder={''}
                                                        onChange={(e) =>
                                                            this.setState({operadorRecebedor: e.value})
                                                        }
                                                        options={this.state.operadores}
                                                    />
                                                </div>

                                                <div className="col-2 mt-4">
                                                    <button
                                                        className={'btn btn-success w-100'}
                                                        onClick={this.criarLancamento}
                                                    >
                                                        Salvar
                                                    </button>
                                                </div>
                                            </form>
                                            : ''
                                    }

                                    <table className={`table `}>
                                        <thead>
                                        <tr>
                                            <th>cambista</th>
                                            <th>tipo</th>
                                            <th>valor</th>
                                            <th>data</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.lancamentos.map((l) => (
                                            <tr key={l.id}>
                                                <td>{l.nome}</td>
                                                <td>{l.descricao}</td>
                                                <td>
                                                    {l.tipo === 'credito' ? (
                                                        <span className="text-success font-weight-bold">
																{l.valor}
															</span>
                                                    ) : (
                                                        <span className="text-danger font-weight-bold">
																- {l.valor}
															</span>
                                                    )}
                                                </td>
                                                <td>{l.data}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
