import {toast} from "react-toastify";

export const alerta = (data, message = null) => {

    const {tipo, mensagens} = defineTipo(data);

    mensagens.forEach((texto) => {

        if (tipo === 'error') {
            toast.error(texto);
        } else {
            toast.success(texto);
        }

    });

}

const defineTipo = (data) => {

    const dados = {
        tipo: null,
        mensagens: [],
    }

    if (data.errors) {

        dados.tipo = 'error';

        Object.keys(data.errors).forEach((erro) => {
            data.errors[erro].forEach((texto) => {
                dados.mensagens.push(texto);
            });
        });

    }

    if (data.success) {
        dados.tipo = 'success';
        dados.mensagens.push(data.message);
    }

    if (data.success === false) {
        dados.tipo = 'error';
        dados.mensagens.push(data.message);
    }

    return dados
}

// export default function Alert({ message }) {
//
//   const teste = () => {
//     console.log('aaaaaaaaa')
//     toast.info(message);
//   }
//
//   return console.log('aaaaaaaaa')
// }
