import { useState, useEffect } from 'react';
import url from './url';

export default function Logo() {
	const [logo, setLogo] = useState();

	useEffect(() => {
		const getLogo = async () => {
			const { logotipo } = await JSON.parse(localStorage.getItem('usuario'));
			setLogo(logotipo);
		};
		getLogo();
	}, []);

	return (
		<>
			{logo !== null ? (
				<img src={`https://setebit-space.nyc3.cdn.digitaloceanspaces.com/bancas/${logo}`} alt="logotipo" />
			) : null}
		</>
	);
}
