import React from 'react';

const ButtonsRoleta = ({numeros ,onClickButton}) => {

  return (
    <div className="row">
      {
        Object.keys(numeros).map((item) =>
          <div className="col">
            <div 
              className={`card m-1 text-white bg-${numeros[item].cor}`}
              onClick={() => onClickButton(item)}
            >
              <div className="card-body p-2">
                {item}
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ButtonsRoleta;
