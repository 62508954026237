const index = ({ valor, func, name }) => {
	return (
		<div className="input-criacao-usuario margin-vertical-30">
			<label className="titulo-input-criacao">{name}:</label>
			<input
				required
				onChange={(c) => func(c.target.value)}
				type="text"
				maxLength="30"
				className="form-control input-dados-criar"
				value={valor || ''}
			/>
		</div>
	);
};

export default index;
