import {useState, useEffect} from 'react';
import http from '../../../../services/http';

import {ListaTimes} from "../../../../components/ListaTimes";
import {alerta} from "../../../../components/Alert";

export default function BolaoCriar(props) {
  const [loadingAtivo, setLoadingAtivo] = useState(true);
  const [credenciais, setCredenciais] = useState({});
  const [confrontos, setConfrontos] = useState([
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
    {matche_id: '', casa: '', fora: '', data: '', hora: '', placarCasa: '', placarFora: ''},
  ]);
  const [times, setTimes] = useState([]);
  const [campeonato, setCampeonato] = useState([]);

  useEffect(() => {
    setLoadingAtivo(false);
  }, []);

  const adicionarTime = () => {
    if (confrontos.length === 10) return

    setConfrontos([
      ...confrontos,
      {
        matche_id: '',
        casa: '',
        fora: '',
        data: '',
        hora: ''
      }
    ])
  }

  const removerTime = () => {
    if (confrontos.length == 6) return

    confrontos.pop();

    setConfrontos([...confrontos])
  }

  const defineCredenciais = (ev) => {
    const {name, value} = ev.target;

    setCredenciais({
      ...credenciais
      , [name]: value
    })
  }

  const defineConfrontos = (ev) => {
    const {name, value, id: index} = ev.target;

    confrontos.forEach((el, ind) => {
      if (index == ind) {

        const matche = campeonato.matches && campeonato.matches.find(matche => {
          if (matche.awayTeam.name === el.fora && matche.homeTeam.name === el.casa) {
            return matche
          }
        })

        el.casa = name === 'casa' ? value : el.casa
        el.fora = name === 'fora' ? value : el.fora
        el.data = name === 'data' ? value : el.data
        el.hora = name === 'hora' ? value : el.hora
        el.matche_id = matche && matche.id
      }
    });

  }

  const salvarBolao = async (ev) => {
    ev.preventDefault();
    const confrontos_times = [];

    try {

      const confronto = confrontos.reduce((ant, att) => (
        new Date(ant.data + ' ' + ant.hora) < new Date(att.data + ' ' + att.hora) ?
          ant : att
      ))

      await confrontos.forEach((el) => {
        confrontos_times.push({
          time_casa: el.casa,
          time_fora: el.fora,
          matche_id: el.matche_id,
          placar_casa: 0,
          placar_fora: 0,
          data_inicio: el.data + ' ' + el.hora,
        })
      });

      const { data: { data } } = await http.post('/boloes/', {
        nome: credenciais.nome,
        valor: credenciais.valor,
        tipo: credenciais.tipo,
        primeiro_premio: credenciais.tipo === 'placar' ? credenciais.premio1 : 0,
        segundo_premio: credenciais.tipo === 'placar' ? credenciais.premio2 : 0,
        terceiro_premio: credenciais.tipo === 'placar' ? credenciais.premio3 : 0,
        porcentagem_premio: credenciais.tipo === 'pontos' ? credenciais.porcentagemPremio : 0,
        premio_acumulado: credenciais.tipo === 'pontos' ? credenciais.premioAcumulado : 0,
        data_limite: confronto.data + ' ' + confronto.hora,
        confrontos: confrontos_times,
      })

      props.history.push('/bolao/' + data.id)
    } catch (error) {
      const { data } = error.response;
      
      alerta(error);

    }
  }

  return (
    <form onSubmit={salvarBolao}>
      <div className="titulo-principal">Novo Bolão</div>

      <div className="conteudo-principal">
        <div className="d-flex tres-colunas">
          <div className="form-group">
            <label className="control-label">Nome</label>
            <input
              required
              type="text"
              className="form-control"
              onChange={defineCredenciais}
              name='nome'
            />
          </div>
          <div className="form-group">
            <label className="control-label">Valor</label>
            <select
              className="form-control"
              onChange={defineCredenciais}
              name='valor'
            >
              <option value=""></option>
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-label">Tipo</label>
            <select
              className="form-control"
              onChange={defineCredenciais}
              name='tipo'
            >
              <option value=""></option>
              <option value="pontos">Pontos</option>
              <option value="placar">Placar</option>
            </select>
          </div>
        </div>
        <div className="d-flex tres-colunas">
          <div className="form-group">
            {
              credenciais && credenciais.tipo == 'placar' ?
                (
                  <>
                    <label className="control-label">{ confrontos.length } acertos</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      onChange={defineCredenciais}
                      name='premio1'
                    />
                  </>
                ) : (
                  <>
                    <label className="control-label">% Premio</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      onChange={defineCredenciais}
                      name='porcentagemPremio'
                    />
                  </>
                )
            }
          </div>

          <div className="form-group">
            {
              credenciais && credenciais.tipo == 'placar' ?
                (
                  <>
                    <label className="control-label">{ confrontos.length -1 } acertos</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      onChange={defineCredenciais}
                      name='premio2'
                    />
                  </>
                ) : (
                  <>
                    <label className="control-label">Premio</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      onChange={defineCredenciais}
                      name='premioAcumulado'
                    />
                  </>
                )
            }
          </div>
          
          <div className="form-group">
            {
              credenciais && credenciais.tipo == 'placar' ?
                <>
                  <label className="control-label">
                    { confrontos.length - 2} acertos
                  </label>
                  <input
                    required
                    type="number"
                    className="form-control"
                    onChange={defineCredenciais}
                    name='premio3'
                  />
                </> : ''
            }
          </div>
        </div>
      </div>

      <div className="titulo-principal mt-2">
        <div className="mb-0 my-auto">Times</div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-info"
            onClick={ () => ListaTimes(setTimes) }
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div className="conteudo-principal">

        <div className="d-flex quatro-colunas">
          <label className="control-label">Time Casa</label>
          <label className="control-label">Time Fora</label>
          <label className="control-label">Horário</label>
          <label className="control-label"> </label>
        </div>

        {
          confrontos && confrontos.map((el, ind) =>
            <div className="d-flex quatro-colunas" key={ind}>

              <div className="form-group">
                <input
                  required
                  className="form-control"
                  name="casa"
                  id={ind}
                  onChange={defineConfrontos}
                  list="times"
                  autoComplete="off"
                />
                <datalist id="times">
                  <option value=""/>
                  {times && times.map(el => (
                    <option key={el.id} value={el.name}>{el.name}</option>
                  ))}
                </datalist>

              </div>

              <div className="form-group">
                <input
                  required
                  className="form-control"
                  name="fora"
                  id={ind}
                  onChange={defineConfrontos}
                  list="times"
                  autoComplete="off"
                />
                <datalist id="times">
                  <option value=""/>
                  {times && times.map(el => (
                    <option key={el.id} value={el.name}>{el.name}</option>
                  ))}
                </datalist>

              </div>

              <div className="form-group">
                <input
                  required
                  type="date"
                  className="form-control"
                  name="data"
                  id={ind}
                  onChange={defineConfrontos}
                />
              </div>

              <div className="form-group">
                <input
                  required
                  type="time"
                  className="form-control"
                  name="hora"
                  id={ind}
                  onChange={defineConfrontos}
                />
              </div>

            </div>
          )
        }

        <button
          className="btn btn-secondary"
          onClick={adicionarTime}
          type="button"
        >
          Adicionar time
        </button>

        <button
          className="btn btn-info ml-2"
          onClick={removerTime}
          type="button"
        >
          Remover time
        </button>
      </div>

      <div className="d-flex duas-colunas m-t">
        <button
          className="btn btn-danger"
          onClick={() => props.history.push('/bolao')}
        >
          Cancelar
        </button>
        <button
          className="btn btn-success"
          type="submit"
        >
          Salvar
        </button>
      </div>
      {loadingAtivo && <div className="loader"/>}
    </form>
  );
}
