import {Component} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import http from 'services/http';
import {alerta} from "../../../components/Alert";
import {toast} from "react-toastify";

const MySwal = withReactContent(Swal);

export default class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            senhaAtual: '',
            novaSenha: '',
            confirmarNovaSenha: '',
            liberado: false,
            carregaPost: false,
        };
    }

    handleSubmit = async () => {
        try {
            this.setState({carregaPost: true})
            const {senhaAtual, novaSenha, confirmarNovaSenha} = this.state;

            if ((novaSenha.length < 6 || novaSenha.length > 18) && novaSenha.length !== 0) {
                toast.error('A senha deve ter entre 6 e 18 caracteres');
                this.setState({carregaPost: false})
                return;
            }

            const res = await http.patch('usuarios/senha', {
                password: novaSenha,
                password_confirmation: confirmarNovaSenha,
                old_password: senhaAtual
            });

            alerta(res.data)

            this.setState({carregaPost: false})
        } catch (e) {
            this.setState({carregaPost: false})
            alerta(e.response.data)
        }
    };

    render() {
        return (
            <div className="bordered">
                <div className="titulo-principal">Senha</div>
                <div className="btn-group caixa-inputs">
                    <div className="input-criacao-usuario margin-vertical-30">
                        <label className="titulo-input-criacao-senha">Senha atual:</label>
                        <input
                            required
                            onChange={(c) => this.setState({senhaAtual: c.target.value})}
                            type="password"
                            maxLength="10"
                            className="form-control input-dados-criar"
                        />
                    </div>
                    <div className="input-criacao-usuario margin-vertical-30">
                        <label className="titulo-input-criacao-senha">Nova senha:</label>
                        <input
                            required
                            onChange={(c) => this.setState({novaSenha: c.target.value})}
                            type="password"
                            maxLength="10"
                            className="form-control input-dados-criar"
                        />
                    </div>
                    <div className="input-criacao-usuario margin-vertical-30">
                        <label className="titulo-input-criacao-senha">Confirmar nova senha:</label>
                        <input
                            required
                            onChange={(c) =>
                                this.setState({confirmarNovaSenha: c.target.value})
                            }
                            type="password"
                            maxLength="10"
                            className="form-control input-dados-criar"
                        />
                    </div>
                </div>
                <div
                    className="d-flex duas-colunas"
                    style={{
                        width: '540px',
                        margin: '12px 0px 20px 210px',
                    }}
                >

                    <button
                        className="btn btn-success"
                        onClick={() => {
                            this.handleSubmit();
                        }}
                        disabled={this.state.carregaPost}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        );
    }
}
