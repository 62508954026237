import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import http from 'services/http';
import ListaResponsiva from 'components/Usuarios/Lista';

export default function Usuarios() {
	const [listaUsuarios, setListaUsuarios] = useState([]);
	const [situacao] = useState('todos');
	const [nomeFilter, setNomeFilter] = useState('');
	const [loadingAtivo, setLoadingAtivo] = useState(false);

	const requestUsuarios = async () => {
		try {
			setLoadingAtivo(true);
			const response = await http.get('/usuarios', {
				params: {
					nivel: 'gerente'
				}
			});
			const { data: usuarios } = response.data;
			setListaUsuarios(usuarios);
			setLoadingAtivo(false);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		requestUsuarios();
	}, []);

	const desativarGerente = async (id, ativo) => {
		try {
			await http.patch('/usuarios/' + id + '/status', {
        status: !ativo
			});

			const novaLista = listaUsuarios.filter((item) => {
				if (item.id === id) {
					item.ativo = !item.ativo;
				}

				return item;
			});

			funcaoQueFiltra(novaLista);
		} catch (e) {
			console.log(e);
		}
	};

	const funcaoQueFiltra = (lista) => {
		if (lista) setListaUsuarios(lista);
		return listaUsuarios.filter((usuario) => {
			let retorna = false;
			if (situacao === 'ativo') {
				usuario.ativo ? (retorna = true) : (retorna = false);
			} else if (situacao === 'inativo') {
				usuario.ativo ? (retorna = false) : (retorna = true);
			} else {
				retorna = true;
			}
			if (usuario.gerente) {
				return (
					usuario.login
						.toLowerCase()
						.indexOf(nomeFilter.toString().toLowerCase()) >= 0 ||
					(usuario.gerente.login
						.toLowerCase()
						.indexOf(nomeFilter.toString().toLowerCase()) >= 0 &&
						retorna)
				);
			} else {
				return (
					usuario.login
						.toLowerCase()
						.indexOf(nomeFilter.toString().toLowerCase()) >= 0 && retorna
				);
			}
		});
	};

	return (
		<>
			<div className="titulo-principal">
				<span>Gerentes</span>
			</div>
			<div className="conteudo-principal d-flex duas-colunas">
				<input
					onChange={(c) => setNomeFilter(c.target.value)}
					type="text"
					className="form-control"
					placeholder="Filtrar por nome"
				/>
				<Link 
				    style={{height:'38px' }}
				    className="btn btn-info" 
					to="/usuarios/criar/gerente">
					Novo Gerente
				</Link>
			</div>

			{loadingAtivo ? (
				<div className="loader" />
			) : (
				<div className="m-t">
					<ListaResponsiva
						tBody={funcaoQueFiltra()}
						showButton1={true}
						showButton2={true}
						tButton2={desativarGerente}
						nivel="gerente"
					/>
				</div>
			)}
		</>
	);
}
