import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import {NavLink} from 'react-router-dom';

import http from 'services/http';

import {alerta} from "../../../components/Alert";

export default function Resultado() {
    const [modulos] = useState(JSON.parse(localStorage.getItem('usuario')).modulos);
    const [loterias, setLoterias] = useState([]);
    const [resultadoConcurso, setResultadoConcurso] = useState([]);
    const [data, setData] = useState(dayjs().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [tipoResultado, setTipoResultado] = useState('')

    useEffect(() => {
        if (modulos.includes('JB')) {
            setTipoResultado('jb');
            return;
        }
        
        if (modulos.includes('Lototime')) {
            setTipoResultado('lototime');
            return;
        }
        
        if (modulos.includes('Seninha')) {
            setTipoResultado('Seninha');
            return;
        }
        
        if (modulos.includes('2 para 500')) {
            setTipoResultado('DoisQuinhentos');
            return;
        }
        
        if (modulos.includes('Rifa')) {
            setTipoResultado('Rifa');
            return;
        }
        
        if (modulos.includes('Sena Brasil')) {
            setTipoResultado('Sena Brasil');
            return;
        }
        
        if (modulos.includes('Bolão de Dezena')) {
            setTipoResultado('Bolao de Dezenas');
        }
        
    }, []);

    useEffect(() => {
        if (tipoResultado === "jb") {
            const buscarLoterias = async () => {
                setLoading(true);
                try {
                    const response = await http.get('jb/extracoes/encerradas', {
                        params: {data}
                    });

                    const {data: loterias} = response.data;

                    setLoterias(loterias);
                } catch (e) {
                    alerta(e.response.data)
                }
                setLoading(false);
            };

            buscarLoterias();
        }

        if (tipoResultado === "lototime") {
            const buscarLoterias = async () => {
                setLoading(true);
                try {
                    const response = await http.get('lototime/extracoes/encerradas', {
                        params: {data}
                    });

                    const {data: loterias} = response.data;

                    setLoterias(loterias);
                } catch (e) {
                    alerta(e.response.data)
                }
                setLoading(false);
            };

            buscarLoterias();
        }

        if (tipoResultado === "Seninha" || tipoResultado === "Quininha" || tipoResultado === "Lotinha") {
            buscarConcursoSeninha();
        }

        if (tipoResultado === "Sena Brasil" || tipoResultado === "Quina Brasil" || tipoResultado === "Loto Brasil") {
            buscarConcursoSenaBrasil();
        }

        if (tipoResultado === "DoisQuinhentos") {
            buscarDoisQuinhentos();
        }

        if (tipoResultado === "Rifa") {
            buscarRifa();
        }

        if (tipoResultado === "Bolao de Dezenas") {
            buscarBolaoDezena();
        }

    }, [data, tipoResultado]);

    async function buscarConcursoSeninha() {
        try {
            setLoading(true);
            const {data: response} = await http.get('/seninha/concursos/resultados/pendentes', {
                params: {
                    data_resultado: data,
                    tipo_concurso: tipoResultado
                }
            });

            if (response.success) {
                setResultadoConcurso(response.data)
            }
            setLoading(false);
        } catch (e) {
            alerta(e.response.data);
            setLoading(false);
        }
    }

    async function buscarConcursoSenaBrasil() {
        try {
            setLoading(true);
            const {data: response} = await http.get('/sena-brasil/concursos/resultados/pendentes', {
                params: {
                    data_resultado: data,
                    tipo_concurso: tipoResultado
                }
            });

            if (response.success) {
                setResultadoConcurso(response.data)
            }
            setLoading(false);
        } catch (e) {
            alerta(e.response.data);
            setLoading(false);
        }
    }

    async function buscarDoisQuinhentos() {
        setLoading(true);
        const {data: response} = await http.get('/doisquinhentos', {
            params: {
                data_resultado: data,
            }
        });

        if (response.success) {
            setResultadoConcurso(response.data)
        }
        setLoading(false);
    }

    async function buscarRifa() {
        setLoading(true);
        const {data: response} = await http.get('/rifas', {
            params: {
                data_resultado: data,
            }
        });

        if (response.success) {
            setResultadoConcurso(response.data)
        }
        setLoading(false);
    }

    async function buscarBolaoDezena() {
        setLoading(true);
        const {data: response} = await http.get('/boloes-dezena/resultados', {
            params: {
                data_resultado: data,
            }
        });

        if (response.success) {
            setResultadoConcurso(response.data)
        }
        setLoading(false);
    }

    return (
        <>
            <div className="titulo-principal">Resultados</div>

            <div className="d-flex border-top-0 duas-colunas conteudo-principal">
                <select
                    required
                    className="form-control text-capitalize"
                    onChange={(e) => setTipoResultado(e.target.value)}
                    value={tipoResultado}
                >
                    {/*<option>Escolher modalidade</option>*/}

                    {modulos.includes('JB') && <option value="jb">Tradicional</option>}
                    {modulos.includes('Lototime') && <option value="lototime">Lototime</option>}
                    {modulos.includes('Seninha') && <option value="Seninha">Seninha</option>}
                    {modulos.includes('Seninha') && <option value="Quininha">Quininha</option>}
                    {modulos.includes('Seninha') && <option value="Lotinha">Lotinha</option>}
                    {modulos.includes('2 para 500') && <option value="DoisQuinhentos">2 para 500</option>}
                    {modulos.includes('Rifa') && <option value="Rifa">Rifa</option>}
                    {modulos.includes('Sena Brasil') && <option value="Sena Brasil">Sena Brasil</option>}
                    {modulos.includes('Sena Brasil') && <option value="Quina Brasil">Quina Brasil</option>}
                    {modulos.includes('Sena Brasil') && <option value="Loto Brasil">Loto Brasil</option>}
                    {modulos.includes('Bolão de Dezena') && <option value="Bolao de Dezenas">Bolão de Dezenas</option>}
                </select>

                <input
                    type="date"
                    className="form-control"
                    placeholder="Data"
                    value={data}
                    onChange={(event) => setData(event.target.value)}
                />
            </div>

            {loading && <div className="loader"/>}

            {!loading && (tipoResultado === "jb" || tipoResultado === "lototime") && (
                <div className="borderTable m-t">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Loteria</th>
                            <th>Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loterias.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <b>{item.nome}</b>
                                    </td>
                                    <td>
                                        <NavLink
                                            className={
                                                item.resultado ? 'btn btn-success' : 'btn btn-danger'
                                            }
                                            exact
                                            to={{
                                                pathname: '/resultado/adicionar/',
                                                loteria: item,
                                                data: data,
                                                tipo: tipoResultado
                                            }}
                                        >
                                            {item.resultado ? 'Ver Resultado' : 'Adicionar'}
                                        </NavLink>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading &&
                (tipoResultado === "Seninha" || tipoResultado === "Quininha" || tipoResultado === "Lotinha") &&
                resultadoConcurso.length > 0 && (
                    <div className="borderTable m-t">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Concurso</th>
                                <th>Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {resultadoConcurso.map((concurso, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <b>{concurso.nome}</b>
                                        </td>
                                        <td>
                                            <NavLink
                                                className={
                                                    concurso.resultado ? 'btn btn-success' : 'btn btn-danger'
                                                }
                                                exact
                                                to={{
                                                    pathname: '/seninha/resultado',
                                                    concurso: concurso,
                                                    data: data
                                                }}
                                            >
                                                {concurso.resultado ? 'Ver Resultado' : 'Adicionar'}
                                            </NavLink>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )}

            {!loading &&
                (tipoResultado === "Sena Brasil" || tipoResultado === "Quina Brasil" || tipoResultado === "Loto Brasil") &&
                resultadoConcurso.length > 0 && (
                    <div className="borderTable m-t">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Concurso</th>
                                <th>Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {resultadoConcurso.map((concurso, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <b>{concurso.nome}</b>
                                        </td>
                                        <td>
                                            <NavLink
                                                className={
                                                    concurso.resultado ? 'btn btn-success' : 'btn btn-danger'
                                                }
                                                exact
                                                to={{
                                                    pathname: '/sena-brasil/resultado',
                                                    concurso: concurso,
                                                    data: data
                                                }}
                                            >
                                                {concurso.resultado ? 'Ver Resultado' : 'Adicionar'}
                                            </NavLink>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )}

            {!loading && tipoResultado === "Bolao de Dezenas" &&
                resultadoConcurso.length > 0 && (
                    <div className="borderTable m-t">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Loteria</th>
                                <th>Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {resultadoConcurso.map((concurso, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <b>{concurso.nome}</b>
                                        </td>
                                        <td>
                                            <NavLink
                                                className={
                                                    concurso.resultados ? 'btn btn-success' : 'btn btn-danger'
                                                }
                                                exact
                                                to={{
                                                    pathname: '/bolao-dezena/resultado/' + concurso.id,
                                                    concurso: concurso,
                                                    data: data
                                                }}
                                            >
                                                {concurso.resultado ? 'Ver Resultado' : 'Adicionar'}
                                            </NavLink>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )}

            {!loading && tipoResultado === "DoisQuinhentos" && (
                <div className="borderTable m-t">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Extração</th>
                            <th>Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        {resultadoConcurso.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <b>{item.nome} - {dayjs(item.data_limite).format('HH:mm')}</b>
                                    </td>
                                    <td>
                                        <NavLink
                                            className={
                                                item.resultado ? 'btn btn-success' : 'btn btn-danger'
                                            }
                                            exact
                                            to={{
                                                pathname: '/doisquinhentos/resultado',
                                                extracao: item
                                            }}
                                        >
                                            {item.resultado ? 'Ver Resultado' : 'Adicionar'}
                                        </NavLink>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading && tipoResultado === "Rifa" && (
                <div className="borderTable m-t">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Extração</th>
                            <th>Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        {resultadoConcurso.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <b>{item.nome} - {dayjs(item.data_limite).format('HH:mm')}</b>
                                    </td>
                                    <td>
                                        <NavLink
                                            className={
                                                item.resultado ? 'btn btn-success' : 'btn btn-danger'
                                            }
                                            exact
                                            to={{
                                                pathname: '/rifa/resultado',
                                                extracao: item
                                            }}
                                        >
                                            {item.resultado ? 'Ver Resultado' : 'Adicionar'}
                                        </NavLink>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}
