import {useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import styles from './styles.module.css';
import http from 'services/http';
import {useHistory} from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

import Modal from 'react-modal';
import {alerta} from "../../../components/Alert";
import dayjs from "dayjs";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '550px',
        background: '#f6ecc7',
    },
};


export default function Sorteio() {
    const [sorteios, setSorteio] = useState([]);
    const [premio, setPremio] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exibirModal, setExibirModal] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [aposta, setAposta] = useState({});
    const [info, setInfo] = useState('');

    const history = useHistory();

    function openModal(aposta, info) {
        setAposta(aposta);
        setInfo(info);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function requestSorteios() {
        try {
            setLoading(true);
            const response = await http.get('/sorteios');
            setSorteio(response.data.data);

            setLoading(false);
        } catch (e) {
            alerta(e.response.data)
        }
    }

    useEffect(() => {
        requestSorteios();
    }, [])

    async function handleDelete(id) {

        Swal.fire({
            title: 'Deseja cancelar o sorteio?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Não, voltar'
        }).then((result) => {
            if (result.value) {
                deleteSorteio(id);
            }
        });
    }

    async function deleteSorteio(id) {
        try {
            const res = await http.patch('/sorteios/' + id);
            requestSorteios();
            alerta(res.data)
        } catch (error) {
            alerta(error.response.data)
        }
    }

    return (
        <>
            <div className="titulo-principal">Sorteio</div>
            <div className={`conteudo-principal ${styles.header_sorteio}`}>
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => history.push('/sorteio/criar')}
                >
                    Novo Sorteio
                </button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className={styles.containerModal}>
                    <div className={styles.premio}>
                        <i
                            size={40}
                            className={`fa fa-5x ${(aposta?.premio) ? aposta?.premio.icon : null}`} aria-hidden="true">
                        </i>
                    </div>
                    <div className={styles.ganhador}>
                        <p>Ganhador:</p>
                        <strong>{aposta?.codigo}</strong>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.text_info}>
                            <p>Gerente:</p>
                            <strong>{aposta?.gerente}</strong>
                        </div>
                        <div className={styles.text_info}>
                            <p>Cambista:</p>
                            <strong>{aposta?.colaborador}</strong>
                        </div>
                        <div className={styles.text_info}>
                            <p>Data:</p>
                            <strong>{aposta?.data}</strong>
                        </div>
                        <div className={styles.text_info}>
                            <p>Prêmio:</p>
                            <strong>{(aposta?.premio) ? aposta?.premio.premio : ''}</strong>
                        </div>
                        <div className={styles.text_info}>
                            <p>Informações:</p>
                            <strong>{info}</strong>
                        </div>
                    </div>
                    <div className={styles.buttonClose}>
                        <button className="btn btn-info" onClick={closeModal}>
                            Fechar
                        </button>
                    </div>
                </div>
            </Modal>

            {loading && <div className="loader"/>}

            {(sorteios.length > 0) ? (

                <div className={styles.table_sorteio}>

                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{padding: '5px'}}>Sorteio</th>
                            <th style={{padding: '5px'}}>Bilhetes</th>
                            <th style={{padding: '5px'}}>Data Criação</th>
                            <th style={{padding: '5px'}}>Encerramento</th>
                            <th style={{padding: '5px'}}>Premio</th>
                            <th style={{padding: '5px'}}>Status</th>
                            <th style={{padding: '5px'}}>Deletar</th>
                            <th style={{padding: '5px'}}>Visualizar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sorteios.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.situacao}</td>
                                    <td>{dayjs(item.created_at).format('DD/MM/YY HH:MM')}</td>
                                    <td>{dayjs(item.data_sorteio).format('DD/MM/YY HH:MM')}</td>
                                    <td>{item.premio}</td>
                                    {(item.ativo) ? (
                                        (item.status) ? (
                                            <>
                                                <td style={{color: '#6c757d'}}>Pendente</td>
                                                <td>
                                                    <button
                                                        className='btn btn-link'
                                                        onClick={() => handleDelete(item.id)}
                                                    >cancelar
                                                    </button>
                                                </td>
                                                <td></td>
                                            </>
                                        ) : (
                                            <>
                                                <td style={{color: '#1B970C'}}>Finalizado</td>
                                                <td>--</td>
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() => {
                                                        openModal(item.aposta_ganhadora, item.info);
                                                    }}
                                                >
                                                    Visualizar
                                                </button>
                                            </>
                                        )
                                    ) : (

                                        <>
                                            <td style={{color: '#ffc107'}}>Cancelado</td>
                                            <td>--</td>
                                            <td>--</td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={styles.noResult}>
                    <p>
                        Nenhum sorteio encontrado
                    </p>
                </div>
            )
            }
        </>
    );
}
