const Cabecalho = ({ user }) => (
	<>
		<div className="header-main">
			<div className="header-left">
				<div className="logo-name">
					<div className="logo buttonHeaderLogo">
						{/* <button
              style={{ border: "none" }}
              className="sidebar-icon"
              onClick={() => props.collapsed()}
            >
              <span className="fa fa-bars" />
            </button> */}
					</div>
				</div>
				<div className="clearfix"> </div>
			</div>
			<div className="header-right">
				<div className="profile_details">
					<ul>
						<li>
							<div className="profile_img">
								<div className="user-name">
									<p>{user.login}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div className="clearfix"> </div>
			</div>
			<div className="clearfix"> </div>
		</div>
	</>
);

export default Cabecalho;
