import { useState } from 'react';
import dayjs from 'dayjs';

import http from 'services/http';

import styles from './styles.module.css';

export default function Relatorio() {
	const data = dayjs().format('YYYY-MM-DD');

	const [dataInicial, setDataInicial] = useState(data);

	const baixarArquivo = async () => {
		try {
			const response = await http({
				url: '/apostas/relatorio', // your url
				method: 'GET',
				responseType: 'blob', // important
				params: {
					data_inicial: dataInicial,
					data_final: dataInicial
				}
			});

			const url = window.URL.createObjectURL(new Blob([response.data.data]));

			const link = document.createElement('a');

			link.href = url;

			const nome = `apostas-${dataInicial}.pdf`;

			link.setAttribute('download', nome);

			document.body.appendChild(link);

			link.click();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div className="titulo-principal">Relatório</div>
			<div className="conteudo-principal d-flex duas-colunas">
				<input
					required
					type="date"
					value={dataInicial}
					onChange={(e) => setDataInicial(e.target.value)}
					className="form-control"
				/>

				<button onClick={baixarArquivo}
				 className="btn btn-primary"
				 className={styles.buttonSalvarPDF}>
					Salvar PDF
				</button>
			</div>
		</>
	);
}
