import {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import http from '../../../services/http';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import styles from './extracao.module.css';
import PermissionGate from "../../../utils/PermissionGate";
import {alerta} from "../../../components/Alert";

const MySwal = withReactContent(Swal);

export default function Bolao(props) {
  const [listaBolao, setListaBolao] = useState([]);
  const [nomeFilter, setNomeFilter] = useState('');
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [loadingAtivo, setLoadingAtivo] = useState(true);

  useEffect(() => {
    const buscarBoloes = async () => {
      try {
        const {data: {data}} = await http.get('/boloes');
        setListaBolao(data);
        setLoadingAtivo(false);
      } catch (e) {
        setLoadingAtivo(false);
        alerta(e.response.data)
      }
    }
    buscarBoloes()
  }, []);

  useEffect(() => {
    const funcaoQueFiltra = () => {
      return listaBolao.filter((el) => {
        return (
          el.nome
            .toLowerCase()
            .indexOf(nomeFilter.toString().toLowerCase()) >= 0
        );
      });
    };
    const setFilter = () => {
      setListaFiltrada(funcaoQueFiltra());
    };

    setFilter();
  }, [nomeFilter, setListaFiltrada, listaBolao]);

  return (
    <>
      <div className="titulo-principal">Bolão</div>
      <div className="conteudo-principal d-flex duas-colunas">
        <input
          onChange={(c) => setNomeFilter(c.target.value)}
          type="text"
          className="form-control"
          placeholder="Filtrar por nome"
        />
        <PermissionGate permissions={['Criar bolão']}>
          <button
            type="button"
            className={`btn btn-info ${styles.btnCriar}`}
            onClick={() => props.history.push('/bolao/criar')}
          >
            Novo Bolão
          </button>
        </PermissionGate>
      </div>

      <div className="conteudo-principal p-0 mt-2">

        <table className="table text-left border">
          <thead>
          <tr>
            <th>Bolão</th>
            <th>Nome</th>
            <th>Tipo</th>
            <th>Criado</th>
            <th>Encerra</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            listaFiltrada && listaFiltrada.map((el, ind) =>
              <tr key={ind}>
                <td>{el.id}</td>
                <td>{el.nome}</td>
                <td>{el.tipo}</td>
                <td>{dayjs(el.created_at).format('DD/MM/YYYY HH:mm')}</td>
                <td>{dayjs(el.data_limite).format('DD/MM/YYYY HH:mm')}</td>
                <td>
                  {el.data_cancelado ? 'Cancelado' :
                    new Date(el.data_limite) > new Date() ?
                      'Andamento' : 'Finalizado'
                  }
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-info w-100 px-0"
                    onClick={() => props.history.push('/bolao/' + el.id)}
                  >
                    Ver
                  </button>
                </td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>

      {loadingAtivo && <div className="loader"/>}
    </>
  );
}
