import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import http from '../../../services/http';
import styles from './styles.module.css';
import {alerta} from "../../../components/Alert";

export default function Modalidades(props) {

    const [modalidadesBanca, setModalidadesBanca] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isActiveFieldsEditModalidade, setIsActiveFieldsEditModalidade] = useState(true);

    const [carregaPost, setCarregaPost] = useState(false);

    const naoMostrar = [
        'Milhar Brinde', 'Milhar Moto', 'Unidade'
    ];

    const submodalidades = [
        'Terno de Grupo 1/3',
        'Terno de Grupo 1/5', 'Terno de Grupo 1/10', 'Passe 1/2',
        'Passe 1/5', 'Passe vai e vem 1/2', 'Passe vai e vem 1/5',
        'Duque de Grupo 1/2', 'Duque de Grupo 1/5', 'Duque de Dezena Combinado  1/2',
        'Duque de Dezena Combinado 1/5', 'Terno de Dezena Combinado  1/3', 'Terno de Dezena Combinado 1/5',
        'Terno de Grupo Combinado  1/3', 'Terno de Grupo Combinado 1/5', 'Duque de Dezena 1/2', 'Duque de Dezena 1/5',
        'Terno de Dezena 1/3', 'Terno de Dezena 1/5', 'Terno de Dezena 1/10'
    ]

    async function requestBanca() {
        try {
            const response = await http.get('/bancas/modalidades');
            const {data: modalidades} = response.data;

            setModalidadesBanca(modalidades);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            alerta(e.response.data)
        }
    }

    useEffect(() => {
        requestBanca();
    }, []);

    function push() {
        return props.history.push('/');
    }

    function atualizarEstado(index, campo, element) {
        try {
            const arrayModalidades = modalidadesBanca;
            arrayModalidades[index][campo] = element;
            setModalidadesBanca([...arrayModalidades]);
        } catch (e) {
            console.log(e);
        }
    }

    async function atualizarModalidade() {
        try {
            setCarregaPost(true);
            const res = await http.patch('/bancas/modalidades', {
                modalidades: modalidadesBanca,
            });

            alerta(res.data)

            setIsActiveFieldsEditModalidade(true);
            setCarregaPost(false);
        } catch (e) {
            setCarregaPost(false);
            alerta(e.response.data)
        }
    }

    function handleClickEditModalidade() {
        setIsActiveFieldsEditModalidade(!isActiveFieldsEditModalidade);
    }

    return (
        <>
            {isLoading && <div className='loader'/>}

            {!isLoading && modalidadesBanca.length > 0 && (
                <div className='mt-3'>
                    <div className='bordered'>
                        
                        <div>
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th>Modalidade</th>
                                    <th>Valor Minimo</th>
                                    <th>Valor Maximo</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {modalidadesBanca.map((modalidade, index) => {
                                    if (!naoMostrar.includes(modalidade.nome)) {
                                        return (
                                            <tr key={index}>
                                                <td>{modalidade.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}</td>

                                                {submodalidades.includes(modalidade.nome) ? (
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            disabled
                                                            value="---"
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <input
                                                            onChange={(event) =>
                                                                atualizarEstado(
                                                                    index,
                                                                    'valor_minimo',
                                                                    event.target.value
                                                                )
                                                            }

                                                            type='number'
                                                            className='form-control'
                                                            disabled={isActiveFieldsEditModalidade}
                                                            value={modalidade.valor_minimo}
                                                        />
                                                    </td>
                                                )}

                                                {submodalidades.includes(modalidade.nome) ? (
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            disabled
                                                            value="---"
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <input
                                                            onChange={(event) =>
                                                                atualizarEstado(
                                                                    index,
                                                                    'valor_maximo',
                                                                    event.target.value
                                                                )
                                                            }

                                                            type='number'
                                                            className='form-control'
                                                            disabled={isActiveFieldsEditModalidade}
                                                            value={modalidade.valor_maximo}
                                                        />
                                                    </td>
                                                )}

                                                {!submodalidades.includes(modalidade.nome) ? (
                                                    <td>
                                                        <button
                                                            onClick={() => atualizarEstado(index, 'ativo', !modalidade.ativo)}
                                                            className={`btn ${
                                                                modalidade.ativo ? 'btn-success' : 'btn-danger'
                                                            }`}
                                                            disabled={carregaPost}
                                                        >
                                                            {modalidade.ativo ? 'Ativa' : 'Bloqueada'}
                                                        </button>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <button
                                                            onClick={() => atualizarEstado(index, 'ativo', !modalidade.ativo)}
                                                            className={`${modalidade.ativo ? 'text-success' : 'text-danger'} font-weight-bold text-decoration-underline`}
                                                        >
                                                            {modalidade.ativo ? 'Desbloqueado' : 'Bloqueado'}
                                                        </button>
                                                    </td>
                                                )}

                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex duas-colunas' style={{padding: '10px'}}>
                            {isActiveFieldsEditModalidade ? (
                                <button
                                    className='btn btn-info'
                                    onClick={() => handleClickEditModalidade()}
                                    disabled={carregaPost}
                                >
                                    Editar
                                </button>
                            ) : (
                                <>
                                    <button
                                        className='btn btn-secondary'
                                        onClick={() => handleClickEditModalidade()}
                                        disabled={carregaPost}
                                    >
                                        Desabilitar
                                    </button>
                                </>
                            )}
                            <button
                                className='btn btn-success'
                                onClick={() => atualizarModalidade()}
                                disabled={carregaPost}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}
