import {Component} from 'react';
import Swal from 'sweetalert2';

import http from 'services/http';
import formatMoney from 'utils/formatMoney';
import {alerta} from "../../../../components/Alert";

export default class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            operador: '',
            caixa: null,
            loading: true
        };
    }

    componentDidMount() {
        this.buscarCaixa();
    }

    buscarCaixa = async () => {
        try {
            const {id} = this.props.match.params;

            const response = await http.get(`prestacao/cambista/${id}`);

            const caixa = response.data.data;

            caixa.valor_total = caixa.total_cambista;
            caixa.entrada = formatMoney(caixa.entrada);
            caixa.entrada_aberta = formatMoney(caixa.entrada_aberta);
            caixa.saidas = formatMoney(caixa.saida);
            caixa.comissoes = formatMoney(caixa.comissao_cambista);
            caixa.lancamentos = formatMoney(caixa.lancamento);
            caixa.subtotal = formatMoney(caixa.total_banca);
            caixa.total = formatMoney(caixa.total_cambista);

            this.setState({caixa});
        } catch (e) {
            alerta(e.response.data);
        }

        this.setState({loading: false});
    };

    prestarContas = async () => {
        try {
            const {id} = this.props.match.params;

            const res = await http.post(`/prestacao/cambista`, {
                cambista_id: id
            });

            alerta(res.data);

            this.props.history.push('/prestacao/cambistas');
        } catch (e) {
            alerta(e.response.data);
        }
    };

    render() {

        return this.state.loading ? (
            <div className="loader"/>
        ) : (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '400px'
                }}
            >
                <div
                    className="caixaAdmin bordered"
                    style={{backgroundColor: '#5cb85c'}}
                >
                    Prestar contas com
                    <br/>
                    <b>{this.state.caixa.cambista}</b>
                </div>

                <div className="caixaAdmin bordered2">
                    Entrada: {this.state.caixa.entrada}
                </div>

                <div className="caixaAdmin bordered2">
                    Em aberto: {this.state.caixa.entrada_aberta}
                </div>

                <div className="caixaAdmin bordered2">
                    Saídas: {this.state.caixa.saidas}
                </div>

                <div className="caixaAdmin bordered2">
                    Comissão: {this.state.caixa.comissoes}
                </div>

                <div className="caixaAdmin bordered2">
                    Lançamentos: {this.state.caixa.lancamentos}
                </div>

                <div className="caixaAdmin bordered2">
                    <b>Total:&nbsp;</b>
                    <b
                        className={`${
                            this.state.caixa.valor_total > 1 ? 'text-success' : ''
                        } ${this.state.caixa.valor_total < 0 ? 'text-danger' : ''}`}
                    >
                        {this.state.caixa.total}
                    </b>
                </div>

                <div className="caixaAdmin bordered2">
                    <button
                        className="btn btn-success btn-lg"
                        onClick={() => this.prestarContas()}
                        type="button"
                    >
                        Prestar contas
                    </button>
                </div>
            </div>
        );
    }
}
