import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import formatMoney from 'utils/formatMoney';

import React, {useState, useMemo} from 'react';
import Pagination from '../../Paginacao';
import '../../Paginacao/style.css';
import PermissionGate from "../../../utils/PermissionGate";


// tButton2(linha.id, index, "cancelado");
const perguntarParaApagar = (id, index, fn) => {
    Swal.fire({
        title: 'Deseja cancelar a aposta?',
        text: 'Essa ação não poderá ser revertida!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true,
        confirmButtonText: 'Sim, desejo cancelar!',
        cancelButtonText: 'Não, voltar!'
    }).then((result) => {
        if (result.value) {
            fn(id, index, 'cancelado');
        }
    });
};

const font = {
    fontSize: '14px'
}

let PageSize = 50;

export default function index({
                                  tHead, tBody, showButtons, tButton1, tButton2, tFooter
                              }) {


    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return tBody.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);


    return (<div className="borderTable  m-t">
            <table className="table tablePagination">
                <thead>
                <tr>
                    {tHead.map((item, index) => {
                        return (<th key={index} scope="col">
                            {item}
                        </th>);
                    })}
                    {showButtons === true && <th scope="col">Bilhete</th>}
                    {showButtons === true && <PermissionGate permissions={["Remover aposta jb"]}>
                        <th scope="col">Cancelar</th>
                    </PermissionGate>}
                </tr>
                </thead>
                <tbody>
                {currentTableData.map((linha, index) => {
                    return (<tr
                        key={index}
                        className={linha.situacao === 'vencedor' ? ' ' : null}
                    >
                        <td>
                            {linha.codigo} <br/>
                            {linha.situacao === 'pendente' ? (<span className="badge bg-secondary">
											{linha.situacao
                                                .toLowerCase()
                                                .replace(/(?:^|\s)\S/g, function (a) {
                                                    return a.toUpperCase();
                                                })}
										</span>) : linha.situacao === 'vencedor' ? (
                                <span className="badge badge-success">
											{linha.situacao
                                                .toLowerCase()
                                                .replace(/(?:^|\s)\S/g, function (a) {
                                                    return a.toUpperCase();
                                                })}
										</span>) : linha.situacao === 'pago' ? (<span className="badge badge-primary">
											{linha.situacao
                                                .toLowerCase()
                                                .replace(/(?:^|\s)\S/g, function (a) {
                                                    return a.toUpperCase();
                                                })}
										</span>) : linha.situacao === 'cancelado' ? (
                                <span className="badge badge-warning">
											{linha.situacao
                                                .toLowerCase()
                                                .replace(/(?:^|\s)\S/g, function (a) {
                                                    return a.toUpperCase();
                                                })}
										</span>) : (<span className="badge badge-danger">
											{linha.situacao
                                                .toLowerCase()
                                                .replace(/(?:^|\s)\S/g, function (a) {
                                                    return a.toUpperCase();
                                                })}
										</span>)}
                        </td>
                        <td>
                            {dayjs(linha.created_at).format('DD/MM')} <br/>{' '}
                            {dayjs(linha.created_at).format('HH:mm')}
                        </td>
                        <td>
                            {linha.cambista}
                            <br/>
                            {linha.gerente && linha.gerente}
                        </td>
                        <td>
                            {formatMoney(parseFloat(linha.valor).toFixed(2))}
                            <br/>
                            {formatMoney(parseFloat(linha.comissao).toFixed(2))}
                        </td>
                        {linha.situacao === 'pago' ? (<td>
										<span style={font} className="badge badge-primary font-premio">
											{formatMoney(parseFloat(linha.premio).toFixed(2))}
										</span>
                            <br/>
                            {linha.pago_em && dayjs(linha.pago_em).format('DD/MM')}
                        </td>) : linha.situacao === 'perdedor' ? (<td>
										<span style={font} className="badge badge-danger font-premio">
											{formatMoney(parseFloat(linha.premio).toFixed(2))}
										</span>
                        </td>) : linha.situacao === 'cancelado' ? (<td>
										<span style={font} className="badge badge-warning font-premio">
											{formatMoney(parseFloat(linha.premio).toFixed(2))}
										</span>
                        </td>) : linha.situacao === 'vencedor' ? (<td>
										<span style={font} className="badge badge-success font-premio">
											{formatMoney(parseFloat(linha.premio).toFixed(2))}
										</span>
                        </td>) : (<td>
										<span style={font} className="badge badge-secondary font-premio">
											{formatMoney(parseFloat(linha.premio).toFixed(2))}
										</span>
                        </td>)}
                        <td>
                            <span>{linha.nome}</span>
                        </td>
                        {showButtons && (<td>
                            {tButton1 && (<button
                                className="btn btn-secondary"
                                onClick={() => {
                                    tButton1(linha.codigo, index);
                                }}
                            >
                                Bilhete
                            </button>)}
                        </td>)}

                        <PermissionGate permissions={["Remover aposta jb"]}>
                            <td>
                                {tButton2 && (<button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        perguntarParaApagar(linha.codigo, index, tButton2);
                                    }}
                                >
                                    Cancelar
                                </button>)}
                            </td>
                        </PermissionGate>

                    </tr>);
                })}
                </tbody>
                {tFooter && tFooter()}
            </table>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={tBody.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}
