import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { alerta } from "components/Alert/index";
import http from "services/http";

export default function DoisQuinhentosResultado({ location: dados }) {
  const [loading, setLoading] = useState(false);
  const [extracao, setExtracao] = useState({});
  const [resultados, setResultados] = useState([]);

  const mudarResultado = (jogo, numero) => {
    const arr = resultados.map((r) => r);
    if (numero.length <= 4) {
      arr[jogo] = numero;
      setResultados(arr);
    }
  };

  const enviarResultado = async () => {

    if (resultados.length < extracao.premios && extracao.tipo_resultado == 'normal') {
      return toast.error('Adicione todos os resultado')
    }

    if (resultados.length < extracao.premios/2 && extracao.tipo_resultado == 'inversa') {
      return toast.error('Adicione todos os resultado')
    }

    await toast.promise(
      http.post('/doisquinhentos/resultado/' + extracao.id, {
        resultado: resultados
      }),
      {
        pending: {
          render() {
            setLoading(true)
            return 'Calculando resultado, aguarde!'
          }
        },
        success: {
          render({ data }) {
            setLoading(false)
            return data.data.message
          }
        },
        error: {
          render({ data }) {
            setLoading(false)
            alerta(data.response.data);
          }
        }
      }
    );
  }

  const inversa = (numero, index) => {
    if (numero) {
      if (numero.length == 4) {
        
        return `${extracao.premios/2+(index+1)}º Inverso ${numero.slice(2)}${numero.slice(-4,2)}`

      }
    }
  }

  useEffect(() => {
    if (dados.extracao) {

      setExtracao(dados.extracao);
      setResultados(dados.extracao.resultado ? dados.extracao.resultado : []);

    } else {
      history.back();
    }
  }, []);

  return (
    <>
      <div className="titulo-principal">
        {extracao && extracao.nome} -&nbsp;
        {dayjs(extracao.data_limite).format('DD/MM/YYYY HH:mm')}
      </div>

      <div className="conteudo-principal">
        {
          extracao.tipo_resultado === 'inversa' ? (
            Array.from({ length: extracao.premios/2 }).map((_, index) =>
              <div key={index} className="form-group d-flex tres-colunas">
                <div style={{ width: '120px' }}>
                  {index + 1}º premio
                </div>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) => mudarResultado(index, event.target.value)}
                  value={resultados[index] || ''}
                />
                <div>{inversa(resultados[index], index)}</div>
              </div>
            )
          ) : (
            Array.from({ length: extracao.premios }).map((_, index) =>
              <div key={index} className="form-group d-flex tres-colunas">
                <div style={{ width: '120px' }}>
                  {index + 1}º premio
                </div>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) => mudarResultado(index, event.target.value)}
                  value={resultados[index] || ''}
                />
                <div>{resultados[index]}</div>
              </div>
            )
          )
        }
        <div className="d-flex duas-colunas m-t">
          <button
            className="btn btn-secondary"
            onClick={() => history.back()}
            disabled={loading}
          >
            Voltar
          </button>
          <button
            className="btn btn-primary"
            onClick={() => enviarResultado()}
            disabled={loading}
          >
            {!loading ? 'Salvar' : 'Aguarde...'}
          </button>
        </div>
      </div>
    </>
  )
}