import { NavLink } from 'react-router-dom';

export default function index({ tHead, tBody, tButton1, carregaPost, props }) {
	return (
		<div className="borderTable m-t">
			<table className="table">
				<thead>
					<tr>
						{tHead.map((item) => (
							<th key={item.toString()}>{item}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tBody.map((linha) => {
						return (
							<tr key={linha.id}>
								
								<td>{linha.nome}</td>
								<td>
									{linha.hora.split(':')[0] + ':' + linha.hora.split(':')[1]}
								</td>
								<td>
									1a{linha.quantidade_sorteios}
								</td>
								<td className={linha.segunda ? 'true' : 'false'}>
									{linha.segunda ? 'Sim' : 'Não'}
								</td>
								<td className={linha.terca ? 'true' : 'false'}>
									{linha.terca ? 'Sim' : 'Não'}
								</td>
								<td className={linha.quarta ? 'true' : 'false'}>
									{linha.quarta ? 'Sim' : 'Não'}
								</td>
								<td className={linha.quinta ? 'true' : 'false'}>
									{linha.quinta ? 'Sim' : 'Não'}
								</td>
								<td className={linha.sexta ? 'true' : 'false'}>
									{linha.sexta ? 'Sim' : 'Não'}
								</td>
								<td className={linha.sabado ? 'true' : 'false'}>
									{linha.sabado ? 'Sim' : 'Não'}
								</td>
								<td className={linha.domingo ? 'true' : 'false'}>
									{linha.domingo ? 'Sim' : 'Não'}
								</td>
								<td>
									<NavLink
										className={'btn btn-light'}
										exact
										to={{
											pathname: '/extracao/' + linha.id,
											extracao: linha
										}}
									>
										Editar
									</NavLink>
								</td>
								<td
									className={linha.ativo ? 'true' : 'false'}
									onClick={() => tButton1(linha)}
								>
									<button
										className={
											linha.ativo ? 'btn btn-success' : 'btn btn-danger'
										}
										disabled={carregaPost && carregaPost}
									>
										{linha.ativo ? 'Ativa' : 'Bloqueada'}
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
