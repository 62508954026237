import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import http from "services/http";
import moeda from "utils/formatMoney";
import { alerta } from "../../../components/Alert";

export default function Lancamento() {
  const selectType = useRef(null);

  const [valor, setValor] = useState(0);
  const [operador, setOperador] = useState(null);
  const [operadores, setOperadores] = useState([]);
  const [descricao, setDescricao] = useState("Retirou");
  const [tipo, setTipo] = useState("debito");
  const [lancamentos, setLancamento] = useState([]);
  const [data, setData] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    buscarOperadores();
    buscarLancamentos();
  }, []);

  function pegarDescricao() {
    const select = selectType.current;
    const option = select.children[select.selectedIndex];
    const texto = option.dataset.tipo;

    setDescricao(texto);
  }

  async function salvarLancamento(e) {
    try {
      e.preventDefault();
      
      const res = await http.post("/lancamentos", {
        data,
        valor,
        operador,
        tipo,
        descricao,
      });

      buscarLancamentos();
      
      alerta(res.data)
    } catch (e) {
      alerta(e.response.data)
    }

  }

  async function buscarLancamentos() {
    try {
      const response = await http.get("lancamentos");

      const lancamentos = response.data.data.map((l) => {
        l.data = dayjs(l.created_at).format("DD/MM/YY HH:mm");
        l.valor = moeda(l.valor);

        return l;
      });

      setLancamento(lancamentos);
    } catch (e) {
      alerta(e.response.data)
    }
  }

  async function buscarOperadores() {
    try {
      const response = await http.get("/usuarios", {
        params: {
          nivel: "operador",
          resumida: true,
        },
      });

      const usuarios = response.data.data;
      setOperadores(usuarios);
    } catch (e) {
      alerta(e.response.data)
    }
  }

  async function removerLancamento(id) {
    try {
      const response = await http.delete(`lancamentos/${id}`);

      buscarLancamentos();

      alerta(response.data)
    } catch (e) {
      alerta(e.response.data)
    }
  }

  return (
    <>
      <div className="titulo-principal">Lançamentos</div>
      <div className="conteudo-principal d-flex duas-colunas">
        <form
          style={{ width: "33%", marginRight: "20px" }}
          onSubmit={salvarLancamento}
        >
          <div className="form-group">
            <select
              className="form-control"
              onChange={(e) => {
                setOperador(e.target.value);
              }}
            >
              <option value="">Escolha um operador</option>
              {operadores.map((op) => (
                (op.tipo_usuario !== 'cliente') ?(
                  <option key={op.id} value={op.id}>
                    {op.login}
                   </option>
                ): (<></>)
              ))}
            </select>
          </div>

          <div className="form-group">
            <input
              required
              type="date"
              onChange={(e) => setData(e.target.value)}
              className="form-control"
              value={data}
            />
          </div>

          <div className="form-group">
            <select
              className="form-control"
              ref={selectType}
              onChange={(e) => {
                setTipo(e.target.value);
                pegarDescricao();
              }}
            >
              <option value="debito" data-tipo={"Retirou"}>
                Retirar (-)
              </option>
              <option value="credito" data-tipo={"Adiantou"}>
                Adiantar (+)
              </option>
            </select>
          </div>

          <div className="form-group">
            <input
              type="number"
              value={valor}
              min="1"
              step="any"
              className="form-control"
              maxLength="6"
              onChange={(e) => setValor(e.target.value)}
            />
          </div>

          <button
            style={{ width: "100%" }}
            type="submit"
            className="btn btn-success"
          >
            Salvar
          </button>
        </form>
        <div className="bordered" style={{ width: "77%", height:'max-content' }}>
          <table className="table ">
            <thead>
              <tr>
                <th>operador</th>
                <th>valor</th>
                <th>tipo</th>
                <th>data</th>
                <th>excluir</th>
              </tr>
            </thead>
            <tbody>
              {lancamentos.map((l) => (
                <tr key={l.id}>
                  <td>{l.nome}</td>
                  <td>
                    {l.tipo === "credito" ? (
                      <span className="text-success font-weight-bold">
                        {l.valor}
                      </span>
                    ) : (
                      <span className="text-danger font-weight-bold">
                        - {l.valor}
                      </span>
                    )}
                  </td>
                  <td>{l.descricao}</td>
                  <td>{l.data}</td>
                  <td>
                    <button
                      onClick={() => removerLancamento(l.id)}
                      className="btn btn-danger"
                    >
                      excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
