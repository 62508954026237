import {Component} from "react";

import axios from "services/http";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Head from "../../utils/Head";

import styles from "./styles.module.css";
import "./digitacao.scss";

const MySwal = withReactContent(Swal);

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codigo: "",
            login: "",
            password: "",
            loading: false,
        };
    }

    handleSubmit = async () => {
        try {
            this.setState({
                loading: true,
            });

            const {codigo, login, password} = this.state;

            const request = await axios.post("/login", {
                codigo,
                login,
                password,
            });

            const {token, usuario} = request.data.data;

            if (usuario.nivel !== "operador") {
                await localStorage.setItem("token", JSON.stringify(token));
                await localStorage.setItem("nivel", usuario.nivel);
                await localStorage.setItem("usuario", JSON.stringify(usuario));

                if (usuario.nivel === "socio") {
                    window.location = "/descarga";
                } else {
                    window.location = "/";
                }
            } else {
                MySwal.fire({
                    type: "error",
                    title: "O login de operador só tem acesso permitido pelo aplicativo",
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
            });

            MySwal.fire({
                type: "error",
                title: "Verifique suas credenciais",
            });
        }
    };

    render() {
        return (
            <>
                <Head/>
                <div className={`limiter ${styles.loginContainer}`}>
                    <div className="container-login100">
                        <div className="wrap-login100 p-t-5 p-b-20 p-3 shadow">
                            {/* <span className="login100-form-title">{"{Bem Vindo}"}</span> */}

                            <div class="hello">
                                <h3 className="login100-form-title">{"{Bem Vindo}"}</h3>
                            </div>

                            <div
                                className="validate-input m-t-25 loginForma form-group"
                                data-validate="Codigo"
                            >
                                <input
                                    className={`input100 ${styles.inputLogin}`}
                                    type="text"
                                    onChange={(event) =>
                                        this.setState({
                                            codigo: event.target.value,
                                        })
                                    }
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            this.handleSubmit();
                                        }
                                    }}
                                    placeholder="Codigo"
                                />
                            </div>

                            <div
                                className="validate-input m-t-25 loginForma form-group"
                                data-validate="Enter username"
                            >
                                <input
                                    className={`input100 ${styles.inputLogin}`}
                                    type="text"
                                    onChange={(event) =>
                                        this.setState({
                                            login: event.target.value,
                                        })
                                    }
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            this.handleSubmit();
                                        }
                                    }}
                                    placeholder="Login"
                                />
                            </div>

                            <div
                                className="validate-input m-t-25 loginForma form-group m-b"
                                data-validate="Enter password"
                            >
                                <input
                                    className={`input100 ${styles.inputLogin}`}
                                    type="password"
                                    onChange={(event) =>
                                        this.setState({
                                            password: event.target.value,
                                        })
                                    }
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            this.handleSubmit();
                                        }
                                    }}
                                    placeholder="Senha"
                                />
                            </div>

                            <div
                                className="container-login100-form-btn m-t-35 form-group"
                                onClick={() => this.handleSubmit()}
                            >
                                <button className="login100-form-btn">Entrar</button>
                            </div>
                            <a
                                className="container-login100-form-btn p-t-35 linkButton"
                                href="https://setebit-space.nyc3.cdn.digitaloceanspaces.com/app/app-jb.apk"
                            >
                                Baixar App
                            </a>
                        </div>
                    </div>
                    {/* <div style={{fontSize: 8}}><span>{'Environment: ' + process.env.NODE_ENV}</span></div> */}
                </div>
            </>
        );
    }
}

export default Login;
