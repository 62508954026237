import dayjs from 'dayjs';

import calcularGrupo from 'utils/calcularGrupo';
import formatMoney from 'utils/formatMoney';
import Logo from 'utils/Logo';

import './styles.css';

const BilheteDoisQuinhentos = ({aposta, nivel}) => {
  return (
    <>
      <div className='bilheteContainer'>
        <div className='logoDiv'>
          <Logo/>
        </div>

        <h3 style={{fontWeight: 'bold', fontFamily: 'arial'}}>
          Bilhete: {aposta.codigo}
        </h3>
        <span style={{fontFamily: 'arial', color: '#000'}}>
          Emitido: {aposta.data_aposta}
        </span>

        <br/>
        <span>
          Ponto: {aposta.operador}
        </span>
        <br/>

        <span>
          Situação:&nbsp;
          <span className={`badge ${aposta.situacao}`}>
            {aposta.situacao.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
              return a.toUpperCase();
            })}
            
          </span>

          {aposta.situacao === 'pago' || aposta.situacao === 'vencedor' ? (
            <span style={{marginLeft: '10px'}}>{formatMoney(aposta.premio, 2)}</span>
          ) : ''}
        </span>
        <hr/>
        <h4>
          2 PARA 500
        </h4>
        <hr/>

        {aposta.palpites.map((palpite, index) => {
          return (
            <div key={index}>
                  <span>
                    Palpite {index + 1}
                  </span>
              <br/>
                  <b> {palpite.numeros.join(' ')} </b>
                  <br/>
              <span>
                {formatMoney(palpite.valor, 2, false)}
              </span>
              <br/>

              {aposta.situacao === 'vencedor' || aposta.situacao === 'pago' ? (
                <span>
                    Situação:&nbsp;
                  <span className={`badge ${palpite.situacao}`}>
                      {palpite.situacao
                        .toLowerCase()
                        .replace(/(?:^|\s)\S/g, function (a) {
                          return a.toUpperCase();
                        })}
                  </span>

                  <span style={{marginLeft: '10px'}}>{formatMoney(palpite.premio ?? 0, 2)}</span>
                </span>
              ) : ''}
              <hr/>
            </div>
          );
        })}

        <h4 style={{fontWeight: 'bold'}}>
          TOTAL: {formatMoney(aposta.valor)} <br/>
          PRÊMIO: {formatMoney(aposta.primeiro_premio)}
        </h4>

        {aposta.sorteio_premio ? (
          <>
            <hr/>
            <span> Concorre a <strong>{aposta.sorteio_premio.premio.premio}</strong></span>
            <br/>
            <span>{aposta.sorteio_premio.info}</span>
            <br/>
            <span> Sorteio dia {aposta.sorteio_premio.data}</span>
            <br/>
            <div className="icon_bilhete">
              <i className={`fa ${aposta.sorteio_premio.premio.icon}`} aria-hidden="true"></i>
            </div>
            <hr/>
          </>
        ) : (
          <></>
        )}
        <br/>
        <span>{aposta.texto_bilhete}</span>
      </div>

      {aposta.pago_em ? (
        <>
          <br/>
          <div className={`box ${aposta.situacao}`}>
            <span style={{fontWeight: 'bold'}}>
              Pago em:&nbsp;
              {dayjs(aposta.pago_em).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
        </>
      ) : (
        <>
          <br/>
          {aposta.cancelado_em && (
            <div className={`box ${aposta.situacao}`}>
              <span style={{fontWeight: 'bold'}}>
                Cancelado:&nbsp;
                {dayjs(aposta.cancelado_em).format('DD/MM/YYYY HH:mm:ss')}
                <br/>
                Nível: {aposta.usuario_cancelou.nivel}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BilheteDoisQuinhentos;
