import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import http from "services/http";
import InputUsuario from "components/Usuarios/InputUsuario";
import { alerta } from "../../../../components/Alert";
import { toast } from "react-toastify";

const MySwal = withReactContent(Swal);

export default function CriarUsuario({ history, match }) {
    const [nivelLogado] = useState(localStorage.getItem("nivel"));
    const [nivelSelecionado] = useState(match.params.nivel);
    const [login, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [gerente, setGerente] = useState(0);
    const [listaGerente, setListaGerente] = useState([]);
    const [tipoUsuario, setTipoUsuario] = useState("gerente");
    const [comissaoGerente, setComissaoGerente] = useState(0);
    const [comissaoLucro, setComissaoLucro] = useState(0);
    const [porcentagemSocio, setPorcentagemSocio] = useState(0);
    const [comissaoDezena, setComissaoDezena] = useState(30);
    const [saldo, setSaldo] = useState(1000);
    const [comissao, setComissao] = useState(30);
    const [comissaoGrupo, setComissaoGrupo] = useState(10);
    const [cotaEscolhida, setCotaEscolhida] = useState(1);
    const [endereco, setEndereco] = useState("");
    const [milharBrindeUsuario, setMilharBrindeUsuario] = useState(1);
    const [telefone, setTelefone] = useState("");
    const [descricao, setDescricao] = useState("");
    const [modalidades, setModalidades] = useState([]);
    const [permissaoCriarOperador, setPermissaoCriarOperador] = useState(false);
    const [permissaoExibirLogo, setPermissaoExibirLogo] = useState(false);
    const [permissaoAdicionarSaldo, setPermissaoAdicionarSaldo] =
        useState(false);
    const [permissoesMarcadas, setPermissoesMarcadas] = useState([]);
    const [listaPermissoes, setListaPermissoes] = useState([]);
    const [show, setShow] = useState(null);
    const [carregaPost, setCarregaPost] = useState(false);

    useEffect(() => {
        const requestGerentes = async () => {
            try {
                const resModalidades = await http.get("bancas/titulo-cotacoes");

                setModalidades(resModalidades.data.data);

                if (nivelLogado === "gerente") {
                    const usuario = await JSON.parse(
                        localStorage.getItem("usuario")
                    );
                    setGerente(usuario.id);
                    return;
                }

                const response = await http.get("/usuarios", {
                    params: {
                        nivel: "gerente",
                    },
                });

                const { data: usuarios } = response.data;

                usuarios.unshift({ login: "Escolha um gerente", id: 0 });

                setListaGerente(usuarios);
            } catch (e) {
                console.log(e);
            }
        };

        requestPermissions();

        if (nivelSelecionado === "operador") {
            requestGerentes();
        }
    }, [nivelSelecionado]);

    const isChecked = (event, perm) => {
        if (event.target.checked) {
            setPermissoesMarcadas([
                ...permissoesMarcadas,
                perm
            ])
        } else {
            const newPermissions = permissoesMarcadas.filter((nome) => nome !== perm);
            setPermissoesMarcadas(newPermissions);
        }
    }


    const ListPermission = ({ grupo, permissoes, index }) => {
        return (
            <div className="card">
                <div id="headingOne">
                    <h5 className="mb-0">
                        <button className="btn btn-link" onClick={() => setShow(index != show ? index : null)}>
                            {grupo}
                        </button>
                    </h5>
                </div>
                <div id="collapseOne" className={`collapse ${show == index ? 'show' : ''}`}>
                    <div className="card-body">
                        {permissoes.map((perm) =>
                            <div className="funkyradio-info">
                                <input id={perm} className="mr-1"
                                    type="checkbox"
                                    checked={permissoesMarcadas.includes(perm)}
                                    onChange={(e) => {
                                        isChecked(e, perm);
                                    }}
                                />
                                <label for={perm}>{perm}</label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const requestPermissions = async () => {
        try {
            const response = await http.get("/permissoes/agrupado");

            const { data: permissoes } = response.data;

            console.log(Object.entries(permissoes));

            setListaPermissoes(permissoes);
        } catch (e) {
            console.log(e);
        }
    };

    const enviarUsuario = async (e) => {
        if (nivelSelecionado === "operador") {
            if (!gerente || gerente < 1) {
                MySwal.fire({
                    type: "error",
                    title: "Escolha um gerente para continuar",
                });

                return;
            }
        }

        if ((senha.length < 6 || senha.length > 18) && senha.length !== 0) {
            toast.error('A senha deve ter entre 6 e 18 caracteres');
            return;
        }

        try {
            const { data } = await http.post("/usuarios", {
                login: login,
                password: senha,
                nivel: nivelSelecionado,
                gerente_id: gerente || null,
                comissao: comissao || 0,
                comissao_grupo: comissaoGrupo || 0,
                saldo: saldo,
                tipo_usuario: tipoUsuario,
                comissao_gerente: comissaoGerente || 0,
                comissao_lucro: comissaoLucro || 0,
                porcentagem_socio: porcentagemSocio || 0,
                comissao_dezena: comissaoDezena || 0,
                cotas: cotaEscolhida,
                milhar_brinde_usuario: milharBrindeUsuario,
                endereco: endereco,
                telefone,
                observacao: descricao,
                lista_permissoes: permissoesMarcadas,
                permissao_criar_operador: permissaoCriarOperador,
                permissao_adicionar_saldo: permissaoAdicionarSaldo,
            });

            alerta(data);
            history.goBack();
        } catch (e) {
            setCarregaPost(false);
            alerta(e.response.data);
        }
    };

    return (
        <div className="bordered">
            <div>
                <div className="titulo-principal">
                    {nivelSelecionado === "operador" && "Criar cambista"}
                    {nivelSelecionado === "gerente" && "Criar gerente"}
                    {nivelSelecionado === "subadmin" && "Criar subadmin"}
                </div>
                <div className="btn-group caixa-inputs p-b">
                    <div className="input-criacao-usuario margin-vertical-30">
                        <label className="titulo-input-criacao">
                            Criado em:
                        </label>
                        <input
                            disabled
                            className="form-control input-dados-criar"
                            value=""
                        />
                    </div>

                    {nivelSelecionado === "operador" &&
                        nivelLogado === "admin" ? (
                        <div className="input-criacao-usuario margin-vertical-30">
                            <label className="titulo-input-criacao">
                                Gerente:
                            </label>
                            <select
                                required
                                className="form-control input-dados-criar"
                                id="inputGerente"
                                onChange={(e) => setGerente(e.target.value)}
                            >
                                {listaGerente.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.login}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    ) : null}

                    <InputUsuario
                        valor={login}
                        func={(e) => setLogin(e)}
                        name={"Login"}
                        tipo={"text"}
                    />
                    <InputUsuario
                        valor={senha}
                        func={(e) => setSenha(e)}
                        name={"Senha"}
                        tipo={"password"}
                    />

                    {nivelSelecionado === "operador" ? (
                        <>
                            <div className="input-criacao-usuario margin-vertical-30">
                                <label
                                    className="titulo-input-criacao"
                                    style={{ marginBottom: 10 }}
                                >
                                    Tipo:
                                </label>
                                <select
                                    required
                                    className="form-control input-dados-criar"
                                    id="inputTipoUsuario"
                                    onChange={(e) =>
                                        setTipoUsuario(e.target.value)
                                    }
                                >
                                    <option selected value={"cambista"}>
                                        Cambista
                                    </option>
                                    <option value={"cliente"}>Cliente</option>
                                </select>
                            </div>

                            <InputUsuario
                                name={"Saldo (R$)"}
                                valor={saldo}
                                func={(e) => setSaldo(e)}
                                tipo={"number"}
                            />
                            
                            <div className="input-criacao-usuario margin-vertical-30">
                                <label
                                    className="titulo-input-criacao"
                                    style={{ marginBottom: 10 }}
                                >
                                    Cotações:
                                </label>
                                <select
                                    required
                                    className="form-control input-dados-criar"
                                    id="inputGerente"
                                    onChange={(e) =>
                                        setCotaEscolhida(e.target.value)
                                    }
                                >
                                    {modalidades.map((m) => (
                                        <option key={m.id} value={m.id}>
                                            {m.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <InputUsuario
                                name={"% Geral"}
                                valor={comissao}
                                func={(e) => setComissao(e)}
                                tipo={"number"}
                            />

                            <InputUsuario
                                name={"% Dezena"}
                                valor={comissaoDezena}
                                func={(e) => setComissaoDezena(e)}
                                tipo={"number"}
                            />

                            <InputUsuario
                                name={"% Grupo"}
                                valor={comissaoGrupo}
                                func={(e) => setComissaoGrupo(e)}
                                tipo={"number"}
                            />

                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label className='titulo-input-criacao'>Milhar brinde:</label>
                                <select
                                    required
                                    className='form-control input-dados-criar'
                                    onChange={(e) => setMilharBrindeUsuario(e.target.value)}
                                    value={milharBrindeUsuario}
                                >
                                    <option value={1}>
                                        Sim
                                    </option>
                                    <option value={0}>
                                        Não
                                    </option>
                                </select>
                            </div>
                        </>
                    ) : null}

                    {nivelSelecionado === "gerente" ? (
                        <>
                            <div className="input-criacao-usuario margin-vertical-30">
                                <label
                                    className="titulo-input-criacao"
                                    style={{ marginBottom: 10 }}
                                >
                                    Tipo:
                                </label>
                                <select
                                    required
                                    className="form-control input-dados-criar"
                                    id="inputTipoUsuario"
                                    onChange={(e) =>
                                        setTipoUsuario(e.target.value)
                                    }
                                >
                                    <option value={"gerente"}>Gerente</option>
                                    <option value={"socio"}>Sócio</option>
                                </select>
                            </div>

                            {tipoUsuario === "gerente" ? (
                                <>
                                    <InputUsuario
                                        name={"Comissão Bruto"}
                                        valor={comissaoGerente}
                                        func={(e) => setComissaoGerente(e)}
                                        tipo={"number"}
                                    />
                                    <InputUsuario
                                        name={"Comissão Lucro"}
                                        valor={comissaoLucro}
                                        func={(e) => setComissaoLucro(e)}
                                        tipo={"number"}
                                    />
                                </>
                            ) : tipoUsuario === "socio" ? (
                                <InputUsuario
                                    name={"% Sócio"}
                                    valor={porcentagemSocio}
                                    func={(e) => setPorcentagemSocio(e)}
                                    tipo={"number"}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    ) : null}

                    <InputUsuario
                        name={"Endereço"}
                        valor={endereco}
                        func={(e) => setEndereco(e)}
                        tipo={"text"}
                    />
                    <InputUsuario
                        name={"Telefone"}
                        valor={telefone}
                        func={(e) => setTelefone(e)}
                        tipo={"text"}
                    />

                    <div className="input-criacao-usuario margin-vertical-30">
                        <label className="titulo-input-criacao">
                            Descrição:
                        </label>
                        <textarea
                            className="form-control input-dados-criar"
                            maxLength="100"
                            id="exampleFormControlTextarea1"
                            onChange={(c) => setDescricao(c.target.value)}
                            rows="3"
                            value={descricao || " "}
                        />
                    </div>

                    {nivelSelecionado === "subadmin" && (
                        <div className="input-criacao-usuario margin-vertical-30">
                            <label className="titulo-input-criacao">
                                Permissões:
                            </label>
                            <div class="form-group">
                                <div class="col-sm-offset-3">
                                    <div id="accordion">
                                        {Object.keys(listaPermissoes).map((el, i) => (
                                            <ListPermission
                                                grupo={el}
                                                index={i}
                                                permissoes={listaPermissoes[el]}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {nivelSelecionado === "gerente" && (
                        <div className="input-criacao-usuario margin-vertical-30">
                            <label className="titulo-input-criacao">
                                Permissões:
                            </label>
                            <div className="funkyradio-info">
                                <input
                                    type="checkbox"
                                    id="criar-operador"
                                    onChange={() =>
                                        setPermissaoCriarOperador(
                                            !permissaoCriarOperador
                                        )
                                    }
                                    checked={permissaoCriarOperador}
                                />
                                &nbsp;
                                <label htmlFor="criar-operador">
                                    Criar Cambista
                                </label>
                                <br />
                                <input
                                    type="checkbox"
                                    id="adicionar-saldo"
                                    onChange={() =>
                                        setPermissaoAdicionarSaldo(
                                            !permissaoAdicionarSaldo
                                        )
                                    }
                                    checked={permissaoAdicionarSaldo}
                                />
                                &nbsp;
                                <label htmlFor="adicionar-saldo">
                                    Adicionar saldo
                                </label>
                            </div>
                        </div>
                    )}

                    {nivelSelecionado === "operador" && (
                        <div className="input-criacao-usuario margin-vertical-30">
                            <label className="titulo-input-criacao">
                                Permissões:
                            </label>
                            <div className="funkyradio-info">
                                <input
                                    type="checkbox"
                                    id="exibir-logo"
                                    onChange={() =>
                                        setPermissaoCriarOperador(
                                            !permissaoCriarOperador
                                        )
                                    }
                                    checked={permissaoCriarOperador}
                                />
                                &nbsp;
                                <label htmlFor="exibir-logo">
                                    Exibir logo
                                </label>
                            </div>
                        </div>
                    )}
                </div>

                {nivelSelecionado === "operador" && (
                    <div style={{ padding: 10 }}>
                        <p style={{ color: "#cc0000" }}>
                            % Geral: para milhar e centena e suas subcategorias.
                        </p>
                        <p style={{ color: "#cc0000" }}>
                            % Dezena: para dezena e suas subcategorias.
                        </p>
                        <p style={{ color: "#cc0000" }}>
                            % Grupo: para grupo e suas subcategorias.
                        </p>
                        <p style={{ color: "#cc0000" }}>
                            Na opção cliente a comissão ganha em cada bilhete,
                            volta em forma de saldo.
                        </p>
                    </div>
                )}

                <div
                    style={{
                        padding: "0 10px",
                        justifyContent: "left",
                    }}
                    className="d-flex m-b m-t"
                >
                    <button
                        style={{
                            display: "block",
                            width: "270px",
                            marginLeft: "150px",
                        }}
                        className="btn btn-success"
                        onClick={(e) => enviarUsuario(e)}
                        disabled={carregaPost}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    );
}