import React, {useEffect, useState} from "react";
import styles from './styles.module.css';
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import http from "../../../../services/http";
import formatMoney from "../../../../utils/formatMoney";
import BilheteBolaoDezena from "../../../../components/BilheteBolaoDezena";

export function VerBolaoDezena() {
    const [exibirModal, setExibirModal] = useState(false);
    const [apostaModal, setApostaModal] = useState({});
    const [loading, setLoading] = useState(false);
    const [bolao, setBolao] = useState({});
    const [quantidadeApostas, setQuantidadeApostas] = useState(0);
    const [estatisticaAcertos, setEstatisticaAcertos] = useState([]);

    const {id} = useParams();

    async function pegarBolao() {
        setLoading(true)
        const {data: response} = await (await http.get('/boloes-dezena/' + id)).data

        setBolao(response.bolao);
        setQuantidadeApostas(response['quantidade_apostas']);
        setEstatisticaAcertos(response['estatistica_acertos']);

        setLoading(false)
    }

    async function pegarBilhete(codigo) {
        const {data: bilhete} = await (await http.get('/boloes-dezena/palpites/' + codigo + '/resultado')).data
        setApostaModal(bilhete[0])
    }

    function calcularSituacaoNumero(numero) {

        const resultados = [];

        bolao?.resultados?.map((resultado) => {
            if (resultado.resultados) {
                resultados.push(...resultado.resultados)
            }
        })

        if (resultados.includes(numero)) {
            return 'bg-success';
        } else {
            return 'text-dark';
        }
    }

    function modalBilhete() {
        return (exibirModal && <div
            className="modal"
            style={{display: 'block', overflowY: 'scroll', maxHeight: '100%'}}>

            <div className="modal-dialog">
                <div className="modal-content modal-bilhete">

                    <div className="modal-body">
                        <div className="center">

                            <BilheteBolaoDezena aposta={apostaModal}/>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            style={{width: '100%'}}
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => setExibirModal(false)}
                        >
                            Fechar
                        </button>
                    </div>

                </div>
            </div>

        </div>);
    }

    useEffect(() => {
        pegarBolao();
    }, [])

    return (
        <>
            {loading && <div className="loader"/>}

            <div className={`titulo-principal`}>Bolão de Dezena</div>

            {!loading && (<div className={`${styles.containerHeader} border border-dark`}>

                <div className={`${styles.apostas}`}>
                    <p>Apostas</p>
                    <strong>{quantidadeApostas}</strong>
                </div>

                <div className={`${styles.premio}`}>
                    <p>Prêmio</p>
                    <strong>{bolao?.premio && formatMoney(bolao.premio)}</strong>
                </div>

                <div className={`${styles.pontos}`}>
                    <p>Informações por pontos</p>
                    <div>
                        {estatisticaAcertos.length > 0 && estatisticaAcertos.map((quantidadePalpites, pontos) => (
                            <div className={`${styles.pontos_item}`}>
                                <p>{++pontos}</p>
                                <strong>{quantidadePalpites}</strong>
                            </div>
                        ))}
                    </div>
                </div>
            </div>)}

            {/*Resultados*/}
            {!loading && (<div className={`${styles.containerResultado} border border-dark mt-2`}>
                <p>Resultados</p>

                {bolao?.resultados?.map((resultado) => (
                    <div>
                        <div className={styles.extracao}>
                            <p>{dayjs(resultado.data_resultado).format('DD/MM/YY')}</p>
                            <p>{resultado.nome}</p>
                        </div>

                        <div className={styles.resultados}>
                            {resultado.resultados?.length > 0 ? resultado.resultados.map((resultado) => (
                                <p>{resultado}</p>
                            )) : (
                                <>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                    <p style={{color: '#fff', userSelect: 'none'}}>00</p>
                                </>
                            )

                            }
                        </div>

                    </div>
                ))}
            </div>)}

            {/*Bilhetes*/}
            {!loading && bolao?.palpites?.length > 0 && (
                <div className={`${styles.containerBilhete} border border-dark mt-2`}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Posição</th>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Números</th>
                            <th>Acertos</th>
                            <th>Bilhete</th>
                        </tr>
                        </thead>

                        <tbody>
                        {bolao?.palpites?.map((palpite, index) => (
                            <tr>
                                <td>{++index}</td>
                                <td>{palpite.bilhete?.codigo}</td>
                                <td>{palpite.bilhete?.cambista?.login}</td>
                                <td>
                                    <ul>
                                        {palpite.numeros?.map((numero) => (
                                            <li className={`${calcularSituacaoNumero(numero)}`}>{numero}</li>
                                        ))}
                                    </ul>
                                </td>
                                <td>{palpite.acertos}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={async () => {
                                            await pegarBilhete(palpite.bilhete?.codigo)
                                            setExibirModal(true)
                                        }}
                                    >
                                        Bilhete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                    </table>
                </div>)}

            {modalBilhete()}
        </>
    );
}
