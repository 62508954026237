import dayjs from 'dayjs';

const fontBold = {
    fontWeight: 'bold',
    borderTop: '1px solid #ccc'
}

const fontRed = {
    color: 'red'
}

const fontGreen = {
    color: 'green'
}

const backTR = {
    backgroundColor: '#cccccc',
}

const capitalizeStr = (str) => {
    return (str.charAt(0).toUpperCase() + str.slice(1));
}

const mostrarAlteracoes = (tipo, evento, alteracoes) => {
    let alteracoesText = '';

    console.log(tipo, alteracoes);

    if (tipo == 'Banca' && alteracoes.antigos.opcoes && alteracoes.novos.opcoes) {
        for (let coluna in alteracoes.antigos.opcoes) {
            if (alteracoes.antigos.opcoes[coluna] != alteracoes.novos.opcoes[coluna])
                alteracoesText += `${formatHtml(coluna)}: ${formatHtml(alteracoes.antigos.opcoes[coluna])} → ${formatHtml(alteracoes.novos.opcoes[coluna])}, `;
        }
    } else if (tipo == 'Banca' && alteracoes.antigos.modalidades && alteracoes.novos.modalidades) {
        for (let coluna in alteracoes.antigos.modalidades) {
            if (alteracoes.antigos.modalidades[coluna].descarga != alteracoes.novos.modalidades[coluna].descarga)
            alteracoesText += `${alteracoes.novos.modalidades[coluna].nome.replace(/duque\s*-\s*grupo\s*-\s*combinado/gi, "Grupo Combinado")}: ${formatHtml(alteracoes.antigos.modalidades[coluna].descarga)} → ${formatHtml(alteracoes.novos.modalidades[coluna].descarga)}, `;
        }
    } else if (!(alteracoes.antigos == null) && Object.keys(alteracoes.antigos).length > 0) {
        for (let coluna in alteracoes.antigos) {
            if (alteracoes.antigos[coluna] != alteracoes.novos[coluna])
                alteracoesText += `${formatHtml(coluna)}: ${formatHtml(alteracoes.antigos[coluna])} → ${formatHtml(alteracoes.novos[coluna])}, `;
        }
    } else {
        for (let coluna in alteracoes.novos) {
            alteracoesText += `${formatHtml(coluna)}: ${formatHtml(alteracoes.novos[coluna])}, `;
        }
    }

    return alteracoesText;
};

const formatHtml = (html) => {
    return capitalizeStr(String(html)
        .replaceAll('null', 'Vazio')
        .replaceAll('_', ' ')
        .replaceAll('undefined', 'Indefinido')
        .replaceAll('true', 'Ativo')
        .replaceAll('false', 'Desativado')
        .replaceAll('[object Object]', '')
        .replaceAll(/<[^>]*>?/gm, ''));
}

const index = ({relatorio}) => {
    return (
        <>
            <div className="m-t" style={{border: '1px solid #ccc'}}>
                <table className="table">
                    <>
                        <tr style={backTR}>
                            <td style={fontBold}>ID</td>
                            <td style={fontBold}>Usuário</td>
                            <td style={fontBold}>Tipo</td>
                            <td style={fontBold}>Data</td>
                            <td style={fontBold}>Descrição</td>
                        </tr>
                        <tbody>
                        {relatorio.map((coluna) => {
                            return (
                                <tr>
                                    <td>{coluna.id}</td>
                                    <td>{coluna.autor_nome}</td>
                                    <td>{coluna.descricao}</td>
                                    <td>{dayjs(coluna.data).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{coluna.auditado_alias} — {mostrarAlteracoes(coluna.tipo, coluna.evento, coluna.alteracoes)} {coluna.ip}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </>
                </table>
            </div>
        </>
    );
}


export default index;