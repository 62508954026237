import React from 'react'

import axios from 'axios'
import url from 'utils/url'
import { alerta } from 'components/Alert/index'

const token = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : null

const http = axios.create({
    baseURL: url,
    headers: {Authorization: `Bearer ${token}`},
})

http.interceptors.response.use((response) => {
    return response
}, (error) => {

    if (error.response.status == 429) {
        alerta({ success: false, message: 'Aguarde, muitas solicitações foram realizadas!' })
    }

    if (error.response.status === 401) {
        localStorage.clear();
        window.location = '/login';
    } else {
        return Promise.reject(error)
    }
    
})

export default http
