import {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import http from 'services/http';
import formatarMoeda from 'utils/formatMoney';
import styles from './styles.module.css';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function CaixaResumo() {
    const [banca, setBanca] = useState('');
    const [geradoEm, setGeradoEm] = useState('');
    const [resumo, setResumo] = useState(null);
    const [relatorio, setRelatorio] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [loading, setLoading] = useState(true);

    async function buscarResumo() {
        try {
            const response = await http.get('/caixa/analitico');
            const {banca, gerado_em, relatorio, resumo} = response.data.data;

            const lancamentos = response.data.data.lancamentos.map((lancamento) => {
                lancamento.data = dayjs(lancamento.created_at).format(
                    'DD/MM/YYYY HH:mm'
                );
                lancamento.valor = formatarMoeda(lancamento.valor);
                return lancamento;
            });

            setRelatorio(relatorio);
            setResumo(resumo);
            setLancamentos(lancamentos);
            setBanca(banca);
            setGeradoEm(gerado_em);
            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        buscarResumo();
    }, []);

    async function downloadPdf() {
        const doc = new jsPDF('l', 'pt', 'a4');
        doc.setFontSize(24);
        doc.text(`Fechamento de caixa: ${banca} - ${geradoEm}`, 40, 30);

        doc.autoTable({
            html: `.tablePDF`,
            useCss: true
        });

        doc.autoTable({
            html: `.table_geral`,
            useCss: true
        });

        doc.autoTable({
            html: `.table_title`,
            useCss: true
        });

        doc.autoTable({
            html: `.table_box`,
            useCss: true
        });

        doc.setFontSize(5);
        doc.save(`Resumo_Fechamento_${geradoEm}.pdf`);
    }

    return loading ? (
        <div className="loader"/>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h2>
                    <b>Fechamento de caixa: {banca}</b>
                </h2>
            </div>
            <br/>
            <h4>
                <b>{geradoEm}</b>
            </h4>

            <br/>
            <br/>

            <section className={styles.containerTablesGerente}>
                <div className={styles.borderColor}>
                    <table className="table tablePDF">
                        {relatorio?.map((gerente) => (
                            <>
                                <tr
                                    key={`gerente-${gerente.nome}`}
                                    style={{backgroundColor: '#7F7F7F', color: '#fff'}}
                                    className={styles.containerTablesGerenteHeadPrimary}
                                >
                                    <th colSpan="9">
                                        <span>{gerente.nome.toUpperCase()}</span>
                                    </th>
                                </tr>
                                <tr
                                    className={styles.containerTablesGerenteHead}
                                    style={{backgroundColor: '#ccc'}}
                                >
                                    <th>Operador</th>
                                    <th>Apostas</th>
                                    <th>Entradas</th>
                                    <th>Em aberto</th>
                                    <th>Saída</th>
                                    <th>Comissões</th>
                                    <th>Lançamentos</th>
                                    <th>Saldo Cambista</th>
                                    <th>Saldo Banca</th>
                                </tr>
                                <tbody className={styles.containerTablesGerenteBody}>
                                {gerente?.cambistas?.map((cambista) => {
                                    return (
                                        <tr key={`operador-${cambista.cambista_id}`}>
                                            <td className={styles.fontBold}>{cambista.cambista.toUpperCase()}</td>
                                            <td className={styles.fontBold}>{cambista.apostas}</td>
                                            <td>{formatarMoeda(cambista.entrada)}</td>
                                            <td>{formatarMoeda(cambista.entrada_aberta)}</td>
                                            <td className="text-black">
                                                {formatarMoeda(cambista.saida)}
                                            </td>
                                            <td className="text-black">
                                                {formatarMoeda(cambista.comissao_cambista)}
                                            </td>
                                            <td>
												<span
                                                    className={`${
                                                        cambista.lancamento < 0
                                                            ? 'text-danger'
                                                            : cambista.lancamento > 0
                                                                ? 'text-success'
                                                                : 'text-black'
                                                    }`}
                                                >
													{formatarMoeda(cambista.lancamento)}
												</span>
                                            </td>
                                            <td className={styles.fontBold}>
												<span
                                                    className={`${
                                                        cambista.total_cambista < 0
                                                            ? 'text-danger'
                                                            : cambista.total_cambista > 0
                                                                ? 'text-success'
                                                                : 'text-black'
                                                    }`}
                                                >
													{formatarMoeda(cambista.total_cambista)}
												</span>
                                            </td>
                                            <td className={styles.fontBold}>
												<span
                                                    className={`${
                                                        cambista.total_banca < 0
                                                            ? 'text-danger'
                                                            : cambista.total_banca > 0
                                                                ? 'text-success'
                                                                : 'text-black'
                                                    }`}
                                                >
													{formatarMoeda(cambista.total_banca)}
												</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                                <tr className={styles.summaryTableGerente}>
                                    <th>TOTAL</th>
                                    <th>{gerente.apostas}</th>
                                    <th>{formatarMoeda(gerente.entrada)}</th>
                                    <th>{formatarMoeda(gerente.entrada_aberta)}</th>
                                    <th className="text-black">{formatarMoeda(gerente.saida)}</th>

                                    <th className="text-black">
                                        {formatarMoeda(gerente.comissao_cambista)}
                                    </th>
                                    <th
                                        className={`${
                                            gerente.lancamento < 0 ? 'text-danger' : (gerente.lancamento > 0) ? 'text-success' : 'text-black'
                                        }`}
                                    >
                                        {formatarMoeda(gerente.lancamento)}
                                    </th>
                                    <th
                                        className={`${
                                            gerente.total_cambista < 0 ? 'text-danger' : (gerente.total_cambista > 0) ? 'text-success' : 'text-black'
                                        }`}
                                    >
                                        {formatarMoeda(gerente.total_cambista)}
                                    </th>
                                    <th
                                        className={`${
                                            gerente.total_banca < 0 ? 'text-danger' : (gerente.total_banca > 0) ? 'text-success' : 'text-black'
                                        }`}
                                    >
                                        {formatarMoeda(
                                            gerente.total_banca
                                        )}
                                    </th>
                                </tr>
                                <tr className={styles.footerTableGerente}>
                                    <th colspan='5'>
                                        {gerente.nome.toUpperCase()}:{' '}
                                        <span
                                            className={
                                                gerente.comissao_gerente < 0
                                                    ? 'text-danger'
                                                    : gerente.comissao_gerente > 0
                                                        ? 'text-success'
                                                        : 'text-black'
                                            }
                                        >
													{formatarMoeda(gerente.comissao_gerente)}
												</span>
                                    </th>
                                    <th colspan='4'>
                                        BANCA:{' '}
                                        <span
                                            className={
                                                (gerente.total_banca - gerente.comissao_gerente) < 0
                                                    ? 'text-danger'
                                                    : gerente.total_banca > 0
                                                        ? 'text-success'
                                                        : ''
                                            }
                                        >
													{formatarMoeda(gerente.total_banca - gerente.comissao_gerente)}
												</span>
                                    </th>
                                </tr>
                                <tr>
                                    <td className={styles.borderBetWeenTable} colspan="9"></td>
                                </tr>
                            </>
                        ))}
                    </table>
                </div>

                {/* RESUMO GERAL */}
                <div className="borderTable m-t">
                    <table className={'table table-hover table_geral' + ' ' + styles.summaryBox}>
                        <tr
                            style={{
                                color: '#fff',
                                backgroundColor: '#008800',
                            }}
                        >
                            <th colSpan="9">TOTAL GERAL DA BANCA</th>
                        </tr>

                        <tr style={{color: '#fff', backgroundColor: '#008800'}}>
                            <th/>
                            <th>Apostas</th>
                            <th>Entradas</th>
                            <th>Em aberto</th>
                            <th>Saída</th>
                            <th>Comissões</th>
                            <th>Lançamentos</th>
                            <th>Saldo Cambista</th>
                            <th>Saldo Banca</th>
                        </tr>

                        {resumo && (
                            <tbody>
                            <tr>
                                <th>TOTAL</th>
                                <th>{resumo.apostas}</th>
                                <th>{formatarMoeda(resumo.entrada)}</th>
                                <th>{formatarMoeda(resumo.entrada_aberta)}</th>
                                <th className="text-black">{formatarMoeda(resumo.saidas)}</th>
                                <th className="text-black">
                                    {formatarMoeda(resumo.comissoes)}
                                </th>
                                <th>{formatarMoeda(resumo.lancamento)}</th>
                                <th>
										<span
                                            className={`${
                                                resumo.total_cambista < 0
                                                    ? 'text-danger'
                                                    : resumo.total_cambista > 0
                                                        ? 'text-success'
                                                        : 'text-black'
                                            }`}
                                        >
											{formatarMoeda(resumo.total_cambista)}
										</span>
                                </th>
                                <th>
										<span
                                            className={`${
                                                resumo.total_banca < 0
                                                    ? 'text-danger'
                                                    : resumo.total_banca > 0
                                                        ? 'text-success'
                                                        : 'text-black'
                                            }`}
                                        >
											{formatarMoeda(resumo.total_banca)}
										</span>
                                </th>
                            </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </section>
            <br/>
            <br/>
            <table className={'table_title'}>
                <tr>
                    <td style={{fontSize: '32px'}}>Lançamentos de Caixa:</td>
                </tr>
            </table>
            <div className={`borderTable m-t ${styles.containerTablesLancamento}`}>
                <table
                    className="table table-hover table_box"
                    style={{backgroundColor: '#ccc', fontWeight: 'bold'}}
                >
                    <tr className={styles.containerTablesLancamentoHead}>
                        <th>Operador</th>
                        <th>Valor</th>
                        <th>Tipo</th>
                        <th>Data</th>
                    </tr>

                    <tbody>
                    {lancamentos.map((lancamento) => (
                        <tr key={lancamento.id}>
                            <td>{lancamento.nome.toUpperCase()}</td>
                            <td>
                                {lancamento.tipo === 'credito' ? (
                                    <span className="text-success font-weight-bold">
											{lancamento.valor}
										</span>
                                ) : (
                                    <span className="text-danger font-weight-bold">
											- {lancamento.valor}
										</span>
                                )}
                            </td>
                            <td>{lancamento.descricao}</td>
                            <td>{lancamento.data}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <button className={`btn btn-primary ${styles.salvarPDF}`} onClick={downloadPdf}>
                Salvar PDF
            </button>
        </>
    );
}
