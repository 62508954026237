import dayjs from 'dayjs';

import calcularGrupo from 'utils/calcularGrupo';
import formatMoney from 'utils/formatMoney';
import Logo from 'utils/Logo';

import './styles.css';

const Bilhete = ({aposta, nivel, tipo}) => {
  return (
    <>
      <div className='bilheteContainer'>
        <div className='logoDiv'>
          <Logo/>
        </div>

        <h3 style={{fontWeight: 'bold', fontFamily: 'arial'}}>
          Bilhete: {aposta.codigo}
        </h3>
        <span style={{fontFamily: 'arial', color: '#000'}}>
          Emitido: {dayjs(aposta.data_aposta).format('DD/MM/YYYY HH:mm:ss')}
        </span>

        <br/>
        <span>
          Ponto: {nivel === 'admin' ? aposta.operador : aposta.operador.login}
        </span>
        <br/>

        <span>
          Situação:&nbsp;
          <span className={`badge ${aposta.situacao}`}>
            {aposta.situacao.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
              return a.toUpperCase();
            })}
            
          </span>

          {aposta.situacao === 'pago' || aposta.situacao === 'vencedor' ? (
            <span style={{marginLeft: '10px'}}>{formatMoney(aposta.premio, 2)}</span>
          ) : ''}
        </span>
        <hr/>
        <h4>
          {aposta.loteria.nome}
        </h4>
        <hr/>

        {aposta.palpites.map((palpite, index) => {
          if (palpite.modalidade === 'milhar-brinde') {
            return null;
          }

          return (
            <div key={index}>
                  <span style={{textTransform: 'uppercase'}}>
                    {palpite.modalidade_nome.replace(/duque\s*-\s*grupo\s*-\s*combinado/gi, "Grupo Combinado")}
                  </span>
              <br/>
              {palpite.modalidade === 'grupo' ? (
                palpite.numeros.map((numero, indice) => (
                  <div key={indice}>
                    <b> {calcularGrupo(numero, tipo)} </b>
                    <br/>
                  </div>
                ))
              ) : (
                <>
                  <b> {palpite.numeros.join(' ')} </b>
                  <br/>
                </>
              )}
              <span>
                    {palpite.sorteio_inicial}/{palpite.sorteio_final} a&nbsp;
                {formatMoney(palpite.valor_palpite, 2, false)} =&nbsp;
                {formatMoney(palpite.valor, 2, false)}
                  </span>
              <br/>

              {aposta.situacao === 'vencedor' || aposta.situacao === 'pago' ? (
                <span>
                    Situação:&nbsp;
                  <span className={`badge ${palpite.situacao}`}>
                      {palpite.situacao
                        .toLowerCase()
                        .replace(/(?:^|\s)\S/g, function (a) {
                          return a.toUpperCase();
                        })}
                  </span>

                  <span style={{marginLeft: '10px'}}>{formatMoney(palpite.premio ?? 0, 2)}</span>
                </span>
              ) : ''}
              <hr/>
            </div>
          );
        })}

        {aposta.palpites.map((palpite, index) => {
          if (palpite.modalidade !== 'milhar-brinde') {
            return null;
          }

          return (
            <div key={index}>
              <span>MILHAR BRINDE 1/1 {palpite.numeros.join(' ')}</span>
              <br/>

              {palpite.situacao === 'pago' || palpite.situacao === 'vencedor' ? (

                <span>
                    Situação:&nbsp;
                  <span className={`badge ${palpite.situacao}`}>
                      {palpite.situacao
                        .toLowerCase()
                        .replace(/(?:^|\s)\S/g, function (a) {
                          return a.toUpperCase();
                        })}
                  </span>
                  
                  <span style={{marginLeft: '10px'}}>{formatMoney(palpite.premio, 2)}</span>
                </span>
              ) : ''}
              <hr/>
            </div>
          );
        })}

        <h4 style={{fontWeight: 'bold'}}>
          TOTAL: {formatMoney(aposta.valor)}
        </h4>

        {aposta.sorteio_premio ? (
          <>
            <hr/>
            <span> Concorre a <strong>{aposta.sorteio_premio.premio.premio}</strong></span>
            <br/>
            <span>{aposta.sorteio_premio.info}</span>
            <br/>
            <span> Sorteio dia {aposta.sorteio_premio.data}</span>
            <br/>
            <div className="icon_bilhete">
              <i className={`fa ${aposta.sorteio_premio.premio.icon}`} aria-hidden="true"></i>
            </div>
            <hr/>
          </>
        ) : (
          <></>
        )}
        <br/>
        <span>{aposta.texto_bilhete}</span>
      </div>

      {aposta.pago_em ? (
        <>
          <br/>
          <div className={`boxx ${aposta.situacao}`}>
            <span style={{fontWeight: 'bold'}}>
              Pago por: {aposta?.usuario_pagou?.nivel} <br/>
              {dayjs(aposta.pago_em).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
        </>
      ) : (
        <>
          <br/>
          {aposta.cancelado_em && (
            <div className={`boxx ${aposta.situacao}`}>
              <span style={{fontWeight: 'bold'}}>
                Cancelado por: {aposta.usuario_cancelou.nivel} <br/>
                {dayjs(aposta.cancelado_em).format('DD/MM/YYYY HH:mm:ss')}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Bilhete;
