import {Component} from 'react';
import Swal from 'sweetalert2';

import http from 'services/http';

import {alerta} from "../../../../components/Alert";

export default class EditarConcursoSeninha extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nome: '',
      hora: '',
      quantidadeSorteio: '',
      milharMoto: '0',
      listaLoterias: [],
      loteria_central: '',
      semana: [
        {
          dia: 'Segunda',
          ativo: false,
        },
        {
          dia: 'Terça',
          ativo: false,
        },
        {
          dia: 'Quarta',
          ativo: false,
        },
        {
          dia: 'Quinta',
          ativo: false,
        },
        {
          dia: 'Sexta',
          ativo: false,
        },
        {
          dia: 'Sábado',
          ativo: false,
        },
        {
          dia: 'Domingo',
          ativo: false,
        },
      ],
    };
  }


  componentDidMount = async () => {
    try {
      const {id} = this.props.match.params;

      const response = await http.get(`seninha/concursos/${id}`);

      const {data: concurso} = response.data;

      this.setState(() => ({
        nome: concurso.nome,
        hora_encerramento: concurso.hora_encerramento,
        semana: [
          {
            dia: 'Segunda',
            ativo: concurso.segunda,
          },
          {
            dia: 'Terça',
            ativo: concurso.terca,
          },
          {
            dia: 'Quarta',
            ativo: concurso.quarta,
          },
          {
            dia: 'Quinta',
            ativo: concurso.quinta,
          },
          {
            dia: 'Sexta',
            ativo: concurso.sexta,
          },
          {
            dia: 'Sábado',
            ativo: concurso.sabado,
          },
          {
            dia: 'Domingo',
            ativo: concurso.domingo,
          },
        ],
      }));
    } catch (e) {
      Swal.fire({
        type: 'error',
        title: 'Concurso não encontrada',
      });

      return this.props.history.push('/seninha');
    }
  };

  salvarEdicoes = async () => {
    try {
      const {nome, hora_encerramento, semana} = this.state;
      const {id} = this.props.match.params;

      const response = await http.put(`/seninha/concursos/${id}`, {
        nome,
        hora_encerramento,
        segunda: semana[0].ativo,
        terca: semana[1].ativo,
        quarta: semana[2].ativo,
        quinta: semana[3].ativo,
        sexta: semana[4].ativo,
        sabado: semana[5].ativo,
        domingo: semana[6].ativo
      });
      
      alerta(response.data);
      
      this.props.history.push('/seninha');
    } catch (e) {
      Swal.fire({
        type: 'error',
        title: 'Não foi possível editar!',
      });
    }
  };

  changerCheckBox = (dia, index) => {
    const {semana} = this.state;
    semana[index].ativo = !dia.ativo;

    this.setState({semana});
  };

  renderCheckBox = () => {
    return this.state.semana.map((item, index) => {
      return (
        <label key={index} className='control-label'>
          <input
            type='checkbox'
            checked={item.ativo}
            onChange={() => {
              this.changerCheckBox(item, index);
            }}
          />
          &nbsp;
          {item.dia}
        </label>
      );
    });
  };

  render() {
    return (
      <>
        <div className='titulo-principal'>Editar concurso</div>
        <div className='conteudo-principal'>
          <div className='d-flex duas-colunas'>
            <div className='form-group'>
              <label className='control-label'>Nome do concurso</label>
              <input
                onChange={(c) => this.setState({nome: c.target.value})}
                type='text'
                disabled
                className='form-control'
                value={this.state.nome}
              />
            </div>
            <div className='form-group'>
              <label className='control-label'>Encerra</label>
              <input
                onChange={(c) =>
                  this.setState({hora_encerramento: c.target.value + ':00'})
                }
                type='time'
                className='form-control'
                value={this.state.hora_encerramento?.slice(0, 5)}
              />
            </div>
          </div>
          <div className='d-flex'>{this.renderCheckBox()}</div>
          <div className='d-flex duas-colunas m-t'>
            <button
              className='btn btn-danger'
              onClick={() => this.props.history.push('/seninha')}
            >
              Cancelar
            </button>
            <button
              className='btn btn-success'
              onClick={() => this.salvarEdicoes()}
            >
              Salvar
            </button>
          </div>
        </div>
      </>
    );
  }
}
