import { useState, useEffect } from 'react';

import Cabecalho from './Cabecalho';
import Principal from './Principal';
import BarraLateral from './BarraLateral';
import { Redirect } from 'react-router-dom';
import {ToastContainer} from "react-toastify";

function Layout({ component, ...rest }) {
	const [menuAberto, setMenuAberto] = useState(false);
	const [perfilAberto, setPerfilAberto] = useState(false);
	const [usuario, setUsuario] = useState([]);
	const [token, setToken] = useState([]);

	useEffect(() => {
		const getUser = async () => {
			const usuario = await localStorage.getItem('usuario');
			setUsuario(JSON.parse(usuario));
			const getToken = await localStorage.getItem('token');
			setToken(JSON.parse(getToken));
		};
		getUser();
	}, []);

	return (
		<div className="page-container">
			{!token === null && <Redirect to={{ pathname: '/login' }} />}
      <BarraLateral user={usuario} />
			<div className="left-content">
				<Cabecalho
					setperfilAberto={() => setPerfilAberto()}
					perfilAberto={perfilAberto}
					user={usuario}
					collapsed={() => {
						setMenuAberto(!menuAberto);
					}}
				/>

				<Principal {...rest} component={component} />
			</div>
    </div>
	);
}

export default Layout;
