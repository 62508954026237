const fontBold = {
    fontWeight: 'bold',
    borderTop: '1px solid #ccc'

}

const backTR = {
    backgroundColor: '#cccccc',
}

const index = ({formatMoney, relatorio, resumo}) => {
    return (
        <>
            <div className="m-t" style={{border: '1px solid #ccc'}}>
                <table className="table ">
                    {relatorio?.map((gerente) => (
                        <>
                            <tr style={{backgroundColor: '#7F7F7F', color: '#fff'}}>
                                <th
                                    className="titulo"
                                    colSpan="8">
                                    {gerente.nome}
                                </th>
                            </tr>
                            <tr style={backTR}>
                                <td style={fontBold}>Cambista</td>
                                <td style={fontBold}>Qtd.</td>
                                <td style={fontBold}>Entrada</td>
                                <td style={fontBold}>Saída</td>
                                <td style={fontBold}>Comissão</td>
                                <td style={fontBold}>Lançamentos</td>
                                <td style={fontBold}>Saldo Cambista</td>
                                <td style={fontBold}>Saldo Banca</td>
                            </tr>
                            <tbody>
                            {gerente?.cambistas?.map((operador) => {
                                return (
                                    <tr key={operador.cambista}>
                                        <td>{operador.cambista}</td>
                                        <td>{operador.apostas}</td>
                                        <td>{formatMoney(operador.entrada)}</td>
                                        <td>{formatMoney(operador.saida)}</td>
                                        <td>{formatMoney(operador.comissao_cambista)}</td>
                                        <td>{formatMoney(operador.lancamento)}</td>
                                        <td>
                                            <b
                                                className={`${
                                                    operador.total_cambista > 0 ? 'text-success' : ''
                                                } ${operador.total_cambista < 0 ? 'text-danger' : ''}`}
                                            >
                                                {formatMoney(operador.total_cambista)}
                                            </b>
                                        </td>
                                        <td>
                                            <b
                                                className={`${
                                                    operador.total_banca > 0 ? 'text-success' : ''
                                                } ${operador.total_banca < 0 ? 'text-danger' : ''}`}
                                            >
                                                {formatMoney(operador.total_banca)}
                                            </b>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                            <tr style={fontBold}>
                                <td>Total:</td>
                                <td>{gerente.apostas}</td>
                                <td>{formatMoney(gerente.entrada)}</td>
                                <td>{formatMoney(gerente.saida)}</td>
                                <td>{formatMoney(gerente.comissao_cambista)}</td>
                                <td>{formatMoney(gerente.lancamento)}</td>
                                <td>
                                    {formatMoney(gerente.total_cambista)}
                                </td>
                                <td>
                                    {formatMoney(gerente.total_banca)}
                                </td>
                            </tr>
                            <tr>
                                <th colSpan="4" style={backTR}>
										<span style={{textTransform: 'uppercase'}}>
											{gerente.nome}
										</span>
                                    :&nbsp;
                                    <span
                                        className={`${
                                            gerente.comissao_gerente > 0 ? 'text-success' : ''
                                        } ${gerente.comissao_gerente < 0 ? 'text-danger' : ''}`}
                                    >
											{formatMoney(gerente.comissao_gerente)}
										</span>
                                </th>
                                <th colSpan="4" style={backTR}>
                                    BANCA:&nbsp;
                                    <span
                                        className={`${gerente.total_banca > 0 ? 'text-success' : ''} ${
                                            gerente.total_banca < 0 ? 'text-danger' : ''
                                        }`}
                                    >
											{formatMoney(gerente.total_banca - gerente.comissao_gerente)}
										</span>
                                </th>
                            </tr>
                            <tr>
                                <td colspan="6" style={{height: '15px'}}></td>
                            </tr>
                        </>
                    ))}
                </table>
            </div>

            {resumo && (
                <>
                    <div className="borderTable">
                        <table className="table table_banca" style={{marginTop: '20px'}}>
                            <tr style={{backgroundColor: '#7F7F7F', color: '#fff'}}>
                                <th className="titulo" colspan='6'>Total Banca</th>
                            </tr>
                            <tr style={backTR}>
                                <td style={fontBold}>Qtd.</td>
                                <td style={fontBold}>Entrada</td>
                                <td style={fontBold}>Saída</td>
                                <td style={fontBold}>Comissões</td>
                                <td style={fontBold}>Saldo Cambista</td>
                                <td style={fontBold}>Saldo Banca</td>
                            </tr>

                            <tbody>
                            <tr>
                                <td>{resumo.apostas}</td>
                                <td>{formatMoney(resumo.entrada)}</td>
                                <td>{formatMoney(resumo.saida)}</td>
                                <td>{formatMoney(resumo.comissoes)}</td>
                                <td>{formatMoney(resumo.total_cambista)}</td>
                                <td>
                                    <b
                                        className={`${resumo.total_banca > 0 ? 'text-success' : ''} ${
                                            resumo.total_banca < 0 ? 'text-danger' : ''
                                        }`}
                                    >
                                        {formatMoney(resumo.total_banca)}
                                    </b>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

export default index;
