import {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import http from 'services/http';

import styles from './styles.module.css';
import {alerta} from "../../../components/Alert";

const MySwal = withReactContent(Swal);

export default function Saldo() {
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [nivel, setNivel] = useState(localStorage.getItem('nivel'));
    const [nomeFilter, setNomeFilter] = useState('');
    const [gerente, setGerente] = useState('0');
    const [gerentes, setGerentes] = useState([]);
    const [nivelLogado] = useState(localStorage.getItem('nivel'));
    const [permissoesGerente, setPermissoesGerente] = useState({criar_operador: true, adicionar_saldo: true});

    useEffect(() => {
        const permissoesGerente = JSON.parse(localStorage.getItem('usuario')).permissoes_gerente;

        if (permissoesGerente) {
            setPermissoesGerente(JSON.parse(localStorage.getItem('usuario')).permissoes_gerente)
        }

        const requestUsuarios = async () => {
            try {
                const response = await http.get('/usuarios', {
                    params: {
                        nivel: 'operador'
                    }
                });

                const {data: usuarios} = response.data;

                setListaUsuarios(usuarios);

                if (nivel === 'gerente') return

                if (nivelLogado === 'gerente') return;

                const responseGerentes = await http.get('/usuarios', {
                    params: {
                        nivel: 'gerente'
                    }
                });

                setGerentes(responseGerentes.data.data);
            } catch (e) {
                alerta(e.response.data)
            }
        };

        requestUsuarios();
    }, []);

    const funcaoQueFiltra = () => {
        const filtro = nomeFilter.toString().toLowerCase();
        const gerenteId = gerente.toString();

        if (gerenteId < 1) {
            if (!filtro) {
                return listaUsuarios;
            }

            return listaUsuarios.filter(
                (usuario) => usuario.login.toLowerCase().indexOf(filtro) >= 0
            );
        }

        return listaUsuarios.filter((u) => {
            return (
                u.login.toLowerCase().indexOf(filtro) >= 0 &&
                u.gerente_id.toString() === gerenteId
            );
        });
    };

    const mudarSaldo = (index, value) => {
        const lista = listaUsuarios.map((item) => {
            if (item.id === index) {
                item.saldo = value;
            }

            return item;
        });

        setListaUsuarios(lista);
    };

    const salvarSaldo = async (index, value) => {
        try {
            const {data} = await http.patch('/usuarios/' + index + '/saldo', {
                saldo: value
            });

            alerta(data);

            mudarSaldo(index, value);
        } catch (e) {
            alerta(e.response.data)
        }

    };

    const alteraSaldo = (index, value) => {
        const lista = listaUsuarios.map((item) => {
            if (item.id === index) {
                const newSaldo = item.saldo + value;
                item.saldo = (newSaldo <= 0) ? 0 : newSaldo;
            }
            return item;
        });

        setListaUsuarios(lista);
    };

    return (
        <div>
            <div className="titulo-principal">
                <span>Saldo</span>
            </div>
            <div className="conteudo-principal d-flex duas-colunas">
                <select
                    onChange={(e) => setGerente(e.target.value)}
                    className="form-control"
                >
                    <option value="0">Todos</option>
                    {gerentes.map((g) => (
                        <option key={g.id} value={g.id}>
                            {g.login}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    onChange={(e) => setNomeFilter(e.target.value)}
                    placeholder="Filtrar por nome"
                />
            </div>
            <div className="table-responsive m-t">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Saldo</th>
                        <th>Salvar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {funcaoQueFiltra().map((operador) => {
                        return (
                            <tr key={operador.id}>
                                <td className={operador.saldo < 1 ? 'text-danger' : ''}>
                                    <b>{operador.login}</b>
                                </td>
                                <td style={{width: '200px'}}>
                                    <div className={styles.saldoContainer}>
                                        <div className={styles.iconLeft}>
                                            <button onClick={() => {
                                                alteraSaldo(operador.id, -100)
                                            }}>
                                                <i className={'fa fa-angle-down'}/>
                                            </button>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                type="number"
                                                placeholder="Adicionar ao saldo"
                                                onChange={(e) =>
                                                    mudarSaldo(operador.id, e.target.value)
                                                }
                                                className="form-control"
                                                value={operador.saldo}
                                            />
                                        </div>
                                        <div className={styles.iconRight}>
                                            <button onClick={() => {
                                                alteraSaldo(operador.id, 100)
                                            }}>
                                                <i className={'fa fa-angle-up'}/>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-success"
                                        onClick={() => salvarSaldo(operador.id, operador.saldo)}
                                        disabled={!permissoesGerente.adicionar_saldo}
                                    >
                                        Salvar
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
