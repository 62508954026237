import {useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';

import ListaResponsiva from 'components/Usuarios/Lista';
import http from 'services/http';
import {useGetUserPermission} from "../../../../hook/permissions";
import PermissionGate from "../../../../utils/PermissionGate";
import axios from "axios";
import {alerta} from "../../../../components/Alert";

export default function Usuarios() {

    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [nivel, setNivel] = useState(localStorage.getItem('nivel'));
    const [gerentes, setGerentes] = useState([]);
    const [nomeFilter, setNomeFilter] = useState('');
    const [gerente, setGerente] = useState(0);
    const [loadingAtivo, setLoadingAtivo] = useState(false);
    const [qtdVendedores, setQtdVendedores] = useState(0);
    const [permissoesGerente, setPermissoesGerente] = useState({criar_operador: true, adicionar_saldo: true});

    const requestUsuarios = async () => {
        try {
            setLoadingAtivo(true);

            const response = await http.get('/usuarios', {
                params: {
                    nivel: 'operador'
                }
            });

            const resModalidades = await http.get('bancas/titulo-cotacoes');

            const {data: modalidades} = resModalidades.data;

            const usuarios = response.data.data.map((u) => {
                u.cotas = modalidades[u.cotas - 1].nome;

                return u;
            });

            setListaUsuarios(usuarios);
            setQtdVendedores(usuarios.length);

            if (nivel === 'admin') {

                const responseGerentes = await http.get('/usuarios', {
                    params: {
                        nivel: 'gerente'
                    }
                });

                setGerentes(Object.values(responseGerentes.data.data));

            }

            setLoadingAtivo(false);
        } catch (e) {
            alerta(e.response.data)
        }
    };

    useEffect(() => {
        const permissoesGerente = JSON.parse(localStorage.getItem('usuario')).permissoes_gerente;

        if (permissoesGerente) {
            setPermissoesGerente(JSON.parse(localStorage.getItem('usuario')).permissoes_gerente)
        }
        requestUsuarios();
    }, []);

    const rangerButton2 = async (id, ativo) => {
        try {
            await http.patch('usuarios/' + id + '/status', {
                status: !ativo
            });

            const novaLista = listaUsuarios.map((item) => {
                if (item.id === id) {
                    item.ativo = !item.ativo;
                }

                return item;
            });

            setListaUsuarios(novaLista);
        } catch (e) {
        }
    };

    const perguntarParaApagar = async (id, ativo) => {
        Swal.fire({
            title: 'Deseja apagar o cambista?',
            text: 'Todas as apostas serão apagadas junto ao cambista!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Sim, apagar!',
            cancelButtonText: 'Não, cancelar'
        }).then((result) => {
            if (result.value) {
                handleDelete(id, ativo);
            }
        });
    };

    const handleDelete = async (id) => {
        try {
            const res = await http.delete('/usuarios/' + id);
            alerta(res.data);

            requestUsuarios();
        } catch (e) {
            alerta(e.response.data);
        }
    };

    const usuariosFiltrados = useMemo(() => {
        const filtro = nomeFilter.toString().toLowerCase();
        const gerenteId = gerente.toString();

        let usuarios = listaUsuarios;

        if (gerenteId > 0) {
            usuarios = usuarios.filter((u) => u.gerente_id.toString() === gerenteId);
        }

        if (filtro) {
            usuarios = usuarios.filter((u) => u.login.toLowerCase().includes(filtro));
        }

        setQtdVendedores(usuarios.length);

        return usuarios;
    }, [nomeFilter, gerente, listaUsuarios]);

    return (
        <>
            <div className="titulo-principal">Cambistas</div>
            <div className="conteudo-principal d-flex tres-colunas">
                <PermissionGate permissions={["Listar gerente"]}>
                    <select
                        onChange={(e) => setGerente(e.target.value)}
                        className="form-control"
                    >
                        <option value="0">Todos</option>
                        {gerentes.map((g) => (
                            <option key={g.id} value={g.id}>
                                {g.login}
                            </option>
                        ))}
                    </select>
                </PermissionGate>
                <input
                    onChange={(e) => setNomeFilter(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Filtrar por nome"
                />
                {permissoesGerente?.criar_operador && (
                    <Link
                        style={{height: '38px'}}
                        className="btn btn-info"
                        to="/usuarios/criar/operador">
                        Novo Cambista
                    </Link>
                )}
            </div>

            <div className="subtitulo">{`${qtdVendedores} vendedores encontrados`}</div>

            {loadingAtivo ? (
                <div className="loader"/>
            ) : (
                <ListaResponsiva
                    tBody={usuariosFiltrados}
                    showButton1={true}
                    showButton2={true}
                    tButton3={perguntarParaApagar}
                    tButton2={rangerButton2}
                    nivel="operador"
                    permissionApagar={'Remover cambista'}
                />
            )}
        </>
    );
}
