import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import http from 'services/http';
import ReactHtmlParser from 'react-html-parser';
import { useLoading } from 'hook/loading';

export default function Regulamento() {
	const [loading, startLoading, stopLoading] = useLoading();

	const [rules, setRules] = useState();

	useEffect(() => {
		requestRegulamento();
	}, []);

	async function requestRegulamento() {
		startLoading();
		const response = await http.get(`/bancas/minha-banca`);

		const regulamento = response.data.data;

		setRules(ReactHtmlParser(regulamento.regulamento));
		stopLoading();
	}

	return (
		<>
			<div className="titulo-principal">Regulamento</div>
			<div className="bordered">
				{loading ? (
					<>
						<div className="loader" />
					</>
				) : (
					<>
						<div style={{ margin: '10px', textAlign: 'center' }}>
							{rules !== null ? (
								<>{rules}</>
							) : (
								<>{'Sua banca não possui regulamento ainda'}</>
							)}
						</div>
						<div
							style={{
								padding: '0 10px',
								display: 'flex',
								justifyContent: 'flex-end'
							}}
							className="m-b m-t"
						>
							{rules !== null ? (
								<Link
									className="btn btn-info"
									to="/regulamento/criar/regulamento"
								>
									Editar
								</Link>
							) : (
								<Link
									className="btn btn-info"
									to="/regulamento/criar/regulamento"
								>
									Criar
								</Link>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}
