import {useEffect, useState} from 'react';

export function useGetUserPermission() {
  const [permissions, setPermission] = useState([]);

  useEffect(() => {

    const getUser = async () => {
      const usuario = await (localStorage.getItem('usuario') && JSON.parse(localStorage.getItem('usuario')));

      setPermission(usuario && usuario.permissoes);
    }
    
    getUser();
  
  }, [])

  return permissions;
}
