import React, {useEffect, useState} from "react";
import styles from "../../Cotacoes/styles.module.css";
import http from "../../../../services/http";
import {alerta} from "../../../../components/Alert";

export function ModalidadeSeninha() {
    const [modalidadesSeninha, setModalidadesSeninha] = useState([]);
    const [modalidadeSeninha, setModalidadeSeninha] = useState([]);
    const [modalidade, setModalidade] = useState('Seninha');
    const [isLoading, setIsLoading] = useState(true);

    async function salvarModalidadeSeninha(modalidade) {

        const response = await http.put(`/seninha/modalidades/${modalidade.id}`, {
            'valor_maximo': modalidade.valor_maximo,
            'premio': modalidade.premio
        })

        alerta(response.data)

    }

    async function salvarStatusModalidade(modalidade, index, coluna) {

        const modalidadesSeninhaArray = modalidadeSeninha;
        modalidadesSeninhaArray.modalidades[index][coluna] = !modalidadesSeninhaArray.modalidades[index][coluna];

        setModalidadeSeninha({...modalidadesSeninhaArray});

        const response = await http.patch(`/seninha/modalidades/${modalidade.id}`)

        alerta(response.data)
    }

    function editarValoresSeninha(index, coluna, valor) {
        const modalidadesSeninhaArray = modalidadeSeninha;
        modalidadesSeninhaArray.modalidades[index][coluna] = valor;

        setModalidadeSeninha({...modalidadesSeninhaArray});
    }

    async function buscarModalidades() {
        try {
            const responseModalidadesSeninha = await http.get('/seninha/concursos/modalidades');
            const {data: modalidadesSeninha} = responseModalidadesSeninha.data;
            setModalidadesSeninha(modalidadesSeninha);

            const modalidadeSelecionada = modalidadesSeninha.filter((item) => {
                return item.nome === modalidade
            })
            setModalidadeSeninha(modalidadeSelecionada['0'])

            setIsLoading(false);
        } catch (e) {
            alerta(e.response.data)
        }
    }

    useEffect(() => {
        buscarModalidades()
    }, [])

    useEffect(() => {
        const modalidadeSelecionada = modalidadesSeninha.filter((item) => {
            return item.nome === modalidade
        })
        setModalidadeSeninha(modalidadeSelecionada['0'])
    }, [modalidade])

    return (
        <>
            <div className="conteudo-principal d-flex duas-colunas mb-2">
                <select
                    required
                    className="form-control text-capitalize"
                    onChange={(e) => setModalidade(e.target.value)}
                    value={modalidade}
                >
                    <option value="Seninha">Seninha</option>
                    <option value="Quininha">Quininha</option>
                    <option value="Lotinha">Lotinha</option>
                </select>
            </div>

            {modalidadeSeninha?.modalidades?.length > 0 && (
                <div className='bordered'>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Quantidade de números</th>
                            <th>Valor máximo por aposta</th>
                            <th>Pagamento</th>
                            <th>Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        {modalidadeSeninha.modalidades.map((modalidade, index) => {
                            return (
                                <tr key={modalidade.id}>
                                    <td>{modalidade.quantidade_numeros}</td>
                                    <td>
                                        <input type="number" value={modalidade?.valor_maximo}
                                               onChange={(e) => editarValoresSeninha(index, 'valor_maximo', e.target.value)}/>
                                    </td>
                                    <td>
                                        <input type="number" value={modalidade?.premio}
                                               onChange={(e) => editarValoresSeninha(index, 'premio', e.target.value)}/>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-success mr-2"
                                            onClick={() => salvarModalidadeSeninha(modalidade)}
                                        >
                                            Salvar
                                        </button>
                                        <button
                                            className={`btn ${modalidade.status ? 'btn-success' : 'btn-danger'}`}
                                            onClick={() => salvarStatusModalidade(modalidade, index, 'status')}
                                        >
                                            {modalidade.status ? 'Ativa' : 'Inativa'}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>)}
        </>
    )
}
