import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import http from '../../../../services/http';
import fut from '../../../../services/futebol';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ResultadoPuleBolao from '../ResultadoPule/index';
import {toast} from "react-toastify";
import {alerta} from "../../../../components/Alert";

const MySwal = withReactContent(Swal)

export default function BolaoVer(props) {
	const [loadingAtivo, setLoadingAtivo] = useState(true);
	const [bolao, setBolao] = useState({})

	const { id: idBolao } = props.match.params;

	useEffect(() => {

		const buscarBolao = async () => {
			try {
				const { data: {data: bolao} } = await http.get('/boloes/'+idBolao);

				setBolao({
					...bolao,
				});

				setLoadingAtivo(false);
			} catch (e) {

        const { data } = e.response;
        alerta(data);

      }
		}
		buscarBolao()

	}, []);

	useEffect(() => {

	}, [bolao]);

	const definePlacar = (ev) => {
		const { name, value, id } = ev.target;

		bolao.times.forEach((el, ind) => {
			if (id == el.id) {
				el.placar_casa = name === 'casa' ? parseInt( value ) : parseInt( el.placar_casa	)
				el.placar_fora = name === 'fora' ? parseInt( value ): parseInt( el.placar_fora)
			}
		});
		setBolao({...bolao})
	}

	const salvarPlacar = async () => {

      await toast.promise(
        http.post('/boloes/'+idBolao+'/adiciona_placar', {
          resultado: bolao.times
        }),
        {
          pending: 'Definindo resultado, aguarde!',
          success: {
            render({data}){
              setBolao({
                ...data.data.data
              });
              return data.data.message
            }
          },
          error: {
            render({data}){
              return data.response.data.message
            }
          }
        }
      );

	}

	const cancelaBolao = async () => {
		try {
			await http.patch('/boloes/'+idBolao);
			props.history.push('/bolao');
		} catch (e) {
      const { data } = e.response;
      alerta(data);
    }
	}

	const carregaPlacar = async () => {

		try {
			MySwal.fire({
				title: 'Os resultados estão sendo processados!',
				confirmButtonText: 'Confirmar',
				type: 'info'
			})

			MySwal.showLoading()

			let dataInicio = '';
			let dataFim = '';

			if (bolao.times.length === 1) {
				dataInicio = new Date(bolao.times[0].data_inicio);
				dataFim	= dataInicio;
			} else {
				dataInicio = bolao.times.reduce((ant, att) => (
					new Date(ant.data_inicio) < new Date(att.data_inicio) ?
					new Date(ant.data_inicio) : new Date(att.data_inicio)
				))

				dataFim = bolao.confrontos.reduce((ant, att) => (
					new Date(ant.data_inicio) > new Date(att.data_inicio) ?
					new Date(ant.data_inicio) : new Date(att.data_inicio)
				))
			}

			dataFim.setHours(dataFim.getHours() + 3);

			dataInicio = dayjs(dataInicio).format('YYYY-MM-DD');
			dataFim = dayjs(dataFim).format('YYYY-MM-DD');

			const { data: campeonato } = await fut.get(`/matches?dateFrom=${dataInicio}&dateTo=${dataFim}`)

			campeonato.matches.forEach((matche) => {
				bolao.times.forEach((comp) => {
					if (matche.id == comp.matche_id) {
						comp.placar_casa = matche.score.fullTime.homeTeam ? matche.score.fullTime.homeTeam : '0'
						comp.placar_fora = matche.score.fullTime.awayTeam ? matche.score.fullTime.awayTeam : '0'
					}
				});
			});

			setBolao({...bolao})
			Swal.hideLoading()

		} catch (error) {Swal.hideLoading()}

	}

	return (
		<>
			<div className="titulo-principal">Bolão</div>
			<div className="conteudo-principal">
				<div className="d-flex quatro-colunas">
					<div className="form-group">
						<label className="control-label">Nome</label>
						<input
							type="text"
							className="form-control"
							disabled
							defaultValue={ bolao && bolao.nome }
						/>
					</div>
					<div className="form-group">
						<label className="control-label">Valor</label>
						<input
							type="text"
							className="form-control"
							disabled
							defaultValue={ bolao && bolao.valor }
						/>
					</div>
					<div className="form-group">
						<label className="control-label">Tipo</label>
						<input
							type="text"
							className="form-control"
							disabled
							defaultValue={ bolao && bolao.tipo }
						/>
					</div>
					<div>
						<label className="control-label">Data Fim</label>
						<div className="form-group d-flex">
							<input
								type="text"
								disabled
								className="form-control"
								defaultValue={ bolao.data_limite && dayjs(bolao.data_limite).format('DD/MM/YYYY') }
							/>
							<input
								type="text"
								disabled
								className="form-control w-25 ml-1"
								defaultValue={ bolao.data_limite && dayjs(bolao.data_limite).format('HH:mm') }
								/>
						</div>
					</div>
				</div>
				<div className="d-flex tres-colunas">

					<div className="form-group">
            {
              bolao && bolao.tipo == 'placar' ?
                (
                  <>
                    <label className="control-label">{ bolao && bolao.times.length } acertos</label>
                    <input
                      type="number"
                      className="form-control"
                      disabled
                      defaultValue={ bolao && bolao.primeiro_premio }
                    />
                  </>
                ) : (
                  <>
                    <label className="control-label">% Premio</label>
                    <input
                      type="number"
                      className="form-control"
                      disabled
                      defaultValue={ bolao && bolao.porcentagem_premio }
                    />
                  </>
                )
            }
					</div>

          <div className="form-group">
            {
              bolao && bolao.tipo == 'placar' ?
                (
                  <>
                    <label className="control-label">{ bolao && bolao.times.length - 1 } acertos</label>
                    <input
                      type="number"
                      className="form-control"
                      disabled
                      defaultValue={ bolao && bolao.segundo_premio }
                    />
                  </>
                ) : (
                  <>
                    <label className="control-label">Premio</label>
                    <input
                      type="number"
                      className="form-control"
                      disabled
                      defaultValue={ bolao && bolao.premio_acumulado }
                    />
                  </>
                )
            }
          </div>

          <div className="form-group">
            {
              bolao && bolao.tipo == 'placar' ?
                <>
                  <label className="control-label">
                    { bolao && bolao.times.length - 2 } acertos
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    disabled
                    defaultValue={ bolao && bolao.terceiro_premio }
                  />
                </> : ''
            }
          </div>

				</div>
			</div>

			{/* TIMES */}
			<div className="titulo-principal mt-2">
				<div className="mb-0 my-auto">Times</div>
				<div className="ms-auto">
					<button
						type="button"
						className="btn btn-info"
						onClick={carregaPlacar}
					>
						<i className="fa fa-check" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div className="conteudo-principal">

				{
					bolao.times && bolao.times.map((el, ind) =>
						<div className="d-flex quatro-colunas" key={ind}>
							<div className="form-group">
								<label className="control-label">{ el.time_casa }</label>
								<input
									required
									type="text"
									className="form-control"
									name="casa"
									id={el.id}
									value={ el.placar_casa }
									onChange={definePlacar}
								/>
							</div>
							<div className="form-group">
								<label className="control-label">{ el.time_fora }</label>
								<input
									required
									type="text"
									className="form-control"
									name="fora"
									id={el.id}
									value={ el.placar_fora }
									onChange={definePlacar}
								/>
							</div>
							<div className="form-group">
								<label className="control-label"></label>
								<input
									type="date"
									className="form-control"
									defaultValue={ dayjs(el.data_inicio).format('YYYY-MM-DD') }
									disabled
								/>
							</div>
							<div className="form-group">
								<label className="control-label"></label>
								<input
									type="time"
									className="form-control"
									defaultValue={ dayjs(el.data_inicio).format('HH:mm')  }
									disabled
								/>
							</div>
						</div>
					)
				}

				{
					bolao.times && !bolao.data_cancelado && bolao.times.length > 0 ?
					<button
						className="btn btn-success"
						onClick={salvarPlacar}
					>
						Salvar Placar
					</button> : ''
				}

			</div>

			<ResultadoPuleBolao
				bolao={ bolao }
				setBolao={ setBolao }
			/>

			<div className="d-flex duas-colunas m-t">
				<button
					className="btn btn-secondary"
					onClick={() => props.history.push('/bolao')}
				>
					Voltar
				</button>

				{
					!bolao.data_cancelado &&
					<button
						className="btn btn-danger"
						onClick={cancelaBolao}
					>
						Cancelar Bolão
					</button>
				}

			</div>

			{loadingAtivo && <div className="loader" />}
		</>
	);
}
