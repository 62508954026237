import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import http from '../../services/http';

import Logo from 'utils/Logo';
import PermissionGate from "../../utils/PermissionGate";

function BarraLateral({ user }) {
	const [rotas, setRotas] = useState([]);

	useEffect(() => {
		const importarRotas = async () => {
			import(`routes/admin/links`).then((links) =>
				setRotas(links.default)
			);
		};

		importarRotas();
	}, []);

	const fazerLogout = async (e) => {
		e.preventDefault();

		await http.post('/logout');

		localStorage.setItem('token', '');
		localStorage.setItem('nivel', '');
		localStorage.setItem('usuario', '');
		localStorage.setItem('banca', '');
		window.location = '/login';
	};

	return (
		<div className="sidebar-menu">
			{user.logotipo !== null && (
				<div className="logo-sideBar">
					<Logo />
				</div>
			)}
			<div className="menu">
				<ul id="menu">
					{
						rotas.filter(rota => rota.showBar)
							.map((rota) => (
								<PermissionGate key={rota.path} permissions={rota.permissions} module={rota.module}>
									<li className="menu-home">
										<NavLink exact to={`${rota.path}`} title={rota.nome}>
											<i className={`fa ${rota.icon}`}>
												<span className="menu-title bold">{rota.nome}</span>
											</i>
										</NavLink>
									</li>
								</PermissionGate>
							))
					}
					<li className="menu-home">
						<a onClick={fazerLogout} href="/">
							<i className="fa fa-sign-out">
								<span className="menu-title font-arial bold">Sair</span>
							</i>
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default BarraLateral;
