export default function (numero, tipo) {
    if (!numero) {
        return '';
    }

    let grupo = parseInt(numero);
    let bicho = '';

    if (numero.toString().length > 2) {
        grupo = Math.ceil(numero.slice(2) / 4);
    }

    const tipoFormatado = tipo.toLowerCase()

    if (tipoFormatado === "jb") {
        switch (grupo) {
            case 0:
            case 25:
                bicho = '25 - Vaca';
                break;
            case 1:
                bicho = '01 - Avestruz';
                break;
            case 2:
                bicho = '02 - Águia';
                break;
            case 3:
                bicho = '03 - Burro';
                break;
            case 4:
                bicho = '04 - Borboleta';
                break;
            case 5:
                bicho = '05 - Cachorro';
                break;
            case 6:
                bicho = '06 - Cabra';
                break;
            case 7:
                bicho = '07 - Carneiro';
                break;
            case 8:
                bicho = '08 - Camelo';
                break;
            case 9:
                bicho = '09 - Cobra';
                break;
            case 10:
                bicho = '10 - Coelho';
                break;
            case 11:
                bicho = '11 - Cavalo';
                break;
            case 12:
                bicho = '12 - Elefante';
                break;
            case 13:
                bicho = '13 - Galo';
                break;
            case 14:
                bicho = '14 - Gato';
                break;
            case 15:
                bicho = '15 - Jacaré';
                break;
            case 16:
                bicho = '16 - Leão';
                break;
            case 17:
                bicho = '17 - Macaco';
                break;
            case 18:
                bicho = '18 - Porco';
                break;
            case 19:
                bicho = '19 - Pavão';
                break;
            case 20:
                bicho = '20 - Peru';
                break;
            case 21:
                bicho = '21 - Touro';
                break;
            case 22:
                bicho = '22 - Tigre';
                break;
            case 23:
                bicho = '23 - Urso';
                break;
            case 24:
                bicho = '24 - Veado';
                break;
            default:
                break;
        }
    }

    if (tipoFormatado === "lototime") {
        switch (grupo) {
            case 0:
            case 25:
                bicho = '25 - Vasco';
                break;
            case 1:
                bicho = '01 - ABC';
                break;
            case 2:
                bicho = '02 - Atlético MG';
                break;
            case 3:
                bicho = '03 - Athletico PR';
                break;
            case 4:
                bicho = '04 - Bahia';
                break;
            case 5:
                bicho = '05 - Botafogo';
                break;
            case 6:
                bicho = '06 - Ceará';
                break;
            case 7:
                bicho = '07 - Corinthians';
                break;
            case 8:
                bicho = '08 - Coritiba';
                break;
            case 9:
                bicho = '09 - Cruzeiro';
                break;
            case 10:
                bicho = '10 - CSA';
                break;
            case 11:
                bicho = '11 - Cuiabá';
                break;
            case 12:
                bicho = '12 - Flamengo';
                break;
            case 13:
                bicho = '13 - Fluminense';
                break;
            case 14:
                bicho = '14 - Fortaleza';
                break;
            case 15:
                bicho = '15 - Goiás';
                break;
            case 16:
                bicho = '16 - Grêmio';
                break;
            case 17:
                bicho = '17 - Internacional';
                break;
            case 18:
                bicho = '18 - Náutico';
                break;
            case 19:
                bicho = '19 - Palmeiras';
                break;
            case 20:
                bicho = '20 - Sampaio Corrêa';
                break;
            case 21:
                bicho = '21 - Santos';
                break;
            case 22:
                bicho = '22 - São Paulo';
                break;
            case 23:
                bicho = '23 - Sport';
                break;
            case 24:
                bicho = '24 - Treze';
                break;
            default:
                break;
        }
    }

    return bicho;
}
