import React, { useEffect, useState } from "react";
import http from "services/http";
import PermissionGate from "utils/PermissionGate";
import dayjs from "dayjs";
import { alerta } from "components/Alert/index";
import formatMoney from "utils/formatMoney";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

export default function Rifa() {
  const [credenciais, setCredenciais] = useState({});
  const [loading, setLoading] = useState(false);
  const [rifas, setRifas] = useState([]);

  useEffect(() => {
		buscarRifas()
  }, [])

  const buscarRifas = async () => {
    try {
      const { data: {data} } = await http.get('/rifas');
      setRifas(data);
    } catch (e) {
      alerta(e.response.data)
    }
  }
  
  const defineCredenciais = (ev) => {
    const {name, value} = ev.target;

    setCredenciais({
      ...credenciais
      , [name]: value
    })
  }

  const salvarRifa = async (e) => {
    e.preventDefault();

    await toast.promise(
      http.post('/rifas', credenciais),
      {
        pending: {
          render(){
            setLoading(true)
            return 'Gerando numeros, aguarde!'
          }
        },
        success: {
          render({data}){
            setLoading(false)
            setCredenciais({});
            buscarRifas();
            document.forms[0].reset();
            return data.data.message
          }
        },
        error: {
          render({data}){
            setLoading(false)
            alerta(data.response.data);
          }
        }
      }
    );
  }

  const CampoEncerramento = ({ el }) => {
    const [editarData, setEditarData] = useState(false);
    const [data, setData] = useState(el.data_limite);

    const salvar = async () => {

      try {
        const response = await http.patch('/rifas/'+el.id, {'data_limite': data})
        setData(data)
        setEditarData(false)
        alerta(response.data)
      } catch (error) {
        alerta(error.response.data)
      }

    }

    return (
        !editarData ?
        <>
          {dayjs(data).format('DD/MM/YYYY HH:mm')}
          <button 
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{height: '25px', width: '25px'}}
            onClick={() => setEditarData(true)}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        </> : 
        <div className="d-flex">
          <input
            required
            type="datetime-local"
            className="form-control"
            onChange={(e) => setData(e.target.value)}
            name='data_limite_nova'
          />
          <button 
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{height: '38px', width: '25px'}}
            onClick={salvar}
          >
            <i className="fa fa-check" aria-hidden="true"></i>
          </button>
          <button 
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{height: '38px', width: '25px'}}
            onClick={() => {
              setEditarData(false)
              setData(el.data_limite)
            }}
          >
            <i className="fa fa-close" aria-hidden="true"></i>
          </button> 
        </div>
    )
  }

  const cancelaRifa = async (id) => {

    Swal.fire({
      title: 'Deseja cancelar a Rifa?',
      text: 'Só será possivel recuperar gerando novos numeros, cuidado ao cancelar!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await http.delete('/rifas/'+id)
          buscarRifas()
          alerta(response.data)
        } catch (error) {
          alerta(error.response.data)
        }
      }
    });
    
  }

  return (
    <>
      <div className="titulo-principal">Rifa</div>
      <form onSubmit={salvarRifa}>
        <div className="conteudo-principal row m-0">

          <div className="col-3 px-1">
            <label>Encerramento</label>
            <input
              required
              type="datetime-local"
              className="form-control"
              onChange={defineCredenciais}
              name='data_limite'
            />
          </div>

          <div className="col-2 px-1">
            <label>Nome/Titulo</label>
            <input
              required
              type="text"
              className="form-control"
              onChange={defineCredenciais}
              name='nome'
            />
          </div>

          <div className="col-2 px-1">
            <label>Tipo</label>
            <select
              className="form-control"
              onChange={defineCredenciais}
              name="tipo"
            >
              <option defaultValue="" value="" selected></option>
              <option value="dezena">dezena</option>
              <option value="centena">centena</option>
              <option value="milhar">milhar</option>
            </select>
          </div>

          <div className="col-1 px-1">
            <label>Valor</label>
            <input
              required
              type="text"
              className="form-control"
              onChange={defineCredenciais}
              name='valor'
            />
          </div>

          <div className="col-2 px-1">
            <label>Premiação</label>
            <input
              required
              type="text"
              className="form-control"
              onChange={defineCredenciais}
              name='premio'
            />
          </div>

          <div className="col-2 px-1">
            <PermissionGate permissions={['Criar dois quinhentos']}>
              <button
                type="submit"
                className={`btn btn-info w-100 mt-4`}
                style={{height: '38px'}}
                disabled={loading}
              >
                Salvar
              </button>
            </PermissionGate>
          </div>

        </div>
      </form>

      <div className="conteudo-principal p-0 mt-2">
        <table className="table text-left border text-center">
					<thead>
						<tr>
							<th>Encerramento</th>
							<th>Nome/Titulo</th>
							<th>Situação</th>
							<th>Tipo</th>
							<th>Valor</th>
							<th>Bilhetes</th>
							<th>Venda</th>
							<th>Premio</th>
							<th>Cancelar</th>
							<th>Ver</th>
						</tr>
					</thead>
					<tbody>
            {
              rifas && rifas.map(rifa => 
                <tr key={rifa.id}>
                  <td style={{maxWidth: "180px"}}>
                    <CampoEncerramento el={rifa} />
                  </td>
								  <td>{rifa.nome}</td>
								  <td>{new Date(rifa.data_limite) > new Date() ? 'Andamento':'Finalizado'}</td>
								  <td>{rifa.tipo}</td>
								  <td>{formatMoney(rifa.valor)}</td>
								  <td>{rifa.bilhetes}</td>
								  <td>{formatMoney(rifa.vendas)}</td>
								  <td>{rifa.premio}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link w-100 px-0"
                      onClick={() => cancelaRifa(rifa.id)}
                    >
                      Cancelar
                    </button>	
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-info w-100 px-0"
                    >
                      Ver
                    </button>	
                  </td>
                </tr>  
              )
            }
					</tbody>
				</table>
      </div>
    </>
  )
}