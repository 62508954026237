import React, {useState, useEffect} from "react";
import styles from "../Configuracao/styles.module.css";
import http from "../../../services/http";
import {alerta} from "../../../components/Alert";

export function Comissao() {
    const [comissaoMilharMoto, setComissaoMilharMoto] = useState(0);
    const [comissaoSeninha, setComissaoSeninha] = useState(0);
    const [comissaoSenaBrasil, setComissaoSenaBrasil] = useState(0);
    const [comissaoBolao, setComissaoBolao] = useState(0);
    const [comissaoBolaoDezena, setComissaoBolaoDezena] = useState(0);
    const [comissaoDoisPraQuinhentos, setComissaoDoisPraQuinhentos] = useState(0);
    const [comissaoRifa, setComissaoRifa] = useState(0);

    useEffect(() => {
        const buscarOpcoesBanca = async () => {
            try {
                const response = await http.get('/bancas/opcoes');

                const {opcoes} = response.data.data;

                const {
                    comissao_milhar_moto,
                    comissao_seninha,
                    comissao_sena_brasil,
                    comissao_bolao_dezena,
                    comissao_bolao,
                    comissao_dois_pra_quinhentos,
                    comissao_rifas,
                } = opcoes;

                setComissaoMilharMoto(comissao_milhar_moto ?? 0);
                setComissaoSeninha(comissao_seninha ?? 0);
                setComissaoSenaBrasil(comissao_sena_brasil ?? 0);
                setComissaoBolaoDezena(comissao_bolao_dezena ?? 0);
                setComissaoBolao(comissao_bolao ?? 0);
                setComissaoDoisPraQuinhentos(comissao_dois_pra_quinhentos ?? 0);
                setComissaoRifa(comissao_rifas ?? 0);
            } catch (e) {
                alerta(e.response.data)
            }
        };
        buscarOpcoesBanca();
    }, []);

    const atualizarComissoes = async () => {
        try {

            const response = await http.post('/bancas/opcoes', {
                comissao_milhar_moto: comissaoMilharMoto,
                comissao_seninha: comissaoSeninha,
                comissao_sena_brasil: comissaoSenaBrasil,
                comissao_bolao_dezena: comissaoBolaoDezena,
                comissao_bolao: comissaoBolao,
                comissao_dois_pra_quinhentos: comissaoDoisPraQuinhentos,
                comissao_rifas: comissaoRifa,
            });

            alerta(response.data)

        } catch (e) {
            alerta(e.response.data);
        }
    };

    return (
        <>
            <div className="titulo-principal">Comissões</div>
            <div className={styles.conteudoPrincipal}>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão milhar moto:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoMilharMoto(e.target.value)}
                            value={comissaoMilharMoto}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão seninha:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoSeninha(e.target.value)}
                            value={comissaoSeninha}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão sena Brasil:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoSenaBrasil(e.target.value)}
                            value={comissaoSenaBrasil}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão bolão:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoBolao(e.target.value)}
                            value={comissaoBolao}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão bolão de dezenas:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoBolaoDezena(e.target.value)}
                            value={comissaoBolaoDezena}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão 2 pra 500:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoDoisPraQuinhentos(e.target.value)}
                            value={comissaoDoisPraQuinhentos}
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Comissão rifa:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type='number'
                            className='form-control'
                            style={{width: 200}}
                            onChange={(e) => setComissaoRifa(e.target.value)}
                            value={comissaoRifa}
                        />
                    </div>
                </div>

                <div className={styles.buttonStyle}>
                    <button
                        className='btn btn-success'
                        style={{width: 200}}
                        onClick={atualizarComissoes}
                    >
                        Salvar
                    </button>
                </div>

            </div>
        </>
    );
}
