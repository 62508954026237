import {useState, useEffect} from 'react';
import ListaResponsiva from '../../../components/ListaResponsiva/Extracao';
import http from '../../../services/http';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import styles from './extracao.module.css';
import {alerta} from "../../../components/Alert";
import Modalidades from "../Modalidades";
import Cotacoes from "../Cotacoes";

const tHead = [
    'Nome',
    'Encerra',
    'Tipo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
    'Editar',
    'Status'
];

export default function Loterias(props) {
    const [listaJogos, setListaJogos] = useState([]);
    const [nomeFilter, setNomeFilter] = useState('');
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [loadingAtivo, setLoadingAtivo] = useState(true);

    const [carregaPost, setCarregaPost] = useState(false);

    const [subMenu, setSubMenu] = useState('Loterias');

    useEffect(() => {
        const requestUsuarios = async () => {
            try {
                const response = await http.get('/loterias');
                const {data} = response.data;

                setListaJogos(data);
                setLoadingAtivo(false);
            } catch (e) {
                setLoadingAtivo(false);
                alerta(e.response.data);
            }
        };
        requestUsuarios();
    }, []);

    const salvarEdicoes = async (data) => {
        try {

            setCarregaPost(true);
            const response = await http.put('/loterias/' + data.id, {
                nome: data.nome,
                hora: data.hora,
                domingo: data.domingo,
                segunda: data.segunda,
                terca: data.terca,
                quarta: data.quarta,
                quinta: data.quinta,
                sexta: data.sexta,
                sabado: data.sabado,
                quantidade_sorteios: data.quantidade_sorteios,
                abrir_dias_antes: data.abrir_dias_antes,
                ativo: !data.ativo,
                milhar_moto: data.milhar_moto
            });

            alerta(response.data);

            const novasLoterias = listaJogos.map(loteria => {
                if (loteria.id === data.id) return {...loteria, ativo: !loteria.ativo}

                return loteria;
            });

            setListaJogos(novasLoterias);

            setCarregaPost(false);
        } catch (e) {
            alerta(e.response.data);
            setCarregaPost(false);
        }
    };

    useEffect(() => {
        const funcaoQueFiltra = () => {
            return listaJogos.filter((usuario) => {
                return (
                    usuario.nome
                        .toLowerCase()
                        .indexOf(nomeFilter.toString().toLowerCase()) >= 0
                );
            });
        };
        const setFilter = () => {
            setListaFiltrada(funcaoQueFiltra());
        };

        setFilter();
    }, [nomeFilter, setListaFiltrada, listaJogos]);

    return (
        <>
            <div className="titulo-principal">{subMenu}</div>

            <div className="conteudo-principal d-flex tres-colunas mb-2">
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => setSubMenu('Loterias')}
                >
                    Extrações
                </button>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => setSubMenu('Modalidades')}
                >
                    Modalidades
                </button>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => setSubMenu('Cotações')}
                >
                    Cotações
                </button>
            </div>

            {subMenu === 'Loterias' && listaJogos.length > 0 && (
                <>
                    <div className="conteudo-principal d-flex duas-colunas">
                        <input
                            onChange={(c) => setNomeFilter(c.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Filtrar por nome"
                        />
                        <button
                            type="button"
                            className={`btn btn-info ${styles.btnCriar}`}
                            onClick={() => props.history.push('/extracao/criar')}
                        >
                            Nova Loteria
                        </button>
                    </div>

                    <ListaResponsiva
                        tHead={tHead}
                        tBody={listaFiltrada}
                        tButton1={salvarEdicoes}
                        carregaPost={carregaPost}
                    />
                </>
            )}

            {subMenu === 'Modalidades' && (
                <Modalidades/>
            )}

            {subMenu === 'Cotações' && (
                <Cotacoes/>
            )}

            {loadingAtivo && <div className="loader"/>}
        </>
    );
}
