import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import http from "../../../services/http";
import dayjs from "dayjs";
import {alerta} from "../../../components/Alert";

export function BolaoDezena() {
  const [loading, setLoading] = useState(false);
  const [boloes, setBoloes] = useState([]);
  const [criarBolao, setCriarBolao] = useState({})
  const [primeiraExtracao, setPrimeiraExtracao] = useState({})
  const [segundaExtracao, setSegundaExtracao] = useState({})
  const [terceiraExtracao, setTerceiraExtracao] = useState({})
  const [quartaExtracao, setQuartaExtracao] = useState({})

  async function criarBolaoDezena() {
    try {
      const response = await (await http.post('/boloes-dezena', {
        data_encerramento: criarBolao.data_encerramento,
        valor: criarBolao.valor,
        premio: criarBolao.premio,
        resultados: [
          primeiraExtracao,
          segundaExtracao,
          terceiraExtracao,
          quartaExtracao
        ]
      })).data;

      alerta(response);
      await buscarBoloes();

    } catch (e) {
      alerta(e.response.data)
    }
  }

  async function buscarBoloes() {
    try {
      setLoading(true);
      const {data: response} = await (await http.get('/boloes-dezena')).data;
      setBoloes(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alerta(e.response.data)
    }
      
  }

  useEffect(() => {
    buscarBoloes();
  }, [])

  return (
    <>
      <div className={`titulo-principal`}>Bolão de Dezenas</div>

      <form className="conteudo-principal">
        <div className="d-flex tres-colunas align-items-center">

          <div className="form-group">
            <label>Encerramento</label>
            <input
              type="datetime-local"
              className="form-control"
              onChange={(e) => setCriarBolao({...criarBolao, 'data_encerramento': e.target.value})}
              value={criarBolao.data_encerramento}
            />
          </div>

          <div className="form-group">
            <label>Valor</label>
            <input
              type="number"
              className="form-control"
              onChange={(e) => setCriarBolao({...criarBolao, 'valor': e.target.value})}
              value={criarBolao.valor}
            />
          </div>

          <div className="form-group">
            <label>Prêmio</label>
            <input
              type="number"
              className="form-control"
              onChange={(e) => setCriarBolao({...criarBolao, 'premio': e.target.value})}
              value={criarBolao.premio}
            />
          </div>
        </div>
      </form>


      {/*Extrações*/}
      <div className={`titulo-principal mt-2`} style={{background: '#7F7F7F'}}>Extrações</div>

      <form className="conteudo-principal">
        <div className="d-flex duas-colunas">

          <div className="form-group">
            <label>Extração 01</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setPrimeiraExtracao({
                ...primeiraExtracao,
                'nome_extracao': e.target.value
              })}
              value={primeiraExtracao.nome_extracao}
            />
          </div>

          <div className="form-group">
            <label>Data resultado</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setPrimeiraExtracao({
                ...primeiraExtracao,
                'data_resultado': e.target.value
              })}
              value={primeiraExtracao.data_resultado}
            />
          </div>

        </div>

        <div className="d-flex duas-colunas">
          <div className="form-group">
            <label>Extração 02</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSegundaExtracao({
                ...segundaExtracao,
                'nome_extracao': e.target.value
              })}
              value={segundaExtracao.nome_extracao}
            />
          </div>

          <div className="form-group">
            <label>Data resultado</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setSegundaExtracao({
                ...primeiraExtracao,
                'data_resultado': e.target.value
              })}
              value={segundaExtracao.data_resultado}
            />
          </div>
        </div>

        <div className="d-flex duas-colunas">
          <div className="form-group">
            <label>Extração 03</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setTerceiraExtracao({
                ...terceiraExtracao,
                'nome_extracao': e.target.value
              })}
              value={terceiraExtracao.nome_extracao}
            />
          </div>

          <div className="form-group">
            <label>Data resultado</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setTerceiraExtracao({
                ...terceiraExtracao,
                'data_resultado': e.target.value
              })}
              value={terceiraExtracao.data_resultado}
            />
          </div>
        </div>

        <div className="d-flex duas-colunas">
          <div className="form-group">
            <label>Extração 04</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setQuartaExtracao({
                ...quartaExtracao,
                'nome_extracao': e.target.value
              })}
              value={quartaExtracao.nome_extracao}
            />
          </div>

          <div className="form-group">
            <label>Data resultado</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setQuartaExtracao({
                ...quartaExtracao,
                'data_resultado': e.target.value
              })}
              value={quartaExtracao.data_resultado}
            />
          </div>
        </div>
      </form>

      <div className="d-flex mb-4 justify-content-end duas-colunas">
        <button
          type="submit"
          onClick={criarBolaoDezena}
          className="btn btn-success mt-2"
          style={{height: '38px'}}
        >
          Criar
        </button>
      </div>

      {loading && <div className="loader"/>}

      {boloes.length > 0 && !loading &&
        <div className="border border-dark mt-2">
          <table className="table">
            <thead>
            <tr>
              <th>Encerramento</th>
              <th>Situação</th>
              <th>Ver</th>
            </tr>
            </thead>

            <tbody>
            {boloes?.map((bolao) => (
              <tr key={bolao.id}>
                <td>{dayjs(bolao.data_encerramento).format('DD/MM/YY HH:mm')}</td>
                <td>
                  {bolao.cancelado_em ? 'Cancelado' :
                    new Date(bolao.data_encerramento) > new Date() ?
                      'Andamento' : 'Finalizado'}
                </td>
                <td>
                  <NavLink
                    className={'btn btn-light'}
                    exact
                    to={{pathname: '/bolao-dezena/' + bolao.id}}
                  >
                    Ver
                  </NavLink>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      }
    </>
  );
}
