import {useEffect, useState} from 'react';

import styles from './styles.module.css';
import http from '../../../services/http';
import formatMoney from '../../../utils/formatMoney';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {alerta} from "../../../components/Alert";

const MySwal = withReactContent(Swal);


export default function loteriaInstantanea() {
  const [caixa, setCaixa] = useState({});
  const [porcentagemCaixa, setPorcentagemCaixa] = useState({});
  const [porcentagemEntradaBanca, setPorcentagemEntradaBanca] = useState(0);
  
	const [carregaPost, setCarregaPost] = useState(false);

  async function buscarCaixaInstantaneo() {
    const {data: response} = await http.get('/loterias-instantanea/caixa');
    setCaixa(response.data);
  }

  useEffect(() => {
    buscarCaixaInstantaneo();
  }, []);

  function calculaPorcentagem() {
    const venda = 100;
    const premio = (caixa.premio * 100) / caixa.entrada;
    const comissao = (caixa.comissao * 100) / caixa.entrada;
    const total = (caixa.total * 100) / caixa.entrada;

    setPorcentagemCaixa({
      venda,
      premio: premio.toFixed(),
      comissao: comissao.toFixed(),
      total: total.toFixed(),
    });
  }

  useEffect(() => {
    calculaPorcentagem();
    setPorcentagemEntradaBanca(caixa.porcentagem_entrada_banca);
  }, [caixa]);

  async function atualizarPorcentagemEntradaBanca(event) {
    
    try {
      event.preventDefault();

      setCarregaPost(true);

      const data = await http.patch('/loterias-instantanea/porcentagem', {
        porcentagem: porcentagemEntradaBanca,
      });

      alerta(data.data);

      setCarregaPost(false);
    } catch (e) {
      alerta(e.response.data);
      setCarregaPost(false);
    }
    
    
  }

  function perguntaLimparCaixa() {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja limpar o caixa? Essa ação vai zerar seu caixa, e não poderá ser revertida!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        limparCaixa();
      }
    });
  };

  async function limparCaixa() {
    const {data} = await http.patch('/loterias-instantanea/caixa/zerar');

    MySwal.fire({
      type: data.success ? 'success' : 'error',
      title: data.message,
    });

    buscarCaixaInstantaneo();
  }

  return (
    <>
      <div className='titulo-principal'>Instantânea</div>
      <article className={`conteudo-principal ${styles.containerBoxes}`}>
        <section>
          <h1>Loteria Instantânea</h1>

          <div className={styles.boxCalculations}>
            <div>
              <p>Venda <span>{caixa.entrada ? formatMoney(caixa.entrada) : formatMoney(0)}</span></p>
              <p>{porcentagemCaixa.venda}%</p>
            </div>
            <div>
              <p>Prêmio <span>{caixa.premio ? formatMoney(caixa.premio) : formatMoney(0)}</span></p>
              <p>{isNaN(porcentagemCaixa.premio) ? 0 : porcentagemCaixa.premio}%</p>
            </div>
            <div>
              <p>Comissão <span>{caixa.comissao ? formatMoney(caixa.comissao) : formatMoney(0)}</span></p>
              <p>{isNaN(porcentagemCaixa.comissao) ? 0 : porcentagemCaixa.comissao}%</p>
            </div>
            <div>
              <p>Total <span>{caixa.total ? formatMoney(caixa.total) : formatMoney(0)}</span></p>
              <p>{isNaN(porcentagemCaixa.total) ? 0 : porcentagemCaixa.total}%</p>
            </div>
            <button onClick={perguntaLimparCaixa}>
              Limpar
            </button>
          </div>

          <form className={styles.formPremiumPercentage}>
            <div>
              <label htmlFor='porcentagem_entrada_banca'>Lucro da banca (%):</label>
              <input type='number'
                     name='porcentagem_entrada_banca'
                     id='porcentagem_entrada_banca'
                     value={porcentagemEntradaBanca}
                     onChange={({target}) => setPorcentagemEntradaBanca(target.value)}/>
            </div>

            <button 
              className={`btn btn-success`} 
              onClick={atualizarPorcentagemEntradaBanca}
              disabled={carregaPost}
            >
              Salvar
            </button>
          </form>
        </section>

        <section>
          <h1>Raspadinha</h1>

          <div className={styles.boxCalculations}>
            <div>
              <p>Venda <span>R$ 0,00</span></p>
              <p>100%</p>
            </div>
            <div>
              <p>Prêmio <span>R$ 0,00</span></p>
              <p>0%</p>
            </div>
            <div>
              <p>Comissão <span>R$ 0,00</span></p>
              <p>0%</p>
            </div>
            <div>
              <p>Total <span>R$ 0,00</span></p>
              <p>0%</p>
            </div>
            <button>
              Limpar
            </button>
          </div>

          <form className={styles.formPremiumPercentage}>
            <div>
              <label htmlFor='porcentagem_entrada_banca'>Lucro da banca (%):</label>
              <input type='number' name='porcentagem_entrada_banca' value='30' id='porcentagem_entrada_banca'/>
            </div>

            <button className={`btn btn-success`} type={'submit'}>
              Salvar
            </button>
          </form>
        </section>
      </article>
    </>
  );
}