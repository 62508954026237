import React, {useEffect, useState} from 'react';

import http from '../../../services/http';
import styles from './styles.module.css';
import {alerta} from "../../../components/Alert";

export default function Cotacoes() {

    const [modalidadesTradicional, setModalidadesTradicional] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isActiveFieldsEditCotacao, setIsActiveFieldsEditCotacao] = useState(true);
    const [pagination, setPagination] = useState(1);

    const [carregaPost, setCarregaPost] = useState(false);

    const naoMostrar = ['Duque de Grupo', 'Terno de Grupo', 'Milhar Brinde', 'Duque de Dezena', 'Passe', 'Passe vai e vem', 'Duque de Dezena Combinado', 'Terno de Dezena', 'Terno de Dezena Combinado', 'Terno de Grupo Combinado', 'Unidade', 'Milhar e Centena', 'MC Invertida', 'Milhar Invertida', 'Centena Invertida', 'Terno de Grupo Combinado  1/3', 'Terno de Grupo Combinado 1/5', 'Duque de Dezena Combinado  1/2', 'Duque de Dezena Combinado 1/5', 'Terno de Dezena Combinado  1/3', 'Terno de Dezena Combinado 1/5',];

    useEffect(() => {
        requestBanca();
    }, []);

    async function requestBanca() {
        try {
            const responseModalidadesTradicional = await http.get('/bancas/modalidades');
            const {data: modalidadesTradicional} = responseModalidadesTradicional.data;
            setModalidadesTradicional(modalidadesTradicional);

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            alerta(e.response.data)
        }
    }

    async function enviarAlteracoes() {
        try {
            setCarregaPost(true);
            const modalidades = modalidadesTradicional;

            const res = await http.patch('/bancas/modalidades', {
                modalidades,
            });

            alerta(res.data)

            setIsActiveFieldsEditCotacao(true);
            setCarregaPost(false);
        } catch (e) {
            setCarregaPost(false);
            alerta(e.response.data)
        }
    }

    function handleClickEditCotacao() {
        setIsActiveFieldsEditCotacao(!isActiveFieldsEditCotacao);
    }

    function handlePagination(pag) {
        setPagination(pag);
    }

    function handleChangeValueCotacao(event) {
        const targetElement = event.target;
        const value = targetElement.value;
        const indexModalidade = targetElement.dataset.indexModalidade;
        const indexCotacao = targetElement.dataset.indexCotacao;

        const arrayModalidades = modalidadesTradicional;
        arrayModalidades[indexModalidade].cotas[indexCotacao] = value;

        setModalidadesTradicional([...arrayModalidades]);
    }

    function pegarCotacoes(modalidade) {
        let cotas = [];

        Object.keys(modalidade.cotas).forEach(function (item) {
            cotas.push(modalidade.cotas[item]);
        });

        return cotas;
    }

    return (
        <>
            {isLoading && (<div className='loader'/>)}


            {!isLoading && modalidadesTradicional.length > 0 && (
                <div className='bordered mt-3'>

                    <>
                        <div>
                            {pagination === 1 ? (
                                <>
                                    <table className='table'>

                                        <thead>
                                        <tr>
                                            <th>Modalidade</th>
                                            <th>Cota 1</th>
                                            <th>Cota 2</th>
                                            <th>Cota 3</th>
                                            <th>Cota 4</th>
                                            <th>Cota 5</th>
                                            <th>Cota 6</th>
                                            <th>Cota 7</th>
                                            <th>Cota 8</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {modalidadesTradicional.map((modalidade, indexModalidade) => {
                                            if (!naoMostrar.includes(modalidade.nome)) {
                                                return (<tr key={indexModalidade}>
                                                    <td>{modalidade.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}</td>
                                                    {pegarCotacoes(modalidade).map((cota, indexCotacao) => {
                                                        if (indexCotacao <= 7) {
                                                            return (<td>
                                                                <input
                                                                    key={indexCotacao}
                                                                    onChange={handleChangeValueCotacao}
                                                                    data-index-modalidade={indexModalidade}
                                                                    data-index-cotacao={indexCotacao + 1}
                                                                    type='number'
                                                                    className='form-control'
                                                                    disabled={isActiveFieldsEditCotacao}
                                                                    value={cota}
                                                                />
                                                            </td>);
                                                        }
                                                    })}
                                                </tr>);
                                            }
                                        })}
                                        </tbody>

                                    </table>

                                    <div className={styles.pagination}>

                                        <a
                                            onClick={() => handlePagination(1)}
                                            className={styles.active}
                                        >
                                            1
                                        </a>
                                        <a onClick={() => handlePagination(2)}>2</a>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <table className='table'>

                                        <thead>
                                        <tr>
                                            <th>Modalidade</th>
                                            <th>Cota 9</th>
                                            <th>Cota 10</th>
                                            <th>Cota 11</th>
                                            <th>Cota 12</th>
                                            <th>Cota 13</th>
                                            <th>Cota 14</th>
                                            <th>Cota 15</th>
                                            <th>Cota 16</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {modalidadesTradicional.map((modalidade, indexModalidade) => {
                                            if (!naoMostrar.includes(modalidade.nome)) {
                                                return (<tr key={indexModalidade}>
                                                    <td>{modalidade.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}</td>
                                                    {pegarCotacoes(modalidade).map((cota, indexCotacao) => {
                                                        if (indexCotacao > 7) {
                                                            return (<td>
                                                                <input
                                                                    key={indexCotacao}
                                                                    onChange={handleChangeValueCotacao}
                                                                    data-index-modalidade={indexModalidade}
                                                                    data-index-cotacao={indexCotacao + 1}
                                                                    type='number'
                                                                    className='form-control'
                                                                    disabled={isActiveFieldsEditCotacao}
                                                                    value={cota}
                                                                />
                                                            </td>);
                                                        }
                                                    })}
                                                </tr>);
                                            }
                                        })}
                                        </tbody>

                                    </table>
                                    <div className={styles.pagination}>
                                        <a onClick={() => handlePagination(1)}>1</a>
                                        <a
                                            onClick={() => handlePagination(2)}
                                            className={styles.active}
                                        >
                                            2
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className='d-flex duas-colunas' style={{padding: '10px'}}>
                            {isActiveFieldsEditCotacao ? (<button
                                className='btn btn-info'
                                onClick={() => handleClickEditCotacao()}
                                disabled={carregaPost}
                            >
                                Editar
                            </button>) : (<>
                                <button
                                    className='btn btn-secondary'
                                    onClick={() => handleClickEditCotacao()}
                                    disabled={carregaPost}
                                >
                                    Desabilitar
                                </button>
                            </>)}
                            <button
                                className='btn btn-success'
                                onClick={() => enviarAlteracoes()}
                                disabled={carregaPost}
                            >
                                Salvar
                            </button>
                        </div>
                    </>


                </div>
            )}

        </>);
}
