import Bilhete from 'components/BilheteBolao/index';
import http from '../../../../services/http';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function ResultadoPuleBolao({ bolao, setBolao }) {
	const [loadingAtivo, setLoadingAtivo] = useState(true);
	const [pule, setPule] = useState({});
	const [exibirModal, setExibirModal] = useState(false);

	useEffect(() => {

		setLoadingAtivo(false);
	}, [bolao]);

	const abrirModal = (pule, modal) => {
		setPule(pule)
		setExibirModal(modal)
	}

	const modalBilhete = () => {
		return (
			exibirModal && (
				<div
					className="modal"
					style={{
						display: 'block',
						overflowY: 'scroll',
						maxHeight: '100%'
					}}
				>
					<div className="modal-dialog">
						<div className="modal-content modal-bilhete">
							<div className="modal-body">
								<div className="center">
									<Bilhete 
										aposta={{...pule, bolao: bolao, times: bolao.times}} 
									/>	
								</div>
							</div>
							<div className="modal-footer">
								<button
								    style={{width:'100%'}}
									type="button"
									className="btn btn-danger"
									data-dismiss="modal"
									onClick={() => abrirModal({}, false)}
								>
									Fechar
								</button>
							</div>
						</div>
					</div>
				</div>
			)
		);
	};

	return (
		<>
			<div className="titulo-principal">
				Pules
			</div>
			<div className="conteudo-principal">
					<table className="table text-left">
						<thead>
							<tr>
                <th>Acertos</th>
                <th>Operador</th>
                <th>Bilhete</th>
                <th>Situação</th>
                <th>Premio</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								bolao.bilhetes && bolao.bilhetes
									.sort((ant, att) => ant.acertos < att.acertos)
									.map((el, ind) => 
								<tr key={ind}>
                  <td>{
                    el.palpites_bolao.filter(el => el.situacao == 'vencedor').length
                  }</td>
                  <td>{el.cambista.login}</td>
                  <td>{el.codigo}</td>
                  <td>{el.situacao}</td>
                  <td>{el.premio}</td>
                  <td>
										<button
											type="button"
											className="btn btn-info w-100 px-0"
											onClick={() => abrirModal(el, true)}
										>
											Ver
										</button>	
									</td>
								</tr>
								)
							}	
						</tbody>
					</table>
			</div>

			{modalBilhete()}
		</>
	);
}