import {useEffect, useState} from 'react';
import styles from './styles.module.css';
import http from 'services/http';

import {NavLink} from "react-router-dom";
import {alerta} from "../../../components/Alert";
import {ModalidadeSeninha} from "./cotacao";

export default function Seninha() {
  const [subMenu, setSubMenu] = useState('Concursos');
  const [concursos, setConcursos] = useState([]);
  const [loading, setLoading] = useState(false);

  async function requestConcursos() {
    try {
      setLoading(true);
      const {data: response} = await http.get('/seninha/concursos');

      setConcursos(response.data);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      alerta(e.response.data)
    }
  }

  async function atualizarStatusConcurso(id) {
    try {
      const {data: response} = await http.patch(`/seninha/concursos/${id}`);

      if (response.success) {

        const concursosAtualizado = concursos.map((concurso) => {
          if (concurso.id === id) {
            concurso.status = concurso.status ? 0 : 1;
          }

          return concurso;
        })

        setConcursos(concursosAtualizado);

      }

      alerta(response);
    } catch (e) {
      alerta(e.response.data);
    }

  }

  useEffect(() => {
    requestConcursos();
  }, []);

  return (
    <>
      <div>
        <div className={styles.seninha}>

          <div className={`titulo-principal ${styles.header_seninha}`}>{subMenu}</div>

          <div className="conteudo-principal d-flex duas-colunas mb-2">
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => setSubMenu('Concursos')}
            >
              Concursos
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => setSubMenu('Modalidades')}
            >
              Modalidades
            </button>
          </div>

          {loading && <div className="loader"/>}

          {subMenu === 'Concursos' && !loading && concursos.length > 0 && (
            <div className="bordered">
              <table className="table">
                <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Encerra</th>
                  <th>Segunda</th>
                  <th>Terça</th>
                  <th>Quarta</th>
                  <th>Quinta</th>
                  <th>Sexta</th>
                  <th>Sábado</th>
                  <th>Domingo</th>
                  <th>Editar</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {concursos?.map((concurso) => {
                  return (
                    <tr key={concurso.id}>
                      <td>{concurso.nome}</td>
                      <td>{concurso.hora_encerramento.split(':')[0] + ':' + concurso.hora_encerramento.split(':')[1]}</td>
                      <td>{concurso.segunda ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.terca ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.quarta ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.quinta ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.sexta ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.sabado ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>{concurso.domingo ? <span className="true">Sim</span> :
                        <span className="false">Não</span>}</td>
                      <td>
                        <NavLink
                          className={'btn btn-light'}
                          exact
                          to={{pathname: '/seninha/' + concurso.id}}
                        >
                          Editar
                        </NavLink>
                      </td>
                      <td>
                        <button
                          className={`btn ${concurso.status ? 'btn-success' : 'btn-danger'}`}
                          onClick={() => atualizarStatusConcurso(concurso.id)}
                        >
                          {concurso.status ? 'Ativa' : 'Inativa'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          )}

          {subMenu === 'Modalidades' && !loading && concursos.length > 0 && <ModalidadeSeninha/>}
        </div>
      </div>
    </>
  );
}
