import {toast} from "react-toastify";
import fut from "../../services/futebol";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal)

export const ListaTimes = async ( setTimes ) => {

  const {value: comps} = await MySwal.fire({
    title: 'Selecione um campeonato',
    input: 'select',
    inputOptions: {
      WC: 'FIFA World Cup',
      CL: 'UEFA Champions League',
      BL1: 'Bundesliga',
      DED: 'Eredivisie',
      BSA: 'Campeonato Brasileiro Série A',
      PD: 'Primera Division',
      FL1: 'Ligue 1',
      ELC: 'Championship',
      PPL: 'Primeira Liga',
      EC: 'European Championship',
      SA: 'Serie A',
      PL: 'Premier League',
      CLI: 'Copa Libertadores',
    },
    inputPlaceholder: 'Selecione',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText:
      'Continuar <i className="fa fa-arrow-right"></i>',
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          resolve()
        } else {
          resolve('Nenhum campeonato selecionado!')
        }
      })
    }
  })

  if (comps) {

    MySwal.fire({
      title: 'Os times foram carregados!',
      confirmButtonText: 'Confirmar',
      type: 'info'
    })

    try {
      MySwal.showLoading()

      const {data} = await fut.get(`competitions/${comps}/matches?status=SCHEDULED`)
      Swal.hideLoading()

      setTimes(times(data));
    } catch (error) {
      Swal.hideLoading()
      setTimes([]);
    }
    
  }

}

const times = (data) => {

  try {

    const arr = [];

    data.matches.forEach(comps => {

      if (arr.map(el => el.id).indexOf(comps.awayTeam.id) == -1) {
        arr.push(comps.awayTeam)
      }

      if (arr.map(el => el.id).indexOf(comps.homeTeam.id) == -1) {
        arr.push(comps.homeTeam)
      }

    });

    return arr;

  } catch (error) {}

}