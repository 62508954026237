import {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import {useLoading} from '../../../../hook/loading';
import http from "../../../../services/http";
import {alerta} from "../../../../components/Alert";
import Swal from "sweetalert2";

export default function AdicionarResultadoConcursoSenaBrasil({location: dados}) {
    const [loading, setLoading] = useLoading();

    const [data, setData] = useState('');
    const [concurso, setConcurso] = useState(null);
    const [resultados, setResultados] = useState([]);


    useEffect(() => {
        if (dados.concurso && dados.data) {

            setConcurso(dados.concurso);
            setData(dados.data);
            setResultados(dados.concurso.resultado ? dados.concurso.resultado.resultados : []);

        } else {
            history.back();
        }
    }, [dados.concurso, dados.data]);

    async function adicionarResultado() {

        if (concurso.quantidade_numero_resultado !== resultados.length) {
            Swal.fire({
                type: 'error',
                title: `Adicione ${concurso.quantidade_numero_resultado} resultados para continuar!`
            });
        }

        const {data: response} = await http.post(`sena-brasil/concursos/resultados/${concurso.id}`, {
            data_resultado: data,
            resultados
        });

        alerta(response);
        history.push('/resultado')
    }

    async function atualizaResultado(index, valor) {
        const resultadosArray = resultados;
        resultadosArray[index] = valor;

        setResultados([...resultadosArray])
    }

    return (
        <>
            <div className="titulo-principal">
                {concurso && concurso.nome} -&nbsp;
                {dayjs(data).format('DD/MM/YYYY')}
            </div>

            <div className="conteudo-principal">

                {Array.from({length: concurso?.quantidade_numero_resultado}).map((value, index) =>

                    <div key={index} className="form-group d-flex justify-content-start">
                        <div style={{width: '100px'}}>
                            {index === 9 ? 10 : '0' + (index + 1)}
                        </div>
                        <input
                            style={{width: '300px'}}
                            type="text"
                            maxLength="2"
                            pattern="[0-9]"
                            className="form-control"
                            onChange={(event) => atualizaResultado(index, event.target.value)}
                            value={resultados[index]}
                        />
                    </div>
                )}

                <div className="d-flex duas-colunas m-t">
                    <button
                        className="btn btn-warning"
                        // onClick={() => simularResultado()}
                        disabled={loading}
                    >
                        Simular resultado
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={adicionarResultado}
                        disabled={loading}
                    >
                        {!loading ? 'Salvar' : 'Aguarde...'}
                    </button>
                </div>
            </div>
        </>
    );
}
