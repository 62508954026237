import { Component } from 'react';
import dayjs from 'dayjs';
import url from '../../../../utils/url';
import styles from './styles.module.css';
import http from 'services/http';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {alerta} from "../../../../components/Alert";

const MySwal = withReactContent(Swal);

const situacao = [
	{
		label: 'Todos',
		value: 'todos'
	},
	{
		label: 'Pendente',
		value: 'pendente'
	},
	{
		label: 'Perdedor',
		value: 'perdedor'
	},
	{
		label: 'Pago',
		value: 'pago'
	}
];

export default class index extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: '',
			hora: '',
			premio: '',
			valorMinimo: '',
			situacaoSelect: '',
			text: '',
			exibirModal: false,
			loadingAtivo: false,	
			imgPremio: `${url}/storage/bancas/logo.jpeg`,
			premios: [],
			carregaPost: false,
		};
	}

	componentDidMount() {
		this.requestPremios();
  } 
	 
	requestPremios = async () => {
		try {
			const response = await http.get('/sorteios/premios');

			this.setState({premios: response.data.data});

		} catch (e) {
      alerta(e.response.data)
		}
	}
	 
	 
	salveSorteio = async () => {
		try {
			this.setState({carregaPost: true});
			
      const res = await http.post('/sorteios', {
        premio: this.state.premio,
        situacao: this.state.situacaoSelect,
        valor_minimo: (this.state.valorMinimo === null) ? 0 : this.state.valorMinimo,
        data_sorteio: this.state.data + " " +this.state.hora + ":00",
        info: this.state.text,
      });

      alerta(res.data)
      this.props.history.push('/sorteio');
			
			this.setState({carregaPost: false});
		   
		} catch (error) {
			this.setState({carregaPost: false});
      alerta(error.response.data)
    }
	};

	setValuePremio = async (value) => {
		try {
			if(value == 0) {
				this.state.imgPremio = `${url}/storage/bancas/logo.jpeg`;
				this.setState({premio : value });
			}else{
				this.state.premios.find(element => {
					if(element.key == value){
						this.state.imgPremio = element.icon;
					}
				})
				this.setState({premio : value });
			}

		}catch(error){
          console.log(error);
		}	
	}
	render() {

	  const icon = "fa fa-5x " + this.state.imgPremio;
	  return (
		<>
			<div className="titulo-principal">Criar Sorteio</div>
			<div className={styles.conteudoPrincipal}>
               <div className={styles.fields_form}>
				   <div class={styles.inputs}>

					  	    <div className={styles.formGroup}>
								<div className={styles.label}>
								<label>Premio:</label>
								</div>
								<div className={styles.selectField}>
										<select
									    style={{ width: 200 }}
										onChange={(e) => this.setValuePremio(e.target.value)}
										className="form-control"
								      	>
										<option value={0}></option>
										{this.state.premios.map((premio) => (
											<option key={premio.key} value={premio.key}>
												{premio.value}
											</option>
										))}
								    </select>
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={styles.label}>
								<label>Status:</label>
								</div>
								<div className={styles.selectField}>
									<select
									    style={{ width: 200 }}
										onChange={(e) => this.setState({ situacaoSelect: e.target.value })}
										className="form-control"
								      	>
									    <option></option>
										{situacao.map((situacao) => (
											<option key={situacao.value} value={situacao.value}>
												{situacao.label}
											</option>
										))}
								    </select>
								</div>
							</div>
							<div className={styles.formGroup}>
										<div className={styles.label}>
											<label>Valor mínimo do bilhete:</label>
										</div>
										<div className={styles.selectField}>
											<input
											    required
												type="number"
												className="form-control"
												style={{ width: 200 }}
												onChange={(e) => this.setState({ valorMinimo: e.target.value })}
											/>
										</div>
									</div>
							<div className={styles.formGroup}>
								<div className={styles.label}>
									<label>Data do Sorteio:</label>
								</div>
								<div className={styles.selectField}>
									<input
										style={{ width: 200 }}
										required
										type="date"
										onChange={(e) => this.setState({ data: e.target.value })}
										className="form-control"
																/>
								</div>
							</div>
							<div className={styles.formGroup}>
							    <div className={styles.label}>
									<label className="control-label">Encerra</label>
								</div>
								<div className={styles.selectField}>
									<input
									    style={{ width: 200 }}
										required
										onChange={(c) => this.setState({ hora: c.target.value })}
										type="time"
										className="form-control"
									/>
								</div>	
						</div>
				   </div>
				   <div className={styles.img}>
					   {(this.state.premio == 0) ? (
						   <img src={this.state.imgPremio}
						   width={300}
						   height={200}
						   />
					   ): (
							<i className={icon} aria-hidden="true"></i>
					   )}
				  </div>
	           </div>
			   <div className={styles.textSorteio}>
				   
					<div className={styles.formGroup}>
						<div className={styles.label}>
							<label>Informações:</label>
						</div>
						<div className={styles.selectField}>
							<textarea
								className="form-control"
								style={{ width: 200 }}
								rows="5"
								onChange={(e) => this.setState({ text: e.target.value })}
							>
						    </textarea>
						</div>
					</div>

			   </div>	
			   <div className={styles.description}>
					<div className={styles.formGroup}>
						{(this.state.premio) ? (
	                     <span>Concorre ao prêmio:  {this.state.premios.map(
								 item => (item.key == this.state.premio) ?
								  <span>{item.value}</span> :
								   '')}
								   </span>
						): <></>}
					
					</div>
					<div className={styles.formGroup}>
						<p>{this.state.text} </p>	
					</div>
			   </div>
			   <div className={styles.buttonStyle}>
					<button 
						className="btn btn-success" 
						onClick={() => this.salveSorteio()}
						disabled={this.state.carregaPost}
					>
						Criar Sorteio
					</button>
				</div>
			</div>			
		</>
	);
  }
}
