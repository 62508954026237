import {Route, Switch, Redirect} from "react-router-dom";

import Login from "../views/Login";
import Layout from "../views/Layout";

import rota from "./admin/links";

import NotFound from "views/404";

const nivel = localStorage.getItem("nivel");

const rotas = () => {
  
  if (!nivel) {
    return <Redirect to={{pathname: "/login"}}/>;
  }

  return rota
    .map((rota) => (
      <PrivateRoute
        exact
        key={rota.path}
        path={rota.path}
        component={rota.component}
      />
    ));

};

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    render={(props) => <Layout {...props} component={Component}/>}
    {...rest}
  />
);

const Routes = () => (
  <Switch nivel={nivel}>
    <Route path="/login" component={Login}/>
    {rotas()}
    <PrivateRoute component={NotFound}/>
  </Switch>
);

export default Routes;
