import dayjs from 'dayjs';

import calcularGrupo from 'utils/calcularGrupo';
import formatMoney from 'utils/formatMoney';
import Logo from 'utils/Logo';

import './styles.css';

const formatarNumeros = (numeros) => {
  let numerosArr = [];

  for (let e = 0; e < numeros.length; e = e + 4) {
    numerosArr.push(numeros.slice(e, e + 4).join(" "));
  }

  return numerosArr;
}

const BilheteRifa = ({aposta, nivel}) => {
  return (
    <>
      <div className='bilheteContainer'>
        <div className='logoDiv'>
          <Logo/>
        </div>

        <h3 style={{fontWeight: 'bold', fontFamily: 'arial'}}>
          Bilhete: {aposta.codigo}
        </h3>
        <span style={{fontFamily: 'arial', color: '#000'}}>
          Emitido: {dayjs(aposta.data_aposta).format('DD/MM/YYYY HH:mm:ss')}
        </span>

        <br/>
        <span>
          Ponto: {aposta.operador}
        </span>
        <br/>

        <span>
          Situação:&nbsp;
          <span className={`badge ${aposta.situacao}`}>
            {aposta.situacao.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
              return a.toUpperCase();
            })}
            
          </span>

          {aposta.situacao === 'pago' || aposta.situacao === 'vencedor' ? (
            <span style={{marginLeft: '10px'}}>{formatMoney(aposta.premio, 2)}</span>
          ) : ''}
        </span>
        <hr/>
        <h4>
          {aposta.titulo}
        </h4>
        <hr/>

        {aposta.palpites.map((palpite, index) => {
          return (
            <div key={index}>
                  <span>
                    Palpites
                  </span>
                  <br/>
                  {formatarNumeros(palpite.numeros).map((el) => 
                    <h6>{el}</h6>
                  )}
              <hr/>
            </div>
          );
        })}

        <h4 style={{fontWeight: 'bold'}}>
          TOTAL: {formatMoney(aposta.valor)} <br/>
        </h4>
        <h4 style={{fontWeight: 'bold'}}>
          PREMIO: {formatMoney(aposta.premio)} <br/>
        </h4>
        <br/>
        <span>{aposta.texto_bilhete}</span>
      </div>

      {aposta.pago_em ? (
        <>
          <br/>
          <div className={`box ${aposta.situacao}`}>
            <span style={{fontWeight: 'bold'}}>
              Pago em:&nbsp;
              {dayjs(aposta.pago_em).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
        </>
      ) : (
        <>
          <br/>
          {aposta.cancelado_em && (
            <div className={`box ${aposta.situacao}`}>
              <span style={{fontWeight: 'bold'}}>
                Cancelado:&nbsp;
                {dayjs(aposta.cancelado_em).format('DD/MM/YYYY HH:mm:ss')}
                <br/>
                Nível: {aposta.usuario_cancelou.nivel}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BilheteRifa;
