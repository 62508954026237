import { useState } from 'react';

export function useLoading() {
	// state for loading
	const [loading, setLoading] = useState(false);

	function start() {
		setLoading(true);
	}

	function stop() {
		setLoading(false);
	}

	return [loading, start, stop];
}
