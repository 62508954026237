import React, {useEffect, useState} from 'react';

import './index.css';
import numerosRoleta from "../../../utils/numerosRoleta";
import ButtonsRoleta from "../../../components/Roleta/ButtonsRoleta";
import Swal from "sweetalert2";

const Roleta = () => {
  const [numerosEscolhidos, setNumerosEscolhidos] = useState({});
  const [numeros, setNumeros] = useState({});

  useEffect(() => {
    setNumeros(numerosRoleta());
  }, [])
  
  const girarRoleta = async () => {
    const sorteado = Math.floor(Math.random() * 37);
    const target = document.querySelector('.ais-animating');

    const player = target.animate([
      {transform: `rotate(-360deg)`},
      {transform: `rotate(${numeros[sorteado].numero}deg)`},
    ], 5000);

    player.addEventListener('finish', function() {
      target.style.transform = `rotate(${numeros[sorteado].numero}deg)`;
      
      if (numerosEscolhidos[sorteado]) {
        Swal.fire({
          type: 'success',
          title: 'Você acertou! o numero ' + sorteado
        });
      }
    });
  }
  
  const selecionaNumero = (numero) => {
    
    if (numerosEscolhidos[numero]) {
      delete numerosEscolhidos[numero];
      setNumerosEscolhidos({
        ...numerosEscolhidos,
      });
    } else {
      setNumerosEscolhidos({
        ...numerosEscolhidos,
        [numero]: numeros[numero]
      })
    }

  }

  return (
    <div className="text-center">
      <div className="d-flex">
        <div className="pointer bg-dark m-auto" />
      </div>
      <img className="spinner ais-animating" src="roleta.png" alt="" width="45%" />

      <div className="card mt-3">
        <div className="card-body d-flex">
          <ButtonsRoleta numeros={numerosEscolhidos} onClickButton={selecionaNumero}/>
        </div>
      </div>
      
      <div className="card mt-3">
        <div className="card-body d-flex">
          <ButtonsRoleta numeros={numeros} onClickButton={selecionaNumero}/>
        </div>
      </div>
      
      <div className="card mt-3">
        <div className="card-body d-flex">
          <button type="button" onClick={girarRoleta} className="btn btn-primary w-100 m-1">Girar</button>
        </div>
      </div>
    
    </div>
  )
}

export default Roleta;
