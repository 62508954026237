import { useState, useEffect } from 'react';

import http from 'services/http';

import styles from './styles.module.css';
import { alerta } from "../../../components/Alert";

export default function Configuracao() {
    const [gerenteCancelar, setGerenteCancelar] = useState(true);
    const [tempoSegundaVia, setTempoSegundaVia] = useState(0);
    const [tempoCancelarAposta, setTempoCancelarAposta] = useState(0);
    const [textoBilhete, setTextoBilhete] = useState('');
    const [apostasAtivas, setApostasAtivas] = useState('');
    const [baixaAutomatica, setBaixaAutomatica] = useState('');
    const [tipoMilharBrinde, setTipoMilharBrinde] = useState('multiplicado');
    const [minimoMilharBrinde, setMinimoMilharBrinde] = useState('2');
    const [maximoMilharBrinde, setMaximoMilharBrinde] = useState('10');
    const [multiplicadorBrinde, setMultiplicadorBrinde] = useState('100');
    const [fixoMilharBrinde, setFixoMilharBrinde] = useState('1000');
    const [comissaoMilharMoto, setComissaoMilharMoto] = useState('0');
    const [comissaoSeninha, setComissaoSeninha] = useState('0');
    const [comissaoDoisPraQuinhentos, setComissaoDoisPraQuinhentos] = useState('0');
    const [cambistas, setCambistas] = useState('0');
    const [modulos, setModulos] = useState([]);
    const [diasInativar, setDiasInativar] = useState('30');

    const [carregaPost, setCarregaPost] = useState(false);

    useEffect(() => {
        const requestEmpresas = async () => {
            try {
                const response = await http.get('/bancas/opcoes');

                const { texto_bilhete, opcoes, apostas_ativas, baixa_automatica } = response.data.data;

                const {
                    gerente_cancelar_apostas,
                    tempo_cancelar_aposta,
                    tempo_segunda_via,
                    tipo_milhar_brinde,
                    fixo_milhar_brinde,
                    minimo_milhar_brinde,
                    maximo_milhar_brinde,
                    multiplicador_milhar_brinde,
                    comissao_milhar_moto,
                    comissao_seninha,
                    comissao_dois_pra_quinhentos,
                    dias_inativar,
                } = opcoes;
                setGerenteCancelar(gerente_cancelar_apostas);
                setTempoCancelarAposta(tempo_cancelar_aposta);
                setTempoSegundaVia(tempo_segunda_via);
                setApostasAtivas(apostas_ativas ? 1 : 0);
                setTextoBilhete(texto_bilhete);
                setTipoMilharBrinde(tipo_milhar_brinde);
                setMinimoMilharBrinde(minimo_milhar_brinde);
                setMaximoMilharBrinde(maximo_milhar_brinde);
                setFixoMilharBrinde(fixo_milhar_brinde);
                setMultiplicadorBrinde(multiplicador_milhar_brinde);
                setComissaoMilharMoto(comissao_milhar_moto);
                setComissaoSeninha(comissao_seninha);
                setComissaoDoisPraQuinhentos(comissao_dois_pra_quinhentos);
                setDiasInativar(dias_inativar);
                setBaixaAutomatica(baixa_automatica ? 1 : 0)
            } catch (e) {
                alerta(e.response.data)
            }
        };
        requestEmpresas();
        requestUsuarios();
        requestModulos();
    }, []);

    const requestModulos = async () => {
        try {

            const response = await http.get('/bancas/modulos')
            setModulos(response.data.data);

        } catch (e) {
            alerta(e.response.data)
        }
    }

    const requestUsuarios = async () => {
        try {

            const response = await http.get('/usuarios', {
                params: {
                    nivel: 'operador'
                }
            });

            setCambistas(response.data.data.length);

        } catch (e) {
            alerta(e.response.data)
        }
    };

    const mandaAlteracoes = async () => {
        try {
            setCarregaPost(true);

            const formData = new FormData();

            formData.append('gerente_cancelar_apostas', (gerenteCancelar === "false") ? false : true)
            formData.append('milhar_brinde', (tipoMilharBrinde === "nao") ? false : true)
            formData.append('pode_cancelar_aposta', (tempoCancelarAposta === "0") ? false : true)
            formData.append('tempo_cancelar_aposta', tempoCancelarAposta)
            formData.append('tempo_segunda_via', tempoSegundaVia)
            formData.append('apostas_ativas', apostasAtivas)
            formData.append('texto_bilhete', textoBilhete)
            formData.append('tipo_milhar_brinde', (tipoMilharBrinde === "nao") ? 'nao' : tipoMilharBrinde)
            formData.append('minimo_milhar_brinde', minimoMilharBrinde)
            formData.append('maximo_milhar_brinde', maximoMilharBrinde)
            formData.append('fixo_milhar_brinde', fixoMilharBrinde)
            formData.append('comissao_milhar_moto', comissaoMilharMoto)
            formData.append('comissao_seninha', comissaoSeninha)
            formData.append('comissao_dois_pra_quinhentos', comissaoDoisPraQuinhentos)
            formData.append('multiplicador_milhar_brinde', multiplicadorBrinde)
            formData.append('dias_inativar', diasInativar)
            formData.append('baixa_automatica', baixaAutomatica)
            formData.append('modulos', JSON.stringify(modulos))

            const res = await http.post('/bancas/opcoes', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            const request = await http.get("/perfil");
            const { usuario } = request.data.data;

            await localStorage.setItem("usuario", JSON.stringify(usuario));

            alerta(res.data)
            setCarregaPost(false);
        } catch (error) {
            setCarregaPost(false);
            alerta(error.response.data)
        }
    };
    
    const handleChangeModule = (e) => {
        if (e.target.checked) {

            setModulos([...modulos.map((modulo) => {
                if (modulo.nome === e.target.value) {
                    modulo.status = 1
                }

                return modulo;
            })]);

        } else {

            setModulos([...modulos.map((modulo) => {
                if (modulo.nome === e.target.value) {
                    modulo.status = 0
                }

                return modulo;
            })]);

        }
    }

    return (
        <>
            <div className="titulo-principal">Configurações</div>
            <div className={styles.conteudoPrincipal}>

                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Numero de cambistas cadastrados:</label>
                    </div>
                    <div className={styles.input}>
                        <input disabled className="form-control"
                            style={{ width: 200 }} value={cambistas}
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Tempo em minutos para imprimir segunda via:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type="number"
                            className="form-control"
                            style={{ width: 200 }}
                            onChange={(e) => setTempoSegundaVia(e.target.value)}
                            value={tempoSegundaVia}
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Tempo em minutos para cambista cancelar aposta:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type="number"
                            className="form-control"
                            style={{ width: 200 }}
                            onChange={(e) => setTempoCancelarAposta(e.target.value)}
                            value={tempoCancelarAposta}
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Quantidade de dias para inativação de usuário:</label>
                    </div>
                    <div className={styles.input}>
                        <input
                            type="number"
                            className="form-control"
                            style={{ width: 200 }}
                            onChange={(e) => setDiasInativar(e.target.value)}
                            value={diasInativar}
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Apostas ativas:</label>
                    </div>
                    <div className={styles.input}>
                        <select
                            style={{ width: 200 }}
                            className="form-control"
                            name="apostasAtivas"
                            id="apostasAtivas"
                            onChange={(e) => setApostasAtivas(e.target.value)}
                        >
                            <option selected={(apostasAtivas) ? 'selected' : ''} value={1}>Sim</option>
                            <option selected={(apostasAtivas) ? '' : 'selected'} value={0}>Não</option>
                        </select>
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Baixa automatica:</label>
                    </div>
                    <div className={styles.input}>
                        <select
                            style={{ width: 200 }}
                            className="form-control"
                            name="baixaAutomatica"
                            id="baixaAutomatica"
                            onChange={(e) => setBaixaAutomatica(e.target.value)}
                        >
                            <option selected={(baixaAutomatica) ? 'selected' : ''} value={1}>Sim</option>
                            <option selected={(baixaAutomatica) ? '' : 'selected'} value={0}>Não</option>
                        </select>
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Milhar Brinde disponível:</label>
                    </div>
                    <div className={styles.input}>
                        <select
                            style={{ width: 200 }}
                            className="form-control"
                            name="milharBrinde"
                            id="milharbrinde"
                            value={tipoMilharBrinde}
                            onChange={(e) => setTipoMilharBrinde(e.target.value)}
                        >
                            <option value="nao">Não</option>
                            <option value="fixo">Valor fixo</option>
                            <option value="multiplicado">Valor multiplicado</option>
                        </select>
                    </div>
                </div>
                {tipoMilharBrinde === 'multiplicado' ? (
                    <>
                        <div className={styles.formGroup}>
                            <div className={styles.label}>
                                <label>Valor mínimo para ativar milhar brinde:</label>
                            </div>
                            <div className={styles.input}>
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{ width: 200 }}
                                    onChange={(e) => setMinimoMilharBrinde(e.target.value)}
                                    value={minimoMilharBrinde}
                                />
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.label}>
                                <label>Valor máximo para multiplicar milhar brinde:</label>
                            </div>
                            <div className={styles.input}>
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{ width: 200 }}
                                    onChange={(e) => setMaximoMilharBrinde(e.target.value)}
                                    value={maximoMilharBrinde}
                                />
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.label}>
                                <label>Multiplicador da milhar brinde:</label>
                            </div>
                            <div className={styles.input}>
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{ width: 200 }}
                                    onChange={(e) => setMultiplicadorBrinde(e.target.value)}
                                    value={multiplicadorBrinde}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    tipoMilharBrinde == 'fixo' ? (
                        <>
                            <div className={styles.formGroup}>
                                <div className={styles.label}>
                                    <label>Valor mínimo para ativar milhar brinde:</label>
                                </div>
                                <div className={styles.input}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        style={{ width: 200 }}
                                        onChange={(e) => setMinimoMilharBrinde(e.target.value)}
                                        value={minimoMilharBrinde}
                                    />
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <div className={styles.label}>
                                    <label>Prêmio fixo da milhar brinde:</label>
                                </div>
                                <div className={styles.input}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        style={{ width: 200 }}
                                        onChange={(e) => setFixoMilharBrinde(e.target.value)}
                                        value={fixoMilharBrinde}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                )}
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Gerente pode cancelar apostas:</label>
                    </div>
                    <div className={styles.input}>
                        <select
                            style={{ width: 200 }}
                            className="form-control"
                            name="gerenteCancelar"
                            id="gerenteCancelar"
                            value={gerenteCancelar}
                            onChange={(e) => setGerenteCancelar(e.target.value)}
                        >
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <label>Texto para imprimir ao final do bilhete:</label>
                    </div>
                    <div className={styles.input}>
                        <textarea
                            className='form-control'
                            style={{ width: 300 }}
                            rows='5'
                            onChange={(e) => setTextoBilhete(e.target.value)}
                            value={textoBilhete}
                        >
                            {textoBilhete}
                        </textarea>
                    </div>
                </div>

                <div>

                    {
                        modulos?.map((modulo, index) => (
                            <div key={index} className="form-check mb-2">
                                <input className="form-check-input"
                                    checked={modulo.status === 1}
                                    onChange={handleChangeModule} type="checkbox"
                                    value={modulo.nome}
                                    id={index} />
                                <label className="form-check-label" htmlFor={index}>
                                    {modulo.nome}
                                </label>
                            </div>
                        ))
                    }

                </div>

                <div className={styles.buttonStyle}>
                    <button
                        className='btn btn-success'
                        onClick={() => mandaAlteracoes()}
                        disabled={carregaPost}
                    >
                        Salvar
                    </button>
                </div>

            </div>
        </>
    );
}
