export default function formatMoney(value = "0", currencyDecimals = 2,activeRS = true) {
	let number = value.toString().replace(',', '');
	const currencySign = 'R$ ';
	const decimalSign = ',';
	const thousandSign = '.';
	const signal = number < 0 ? '-' : '';
	const i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(currencyDecimals)))
	);
	const j = i.length > 3 ? i.length % 3 : 0;

	return (
		((activeRS) ? currencySign: '') +
		signal +
		(j ? i.substr(0, j) + thousandSign : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSign) +
		(currencyDecimals
			? decimalSign +
			  Math.abs(number - i)
					.toFixed(currencyDecimals)
					.slice(2)
			: '')
	);
}
