import React, { useEffect, useState } from "react";
import http from "services/http";
import PermissionGate from "utils/PermissionGate";
import dayjs from 'dayjs';
import { alerta } from "components/Alert/index";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import formatMoney from "utils/formatMoney";

export default function DoisQuinhentos(props) {
  const [loading, setLoading] = useState(false);
  const [credenciais, setCredenciais] = useState({});
  const [checks, setChecks] = useState([
    { checked: false, valor: 0, quantidade: 1 },
    { checked: false, valor: 0, quantidade: 2 },
    { checked: false, valor: 0, quantidade: 3 },
    { checked: false, valor: 0, quantidade: 6 },
    { checked: false, valor: 0, quantidade: 13 },
  ]);
  const [milharEsconde, setMilharEsconde] = useState(false);
  const [lista, setLista] = useState([]);

  const defineCredenciais = (ev) => {
    const { name, value } = ev.target;

    setCredenciais({
      ...credenciais
      , [name]: value
    })
  }

  const salvarDoisQuinhentos = async (e) => {

    e.preventDefault();

    let milhares = {
      'milhar_premiada': '',
      'milhar_esconder': ''
    };

    if (milharEsconde) {
      const { value: formValues } = await Swal.fire({
        html:
          '<label>Milhar premiada</label><input id="swal-input1" style="width: 30px; margin: 3px"><br/>' +
          '<label>Milhar escondida</label><input id="swal-input2" style="width: 30px; margin: 3px">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        }
      })

      if (formValues) {
        milhares = {
          'milhar_premiada': formValues[0],
          'milhar_esconder': formValues[1]
        };
      }
    }

    await toast.promise(
      http.post('/doisquinhentos', {
        ...credenciais,
        ...milhares,
        numeros: checks,
        premios: [
          credenciais.primeiro_premio,
          credenciais.segundo_premio,
          credenciais.terceiro_premio,
          credenciais.quarto_premio,
          credenciais.quinto_premio
        ]
      }),
      {
        pending: {
          render() {
            setLoading(true)
            return 'Gerando palpites, aguarde!'
          }
        },
        success: {
          render({ data }) {
            setLoading(false)
            setCredenciais({});
            buscarDoisQuinhentos();
            document.forms[0].reset();
            return data.data.message
          }
        },
        error: {
          render({ data }) {
            setLoading(false)
            alerta(data.response.data);
          }
        }
      }
    );

  }

  const cancelaDoisQuinhentos = async (id) => {

    Swal.fire({
      title: 'Deseja cancelar o 2 pra 500?',
      text: 'Só será possivel recuperar gerando novos palpites, cuidado ao cancelar!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await http.delete('/doisquinhentos/' + id)
          buscarDoisQuinhentos()
          alerta(response.data)
        } catch (error) {
          alerta(error.response.data)
        }
      }
    });

  }

  const CampoEncerramento = ({ el }) => {
    const [editarData, setEditarData] = useState(false);
    const [data, setData] = useState(el.data_limite);

    const salvar = async () => {

      try {
        const response = await http.patch('/doisquinhentos/' + el.id, { 'data_limite': data })
        setData(data)
        setEditarData(false)
        alerta(response.data)
      } catch (error) {
        alerta(error.response.data)
      }

    }

    return (
      !editarData ?
        <>
          {dayjs(data).format('DD/MM/YYYY HH:mm')}
          <button
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{ height: '25px', width: '25px' }}
            onClick={() => setEditarData(true)}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        </> :
        <div className="d-flex">
          <input
            required
            type="datetime-local"
            className="form-control"
            onChange={(e) => setData(e.target.value)}
            name='data_limite_nova'
          />
          <button
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{ height: '38px', width: '25px' }}
            onClick={salvar}
          >
            <i className="fa fa-check" aria-hidden="true"></i>
          </button>
          <button
            className="btn btn-secondary ml-1 p-0 text-center"
            style={{ height: '38px', width: '25px' }}
            onClick={() => {
              setEditarData(false)
              setData(el.data_limite)
            }}
          >
            <i className="fa fa-close" aria-hidden="true"></i>
          </button>
        </div>
    )
  }

  const buscarDoisQuinhentos = async () => {
    try {
      const { data: { data: opcoes } } = await http.get('/bancas/opcoes');
      const { data: { data } } = await http.get('/doisquinhentos');
      setLista(data);
      setMilharEsconde(opcoes.opcoes.dois_pra_quinhentos);
    } catch (e) {
      alerta(e.response.data)
    }
  }

  const checkedNumeros = (e) => {
    const { name, checked, value, id } = e.target

    if (name === 'um') {
      if (id == 'check') {
        checks[0] = { ...checks[0], checked };
      } else {
        checks[0] = { ...checks[0], valor: value };
      }
    }
    if (name === 'dois') {
      if (id == 'check') {
        checks[1] = { ...checks[1], checked };
      } else {
        checks[1] = { ...checks[1], valor: value };
      }
    }
    if (name === 'tres') {
      if (id == 'check') {
        checks[2] = { ...checks[2], checked };
      } else {
        checks[2] = { ...checks[2], valor: value };
      }
    }
    if (name === 'seis') {
      if (id == 'check') {
        checks[3] = { ...checks[3], checked };
      } else {
        checks[3] = { ...checks[3], valor: value };
      }
    }
    if (name === 'treze') {
      if (id == 'check') {
        checks[4] = { ...checks[4], checked };
      } else {
        checks[4] = { ...checks[4], valor: value };
      }
    }
    
    if (checks.filter(el => el.checked).length == 5) return

    setChecks([
      ...checks
    ])
  }

  useEffect(() => {

    buscarDoisQuinhentos()

  }, [])

  return (
    <>
      <div className="titulo-principal">2 Pra 500</div>
      <form onSubmit={salvarDoisQuinhentos}>
        <div className="conteudo-principal row m-0">

          <div className="col-3 px-1">
            <label>Encerramento</label>
            <input
              required
              type="datetime-local"
              className="form-control"
              onChange={defineCredenciais}
              name='data_limite'
            />
          </div>

          <div className="col-2 px-1">
            <label className="control-label">Tipo</label>
            <select
              className="form-control"
              onChange={defineCredenciais}
              name='tipo_resultado'
            >
              <option>Selecione</option>
              <option value="normal">normal</option>
              <option value="inversa">inversa</option>
            </select>
          </div>
          <div className="col-2 px-1">
            <label>Valor</label>
            <input
              required
              type="text"
              className="form-control"
              onChange={defineCredenciais}
              placeholder="valor"
              name='valor'
            />
          </div>

          <div className="col-1 px-1">
            <label>1 <input type='checkbox' id='check' name="um" onClick={(e) => checkedNumeros(e)} /></label>
            <input
              type="text"
              className="form-control"
              onChange={checkedNumeros}
              placeholder="diminuir"
              name='um'
              id='valorCheck'
              disabled={!checks[0].checked}
            />
          </div>
          <div className="col-1 px-1">
            <label>2 <input type='checkbox' id='check' name="dois" onClick={(e) => checkedNumeros(e)} /></label>
            <input
              type="text"
              className="form-control"
              onChange={checkedNumeros}
              placeholder="diminuir"
              name='dois'
              id='valorCheck'
              disabled={!checks[1].checked}
            />
          </div>
          <div className="col-1 px-1">
            <label>3 <input type='checkbox' id='check' name="tres" onClick={(e) => checkedNumeros(e)} /></label>
            <input
              type="text"
              className="form-control"
              onChange={checkedNumeros}
              placeholder="diminuir"
              id='valorCheck'
              name='tres'
              disabled={!checks[2].checked}
            />
          </div>
          <div className="col-1 px-1">
            <label>6 <input type='checkbox' id='check' name="seis" onClick={(e) => checkedNumeros(e)} /></label>
            <input
              type="text"
              className="form-control"
              onChange={checkedNumeros}
              placeholder="diminuir"
              id='valorCheck'
              name='seis'
              disabled={!checks[3].checked}
            />
          </div>
          <div className="col-1 px-1">
            <label>13 <input type='checkbox' id='check' name="treze" onClick={(e) => checkedNumeros(e)} /></label>
            <input
              type="text"
              className="form-control"
              onChange={checkedNumeros}
              placeholder="diminuir"
              id='valorCheck'
              name='treze'
              disabled={!checks[4].checked}
            />
          </div>


          <div className="row p-3">
            <div className="col-2 px-1">
              <label>1 Premio</label>
              <input
                required
                type="text"
                className="form-control"
                onChange={defineCredenciais}
                name='primeiro_premio'
              />
            </div>

            <div className="col-2 px-1">
              <label>2 Premio</label>
              <input
                required
                type="text"
                className="form-control"
                onChange={defineCredenciais}
                name='segundo_premio'
              />
            </div>

            <div className="col-2 px-1">
              <label>3 Premio</label>
              <input
                required
                type="text"
                className="form-control"
                onChange={defineCredenciais}
                name='terceiro_premio'
              />
            </div>

            <div className="col-2 px-1">
              <label>4 Premio</label>
              <input
                required
                type="text"
                className="form-control"
                onChange={defineCredenciais}
                name='quarto_premio'
              />
            </div>

            <div className="col-2 px-1">
              <label>5 Premio</label>
              <input
                required
                type="text"
                className="form-control"
                onChange={defineCredenciais}
                name='quinto_premio'
              />
            </div>

            <div className="col-2 px-1">
              <PermissionGate permissions={['Criar dois quinhentos']}>
                <button
                  type="submit"
                  className={`btn btn-info w-100 mt-4`}
                  style={{ height: '38px' }}
                  disabled={loading}
                >
                  Salvar
                </button>
              </PermissionGate>
            </div>
          </div>
        </div>

      </form>

      <div className="conteudo-principal p-0 mt-2">
        <table className="table text-left border text-center">
          <thead>
            <tr>
              <th>Encerramento</th>
              <th>Situação</th>
              <th>Valor</th>
              <th>Bilhetes</th>
              <th>Premio</th>
              <th>Venda</th>
              <th>Cancelar</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {
              lista && lista.map(el =>
                <tr key={el.id}>
                  <td style={{ maxWidth: "180px" }}>
                    <CampoEncerramento el={el} />
                  </td>
                  <td>{new Date(el.data_limite) > new Date() ? 'Andamento' : 'Finalizado'}</td>
                  <td>{formatMoney(el.valor)}</td>
                  <td>{el.palpites - el.palpites_livres}/{el.palpites_livres}</td>
                  <td>{formatMoney(el.primeiro_premio)}</td>
                  <td>{formatMoney((el.palpites - el.palpites_livres) * el.valor)}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link w-100 px-0"
                      onClick={() => cancelaDoisQuinhentos(el.id)}
                    >
                      Cancelar
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-info w-100 px-0"
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </>
  )
}