import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dayjs from 'dayjs';

import http from 'services/http';
import InputUsuario from 'components/Usuarios/InputUsuario';
import {alerta} from "../../../../components/Alert";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";

const MySwal = withReactContent(Swal);

export default function Editar(props) {
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [listaGerente, setListaGerente] = useState([]);
    const [tipoGerente, setTipoGerente] = useState();
    const [gerente, setGerente] = useState('');
    const [comissaoGerente, setComissaoGerente] = useState(0);
    const [comissaoLucro, setComissaoLucro] = useState(0);
    const [porcentagemSocio, setPorcentagemSocio] = useState(0);
    const [comissaoDezena, setComissaoDezena] = useState(30);
    const [saldo, setSaldo] = useState('');
    const [comissao, setComissao] = useState(30);
    const [comissaoGrupo, setComissaoGrupo] = useState(10);
    const [cotaEscolhida, setCotaEscolhida] = useState(1);
    const [endereco, setEndereco] = useState('');
    const [telefone, setTelefone] = useState('');
    const [descricao, setDescricao] = useState('');
    const [milharBrindeUsuario, setMilharBrindeUsuario] = useState(1);
    const [nivel, setNivel] = useState('');
    const [modalidades, setModalidades] = useState([]);
    const [dataCriacao, setDataCriacao] = useState('');
    const [permissaoCriarOperador, setPermissaoCriarOperador] = useState(false);
    const [permissaoAdicionarSaldo, setPermissaoAdicionarSaldo] = useState(false);
    const [nivelLogado, setNivelLogado] = useState('');


    useEffect(() => {
        const requestUsuarios = async () => {
            try {
                const {id} = props.match.params;

                const response = await http.get('/usuarios/' + id);

                const {data: usuario} = response.data;

                setLogin(usuario.login);
                setNivel(usuario.nivel);

                //
                setSaldo(usuario.saldo);
                setComissao(usuario.comissao || '0');
                setComissaoDezena(usuario.comissao_dezena || '0');
                setComissaoGrupo(usuario.comissao_grupo || '0');
                setComissaoGerente(usuario.comissao_gerente || '0');
                setComissaoLucro(usuario.comissao_lucro || '0');
                setPorcentagemSocio(usuario.porcentagem_socio || '0');
                setTipoGerente(usuario.tipo_usuario || 'gerente');
                setCotaEscolhida(usuario.cotas);

                //
                setDescricao(usuario.observacao);
                setMilharBrindeUsuario(usuario.milhar_brinde_usuario);
                setEndereco(usuario.endereco);
                setTelefone(usuario.telefone);

                if (usuario.permissoes) {
                    setPermissaoAdicionarSaldo(
                        usuario.permissoes.adicionar_saldo || false,
                    );

                    setPermissaoCriarOperador(usuario.permissoes.criar_operador || false);
                }

                const data = dayjs(usuario.created_at).format('DD/MM/YYYY HH:mm');

                setDataCriacao(data);
                await setNivelLogado(localStorage.getItem('nivel'));

                if (usuario.nivel === 'operador') {
                    setGerente(usuario.gerente_id);

                    const resModalidades = await http.get('bancas/titulo-cotacoes');
                    setModalidades(resModalidades.data.data);

                    const response = await http.get('/usuarios', {
                        params: {
                            nivel: 'gerente',
                        },
                    });
                    const {data: usuarios} = response.data;
                    setListaGerente(usuarios);
                }
            } catch (e) {

            }
        };

        requestUsuarios();
    }, [props.match.params]);

    const enviarModificacao = async () => {
        try {
            const {id} = props.match.params;

            if ((senha.length < 6 || senha.length > 18) && senha.length !== 0) {
                toast.error('A senha deve ter entre 6 e 18 caracteres');
                return;
            }

            const {data} = await http.put('/usuarios/' + id, {
                login,
                nivel,
                password: senha,
                gerente_id: gerente,
                comissao: comissao || 0,
                comissao_grupo: comissaoGrupo || 0,
                saldo,
                tipo_usuario: tipoGerente,
                comissao_gerente: comissaoGerente || 0,
                comissao_lucro: comissaoLucro || 0,
                porcentagem_socio: porcentagemSocio || 0,
                comissao_dezena: comissaoDezena || 0,
                cotas: cotaEscolhida,
                milhar_brinde_usuario: milharBrindeUsuario,
                endereco,
                telefone,
                observacao: descricao,
                permissao_criar_operador: permissaoCriarOperador,
                permissao_adicionar_saldo: permissaoAdicionarSaldo,
            });

            alerta(data)

            if (nivel === 'operador') {
                props.history.push('/');
            } else if (nivel === 'subadmin') {
                props.history.push('/subadmin')
            } else {
                props.history.push('/rota');
            }

        } catch (e) {
            alerta(e.response.data);
        }
    };

    const perguntarParaApagar = async () => {
        const {id} = props.match.params;

        Swal.fire({
            title: 'Deseja apagar o gerente?',
            text: 'Certifique-se de que não existem cambistas ligados ao gerente antes de apagar!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Sim, apagar!',
            cancelButtonText: 'Não, cancelar',
        }).then((result) => {
            if (result.value) {
                apagarRota(id);
            }
        });
    };

    const apagarRota = async (id) => {
        try {
            const res = await http.delete(`/usuarios/${id}`);

            alerta(res.data);

            props.history.push('/rota');
        } catch (e) {
            alerta(e.response.data)
        }
    };

    return (
        <div className='bordered'>
            <div>
                <div className='titulo-principal'>
                    {nivel === 'operador' ? (<span>Atualizar Cambista</span>) : (<span>Atualizar Usuário</span>)}

                </div>
                <div className='btn-group caixa-inputs p-b'>
                    <div className='input-criacao-usuario margin-vertical-30'>
                        <label
                            className='titulo-input-criacao'
                            style={{marginBottom: 10}}
                        >
                            Criado em:
                        </label>
                        <input
                            disabled
                            className='form-control input-dados-criar'
                            value={dataCriacao}
                        />
                    </div>

                    {(nivel === 'operador' && nivelLogado !== 'gerente') && (
                        <div className='input-criacao-usuario margin-vertical-30'>
                            <label className='titulo-input-criacao'>Gerente:</label>
                            <select
                                required
                                className='form-control input-dados-criar'
                                id='inputGerente'
                                onChange={(e) => setGerente(e.target.value)}
                                value={gerente}
                            >
                                {listaGerente.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.login}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    <div className='input-criacao-usuario margin-vertical-30'>
                        <label className='titulo-input-criacao'>Login:</label>
                        <input
                            required
                            onChange={(c) => setLogin(c.target.value)}
                            type='text'
                            maxLength='30'
                            className='form-control input-dados-criar'
                            value={login}
                        />
                    </div>

                    <div className='input-criacao-usuario margin-vertical-30'>
                        <label className='titulo-input-criacao'>Senha:</label>
                        <input
                            required
                            onChange={(c) => setSenha(c.target.value)}
                            type='password'
                            maxLength='30'
                            className='form-control input-dados-criar'
                            value={senha}
                        />
                    </div>

                    {nivel === 'operador' && (
                        <>
                            <InputUsuario
                                name={'Saldo'}
                                valor={saldo}
                                func={(e) => setSaldo(e)}
                            />
                            
                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label className='titulo-input-criacao'>Cotações:</label>
                                <select
                                    required
                                    className='form-control input-dados-criar'
                                    onChange={(e) => setCotaEscolhida(e.target.value)}
                                    value={cotaEscolhida}
                                >
                                    {modalidades.map((m) => (
                                        <option key={m.id} value={m.id}>
                                            {m.nome.replace(/duque\s*de\s*grupo\s*combinado/gi, "Grupo Combinado")}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <InputUsuario
                                name={'% Geral'}
                                valor={comissao}
                                func={(e) => setComissao(e)}
                            />
                            <InputUsuario
                                name={'% Dezena'}
                                valor={comissaoDezena}
                                func={(e) => setComissaoDezena(e)}
                            />
                            <InputUsuario
                                name={'% Grupo'}
                                valor={comissaoGrupo}
                                func={(e) => setComissaoGrupo(e)}
                            />

                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label className='titulo-input-criacao'>Milhar brinde:</label>
                                <select
                                    required
                                    className='form-control input-dados-criar'
                                    onChange={(e) => setMilharBrindeUsuario(e.target.value)}
                                    value={milharBrindeUsuario}
                                >
                                    <option value={1}>
                                        Sim
                                    </option>
                                    <option value={0}>
                                        Não
                                    </option>
                                </select>
                            </div>
                        </>
                    )}


                    {nivel === 'gerente' && (
                        <>

                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label
                                    className='titulo-input-criacao'
                                    style={{marginBottom: 10}}
                                >
                                    Tipo:
                                </label>
                                <select
                                    required
                                    className='form-control input-dados-criar'
                                    id='inputTipoGerente'
                                    onChange={(e) => setTipoGerente(e.target.value)}
                                    value={tipoGerente}
                                >
                                    <option value={'gerente'}>Gerente</option>
                                    <option value={'socio'}>Sócio</option>
                                </select>
                            </div>

                            {tipoGerente === 'gerente' ? (
                                <>
                                    <InputUsuario
                                        name={'Comissão Bruto'}
                                        valor={comissaoGerente}
                                        func={(e) => setComissaoGerente(e)}
                                    />
                                    <InputUsuario
                                        name={'Comissão Lucro'}
                                        valor={comissaoLucro}
                                        func={(e) => setComissaoLucro(e)}
                                    />
                                </>
                            ) : (
                                <InputUsuario
                                    name={'% Sócio'}
                                    valor={porcentagemSocio}
                                    func={(e) => setPorcentagemSocio(e)}
                                    tipo={'number'}
                                />
                            )}

                        </>
                    )}

                    <InputUsuario
                        name={'Endereço'}
                        valor={endereco}
                        func={(e) => setEndereco(e)}
                    />

                    <InputUsuario
                        name={'Telefone'}
                        valor={telefone}
                        func={(e) => setTelefone(e)}
                    />

                    <div className='input-criacao-usuario margin-vertical-30'>
                        <label className='titulo-input-criacao'>Descrição:</label>
                        <textarea
                            className='form-control input-dados-criar'
                            onChange={(c) => setDescricao(c.target.value)}
                            rows='3'
                            value={descricao || ''}
                        />
                    </div>

                    {nivel === 'gerente' && (
                        <>
                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label className='titulo-input-criacao'>Permissões:</label>
                                <div className='funkyradio-info'>
                                    <input
                                        type='checkbox'
                                        id='criar-operador'
                                        onChange={() =>
                                            setPermissaoCriarOperador(!permissaoCriarOperador)
                                        }
                                        checked={permissaoCriarOperador}
                                    />
                                    &nbsp;
                                    <label htmlFor='criar-operador'>Criar Cambista</label>
                                    <br/>
                                    <input
                                        type='checkbox'
                                        id='adicionar-saldo'
                                        onChange={() =>
                                            setPermissaoAdicionarSaldo(!permissaoAdicionarSaldo)
                                        }
                                        checked={permissaoAdicionarSaldo}
                                    />
                                    &nbsp;
                                    <label htmlFor='adicionar-saldo'>Adicionar saldo</label>
                                </div>
                            </div>

                            <div className='input-criacao-usuario margin-vertical-30'>
                                <label className='titulo-input-criacao'>Apagar Gerente:</label>
                                <button
                                    className='btn btn-danger btn-small'
                                    onClick={() => {
                                        perguntarParaApagar();
                                    }}
                                >
                                    Apagar
                                </button>
                            </div>
                        </>
                    )}
                </div>

                {nivel === 'operador' && (
                    <div style={{padding: 10}}>
                        <p style={{color: '#cc0000'}}>
                            % Geral: para milhar e centena e suas subcategorias.
                        </p>
                        <p style={{color: '#cc0000'}}>
                            % Dezena: para dezena e suas subcategorias.
                        </p>
                        <p style={{color: '#cc0000'}}>
                            % Grupo: para grupo e suas subcategorias.
                        </p>
                        <p style={{color: '#cc0000'}}>
                            Na opção cliente a comissão ganha em cada bilhete, volta em forma de saldo.
                        </p>
                    </div>
                )}

                <div
                    style={{
                        padding: '0 10px',
                        justifyContent: 'left',
                    }}
                    className='d-flex m-b m-t'
                >
                    <button
                        style={{display: 'block', width: '270px', marginLeft: '150px'}}
                        className='btn btn-success'
                        onClick={() => enviarModificacao()}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    );
}