import Bolao from "../../views/Admin/Bolao";
import Operador from "../../views/Admin/Usuarios/Operador";
import Rota from "../../views/Admin/Usuarios/Rota";
import Saldo from "../../views/Admin/Saldo";
import Caixa from 'views/Admin/Caixa';
import Bilhetes from 'views/Admin/Acompanhamento';
import Lancamento from 'views/Admin/Lancamento';
import ListaCaixaCambistas from "../../views/Admin/CaixaCambistas/ListaCambistas";
import Extracao from "../../views/Admin/Extracao";
import Resultado from "../../views/Admin/Resultado";
import Financeiro from "../../views/Admin/Financeiro";
import Descarga from "../../views/Admin/Descarga";
import Seninha from "../../views/Admin/Seninha";
import loteriaInstantanea from "../../views/Admin/Instantanea";
import Sorteio from "../../views/Admin/Sorteio";
import Relatorio from "../../views/Admin/Relatorio";
import Regulamento from "../../views/Admin/Regulamento";
import Configuracao from "../../views/Admin/Configuracao";
import Senha from "../../views/Admin/Senha";
import Usuario from "../../views/Admin/Usuarios/Editar";
import CriarUsuario from "../../views/Admin/Usuarios/CriarUsuario";
import CriarRegulamento from "../../views/Admin/Regulamento/CriarRegulamento";
import ResumoCaixaCambistas from "../../views/Admin/CaixaCambistas/ResumoCambistas";
import CaixaResumo from "../../views/Admin/CaixaResumo";
import BolaoCriar from "../../views/Admin/Bolao/Criar";
import BolaoVer from "../../views/Admin/Bolao/Ver";
import Criar from "../../views/Admin/Extracao/Criar";
import Editar from "../../views/Admin/Extracao/Editar";
import Adicionar from "../../views/Admin/Resultado/Adicionar";
import CriarSorteio from "../../views/Admin/Sorteio/Criar";
import Roleta from "../../views/Admin/Roleta";
import SenaBrasil from "../../views/Admin/SenaBrasil";
import EditarConcursoSeninha from "../../views/Admin/Seninha/editar";
import EditarConcursoSenaBrasil from "../../views/Admin/SenaBrasil/editar";
import DoisQuinhentos from "views/Admin/DoisQuinhentos/index";
import DoisQuinhentosResultado from "views/Admin/DoisQuinhentos/Resultado/index";
import AdicionarResultadoConcursoSeninha from "../../views/Admin/Seninha/resultado";
import AdicionarResultadoConcursoSenaBrasil from "../../views/Admin/SenaBrasil/resultado";
import {BolaoDezena} from "../../views/Admin/BolaoDezena";
import {VerBolaoDezena} from "../../views/Admin/BolaoDezena/Ver";
import AdicionarResultadoBolaoDezena from "../../views/Admin/BolaoDezena/resultado";
import {Comissao} from "../../views/Admin/Comissao";
import Rifa from "views/Admin/Rifa/index";
import RifaResultado from "views/Admin/Rifa/Resultado/index";
import Auditoria from "../../views/Admin/Auditoria/index";

export default [
    {
        path: '/usuarios/:id/login',
        permissions: ["Listar cambista", "Listar gerente"],
        module: [],
        component: Usuario,
        showBar: false
    },
    {
        path: '/usuarios/criar/:nivel',
        permissions: ["Listar cambista", "Listar gerente"],
        module: [],
        component: CriarUsuario,
        showBar: false
    },
    {
        path: '/regulamento/criar/regulamento',
        permissions: ["Alterar regulamento"],
        module: [],
        component: CriarRegulamento,
        showBar: false
    },
    {
        path: '/caixa/resumo',
        permissions: ["Listar resumo"],
        module: [],
        component: CaixaResumo,
        showBar: false
    },
    {
        path: '/bolao/criar',
        permissions: ["Criar bolão"],
        module: [],
        component: BolaoCriar,
        showBar: false
    },
    {
        path: '/bolao/:id',
        permissions: ["Ver bolão"],
        module: [],
        component: BolaoVer,
        showBar: false
    },
    {
        path: '/extracao/criar',
        permissions: ["Criar loteria"],
        module: [],
        component: Criar,
        showBar: false
    },
    {
        path: '/extracao/:id',
        permissions: ["Ver loteria"],
        module: [],
        component: Editar,
        showBar: false
    },
    {
        path: '/resultado/adicionar',
        permissions: ["Listar resultado jb"],
        module: [],
        component: Adicionar,
        showBar: false
    },
    {
        path: '/sorteio/criar',
        permissions: ["Criar sorteio"],
        module: [],
        component: CriarSorteio,
        showBar: false
    },
    {
        path: '/roleta',
        nome: 'Roleta',
        icon: 'fa-users',
        permissions: ["*"],
        module: [],
        component: Roleta,
        showBar: true
    },
    {
        path: '/',
        nome: 'Cambistas',
        icon: 'fa-users',
        permissions: ["Listar cambista"],
        module: [],
        component: Operador,
        showBar: true
    },
    {
        path: '/rota',
        nome: 'Gerentes',
        icon: 'fa-user',
        permissions: ["Listar gerente"],
        module: [],
        component: Rota,
        showBar: true
    },
    // {
    //     path: '/subadmin',
    //     nome: 'SubAdmin',
    //     icon: 'fa-sitemap',
    //     permissions: ["Listar subadmin"],
    //     component: Subadmin,
    //     showBar: true
    // },
    {
        path: '/saldo',
        nome: 'Saldo',
        icon: 'fa-dollar',
        permissions: ["Listar cambista"],
        module: [],
        component: Saldo,
        showBar: true
    },
    {
        path: '/caixa',
        nome: 'Caixa',
        icon: 'fa-money',
        permissions: ["Listar caixa"],
        module: [],
        component: Caixa,
        showBar: true
    },
    {
        path: '/bilhetes',
        nome: 'Bilhetes',
        icon: 'fa-ticket',
        permissions: ["Listar aposta jb"],
        module: [],
        component: Bilhetes,
        showBar: true
    },
    {
        path: '/doisquinhentos/resultado',
        permissions: ["Listar dois quinhentos"],
        module: [],
        component: DoisQuinhentosResultado,
        showBar: false
    },
    {
        path: '/rifa/resultado',
        permissions: ["Listar rifa"],
        module: [],
        component: RifaResultado,
        showBar: false
    },
    {
        path: '/lancamento',
        nome: 'Lançamentos',
        icon: 'fa-credit-card',
        permissions: ["Listar lançamento"],
        module: [],
        component: Lancamento,
        showBar: true
    },
    {
        path: '/prestacao/cambistas',
        nome: 'Prestar Contas',
        icon: 'fa-calculator',
        permissions: ["Listar prestação cambista"],
        module: [],
        component: ListaCaixaCambistas,
        showBar: true
    },
    {
        path: '/prestacao/cambistas/:id',
        permissions: ["Prestação cambista"],
        module: [],
        component: ResumoCaixaCambistas,
        showBar: false
    },
    {
        path: '/resultado',
        nome: 'Resultados',
        icon: 'fa-list-ol',
        permissions: ["Listar resultado jb"],
        module: [],
        component: Resultado,
        showBar: true
    },
    {
        path: '/auditoria',
        nome: 'Auditoria',
        icon: 'fa-eye',
        permissions: ["Listar auditoria"],
        module: [],
        component: Auditoria,
        showBar: true
    },
    {
        path: '/financeiro',
        nome: 'Venda',
        icon: 'fa-area-chart',
        permissions: ["Listar venda"],
        module: [],
        component: Financeiro,
        showBar: true
    },
    {
        path: '/comissoes',
        nome: 'Comissões',
        icon: 'fa-percent',
        permissions: ["Listar modalidade"],
        module: [],
        component: Comissao,
        showBar: true
    },
    {
        path: '/extracao',
        nome: 'Loterias',
        icon: 'fa-gamepad',
        permissions: ["Listar loteria"],
        module: ["JB", "Lototime"],
        component: Extracao,
        showBar: true
    },
    {
        path: '/seninha',
        nome: 'Seninha',
        icon: 'fa-gamepad',
        permissions: ["Listar seninha"],
        module: ["Seninha"],
        component: Seninha,
        showBar: true
    },
    {
        path: '/seninha/resultado',
        nome: 'Adicionar resultado seninha',
        icon: 'fa-calendar',
        permissions: ["Adicionar resultado seninha"],
        module: [],
        component: AdicionarResultadoConcursoSeninha,
        showBar: false
    },
    {
        path: '/seninha/:id',
        nome: 'Editar Seninha',
        icon: 'fa-calendar',
        permissions: ["Listar seninha"],
        module: [],
        component: EditarConcursoSeninha,
        showBar: false
    },
    {
        path: '/sena-brasil',
        nome: 'Sena Brasil',
        icon: 'fa-gamepad',
        permissions: ["Listar seninha"], //Mudar
        module: ["Sena Brasil"],
        component: SenaBrasil,
        showBar: true
    },
    {
        path: '/sena-brasil/resultado',
        nome: 'Adicionar resultado seninha',
        icon: 'fa-calendar',
        permissions: ["Adicionar resultado seninha"], //Mudar
        module: [],
        component: AdicionarResultadoConcursoSenaBrasil,
        showBar: false
    },
    {
        path: '/sena-brasil/:id',
        nome: 'Editar Seninha',
        icon: 'fa-calendar',
        permissions: ["Listar seninha"], //Mudar
        module: [],
        component: EditarConcursoSenaBrasil,
        showBar: false
    },
    {
        path: '/bolao',
        nome: 'Bolão',
        icon: 'fa-gamepad',
        permissions: ["Listar bolão"],
        module: ["Bolão"],
        component: Bolao,
        showBar: true
    },
    {
        path: '/bolao-dezena/resultado/:id',
        nome: 'Adicionar resultado bolão de dezenas',
        icon: 'fa-calendar',
        permissions: ["Adicionar resultado seninha"], //Mudar
        module: [],
        component: AdicionarResultadoBolaoDezena,
        showBar: false
    },
    {
        path: '/bolao-dezena/:id',
        nome: 'Ver Bolão Dezena',
        icon: 'fa-calendar',
        permissions: ["Listar seninha"], //Mudar
        module: [],
        component: VerBolaoDezena,
        showBar: false
    },
    {
        path: '/rifa',
        nome: 'Rifa',
        icon: 'fa-gamepad',
        permissions: ["Listar rifa"],
        module: ["Rifa"],
        component: Rifa,
        showBar: true
    },
    {
        path: '/doisquinhentos',
        nome: '2 pra 500',
        icon: 'fa-gamepad',
        permissions: ["Listar dois quinhentos"],
        module: ["2 para 500"],
        component: DoisQuinhentos,
        showBar: true
    },
    {
        path: '/bolao-dezena',
        nome: 'Bolão Dezena',
        icon: 'fa-gamepad',
        permissions: ["Listar seninha"], //Mudar
        module: ["Bolão de Dezena"],
        component: BolaoDezena,
        showBar: true
    },
    {
        path: '/caixa-instantaneo',
        nome: 'Instantânea',
        icon: 'fa-star',
        permissions: ["Alterar lucro da banca"],
        module: ["JB", "Lototime"],
        component: loteriaInstantanea,
        showBar: true
    },
    {
        path: '/sorteio',
        nome: 'Sorteio',
        icon: 'fa fa-gift',
        permissions: ["Listar sorteio"],
        module: [],
        component: Sorteio,
        showBar: true
    },
    {
        path: '/relatorio',
        nome: 'Relatório',
        icon: 'fa-save',
        permissions: ["Listar relatório"],
        module: [],
        component: Relatorio,
        showBar: true
    },
    {
        path: '/regulamento',
        nome: 'Regulamento',
        icon: 'fa-file-text-o',
        permissions: ["Listar regulamento"],
        module: [],
        component: Regulamento,
        showBar: true
    },
    {
        path: '/descarga',
        nome: 'Descarga',
        icon: 'fa-balance-scale',
        permissions: ["Listar descarga"],
        module: ["JB", "Lototime"],
        component: Descarga,
        showBar: true
    },
    {
        path: '/configuracao',
        nome: 'Configurações',
        icon: 'fa-cog',
        permissions: ["Listar opções"],
        module: [],
        component: Configuracao,
        showBar: true
    },
    {
        path: '/senha',
        nome: 'Senha',
        icon: 'fa-key',
        permissions: ["Alterar senha"],
        module: [],
        component: Senha,
        showBar: true
    }
];
