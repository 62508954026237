import { useEffect } from 'react';

const Head = () => {
	useEffect(() => {
		const meta = document.createElement('meta');
		meta.setAttribute('name', 'viewport');
		meta.setAttribute('content', 'width=device-width,initial-scale=1, user-scalable=no');

		const head = document.head;
		head.appendChild(meta);
	}, []);

	return <></>;
};

export default Head;
