import {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Swal from 'sweetalert2';

import http from 'services/http';
import formatMoney from 'utils/formatMoney';
import styles from './styles.module.css';
import PermissionGate from "../../../../utils/PermissionGate";
import {alerta} from "../../../../components/Alert";

export default class index extends Component {


    constructor(props) {
        super(props);

        this.state = {
            gerentes: [],
            operadores: [],
            gerente: 0,
            loading: true,
            nivel: '',
        };

    }

    componentDidMount() {
        const buscaNivel = async () => {
            const nivel = await localStorage.getItem('nivel');
            this.setState({nivel});
        }
        buscaNivel();

        if (localStorage.getItem('nivel') === 'admin') {
            this.buscarGerentes();
        }

        this.buscarCaixa();
    }

    buscarGerentes = async () => {
        try {
            const response = await http.get('/usuarios', {
                params: {
                    nivel: 'gerente',
                    resumida: true
                }
            });
            const usuarios = response.data.data;

            this.setState({gerentes: usuarios});
        } catch (e) {
        }
    };

    buscarCaixa = async () => {
        try {
            const response = await http.get('/prestacao');

            const operadores = response.data.data.map((op) => {
                op.id = op.cambista_id;
                op.nome = op.cambista;
                op.entrada = formatMoney(op.entrada);
                op.entrada_aberta = formatMoney(op.entrada_aberta);
                op.saidas = formatMoney(op.saida);
                op.comissoes = formatMoney(op.comissao_cambista);
                op.lancamentos = formatMoney(op.lancamento);
                op.valor_total = op.total_cambista;
                op.total = formatMoney(op.total_cambista);
                return op;
            });

            this.setState({operadores});
        } catch (e) {
        }

        this.setState({loading: false});
    };

    filtrarOperadores = () => {
        const {gerente, operadores} = this.state;

        if (gerente < 1) {
            return operadores;
        }

        return operadores.filter((op) => op.gerente_id == gerente);
    };

    prestarContasTodos = async () => {
        try {
            this.setState({loading: false});

            const {gerente} = this.state;

            const res = await http.post(`prestacao/cambista`, {
                gerente_id: gerente
            });

            alerta(res.data)

            this.buscarCaixa(localStorage.getItem('nivel'));
        } catch (e) {
        }
    };

    perguntaPrestarContas = () => {
        Swal.fire({
            title: 'Atenção!',
            text: 'Antes de prestar contas com todos, entre em caixa, clique em resumo para fechamento e salve a prestação de contas, pois ação deste botão é zerar todos os caixas e não tem como desfazer a após a confirmação. OBS. Aguarde ate a função carregar completamente, não feche a pagina!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.prestarContasTodos();
            }
        });
    };

    render() {
        console.log(this.state.gerente)
        return (
            <>
                <div className="titulo-principal">
                    Prestar Contas
                </div>
                <PermissionGate permissions={["Prestação cambista"]}>
                    <div className="conteudo-principal d-flex duas-colunas">
                        <select
                            className="form-control"
                            onChange={(e) => this.setState({gerente: e.target.value})}
                        >
                            <option value="0">Todos os Gerentes</option>
                            {this.state.gerentes.map((g) => (
                                <option key={g.id} value={g.id}>
                                    {g.login}
                                </option>
                            ))}
                        </select>
                        <button
                            onClick={() => this.perguntaPrestarContas()}
                            className="btn btn-info btn-menu-prestacao"
                        >
                            Prestar conta com todos
                        </button>
                    </div>
                </PermissionGate>
                {this.state.loading ? (
                    <div className="loader"/>
                ) : (
                    <div className="table-responsive m-t">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Cambista</th>
                                <th>Entrada</th>
                                <th>Em aberto</th>
                                <th>Saídas</th>
                                <th>Comissão</th>
                                <th>Lançamentos</th>
                                <th>Total</th>
                                <PermissionGate permissions={["Prestação cambista"]}>
                                    <th>Prestação</th>
                                </PermissionGate>
                            </tr>
                            </thead>
                            <tbody>
                            {this.filtrarOperadores().map((operador) => (
                                <tr key={operador.id}>
                                    <td>
                                        <b>{operador.nome}</b>
                                    </td>
                                    <td>{operador.entrada}</td>
                                    <td>{operador.entrada_aberta}</td>
                                    <td>{operador.saidas}</td>
                                    <td>{operador.comissoes}</td>
                                    <td>{operador.lancamentos}</td>
                                    <td>
                                        <b
                                            className={`${
                                                operador.valor_total > 0 ? 'text-success' : ''
                                            } ${operador.valor_total < 0 ? 'text-danger' : ''}`}
                                        >
                                            {operador.total}
                                        </b>
                                    </td>
                                    <PermissionGate permissions={["Prestação cambista"]}>
                                        <td style={{display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap'}}>
                                            <NavLink
                                                className={`btn-prestacao ${styles.buttonHover}`}
                                                to={`/prestacao/cambistas/${operador.id}`}
                                            >
                                                Prestar contas
                                            </NavLink>
                                        </td>
                                    </PermissionGate>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        );
    }
}
